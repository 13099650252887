import { useEffect } from "react";
import { Column, Spacer } from "../../../../components/Layout/Layout";
import {
  AdministratorDetails,
  Timezone,
  DateTimeFormat,
} from "../../../../core/api/administrators/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import useSharedAdministrator from "../SharedAdministratorContext/useSharedAdministrator";
import GeneralForm from "./GeneralForm";
import Alert from "../../../../ui-lib/components/Alerts/Alert";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { ManualSyncWrapper } from "../../../../components/ManualSync/ManualSyncWrapper";

const General = ({
  timezones,
  dateTimeFormats,
  data,
}: {
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
  data: AdministratorDetails;
}) => {
  const t = useTranslations();

  const { update, reload } = useSharedAdministrator();

  const onSubmit = async (values: AdministratorDetails) => {
    try {
      await update(
        values,
        data?.twoFactorAuthenticationType,
        data?.twoFactorAuthenticationData
      );
      notify({
        message: t("Administrator:edited_success"),
      });
      await reload();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    reload();
  }, [data.id]);

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      {data.restrictLogin && (
        <div className="w-100">
          <Alert
            title={t("Administrator:inactive_admin_warning")}
            withDefaultIcon
            variant="warning"
          />

          <Spacer size={32} />
        </div>
      )}

      <h1>{t("Administrator:general_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:general_description")}
      </p>

      <Spacer size={32} />

      <GeneralForm
        defaultValues={data}
        onSubmit={onSubmit}
        timezones={timezones}
        dateTimeFormats={dateTimeFormats}
      />

      {data.type === "respondent" && (
        <ManualSyncWrapper
          clusterData={{
            lastSync: data.lastClusterSync,
            latestSyncAttempt: data.lastClusterSyncAttempt,
          }}
          refetchFunc={reload}
        />
      )}
    </Column>
  );
};

export default General;
