import { Fragment, useRef } from "react";
import classnames from "classnames";
import { OverlayPanel } from "primereact/overlaypanel";
import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";
import Icon from "../Icon";
import { PopupContextMenuLinkButton } from "../Menus/PopupContextMenu/PopupContextMenuLinkButton";
import { RowAction } from "./Table";

import styles from "./Table.module.css";

const MobileWrapper = <TRowData,>({
  rowActions,
  isMobile,
  children,
}: {
  rowActions: RowAction<TRowData>[];
  isMobile: boolean;
  children: React.ReactNode;
}) => {
  if (rowActions.length === 1 && isMobile) {
    return <div className="action-button-wrapper">{children}</div>;
  }
  if (rowActions.length > 1 || !isMobile) {
    return children;
  }
  return null;
};

export const ActionColumnBody = <TRowData,>({
  rowIndex = 0,
  rowData,
  showTooltip,
  showRowsActionsOnHover,
  rowActionContextMenu,
  isMobile,
  rowActions,
}: {
  rowIndex?: number;
  rowData: TRowData;
  showTooltip: boolean;
  showRowsActionsOnHover: boolean;
  rowActionContextMenu?: boolean;
  isMobile: boolean;
  rowActions: RowAction<TRowData>[];
}) => {
  const popupContextRef = useRef<OverlayPanel>(null);
  const tooltipText = (action: RowAction<TRowData>) =>
    typeof action.text === "string" ? action.text : action?.text?.(rowData);
  const btnElement = (action: RowAction<TRowData>) => {
    const iconName =
      typeof action.icon === "string" ? action.icon : action.icon(rowData);
    return (
      <Fragment key={`col_${rowIndex}_${iconName}`}>
        {showTooltip && !isMobile && (
          <PrimeTooltip
            key={`tooltip_${rowIndex}`}
            target={`#${iconName}_${rowIndex}`}
          />
        )}
        <button
          id={`${iconName}_${rowIndex}`}
          data-cy={
            typeof action.text === "string"
              ? action.text
              : action.text?.(rowData)
          }
          data-pr-at="center top-6"
          data-pr-tooltip={tooltipText(action)}
          data-pr-position="top"
          key={`action_${action.text || action.icon}`}
          type="button"
          className={classnames("padding-4", {
            [styles.tableRowActionButton]: true,
            customDatatableRowActionsOnlyOnHover: action.alwaysShow
              ? false
              : showRowsActionsOnHover,
          })}
          onClick={(event) => {
            event.stopPropagation(); // Prevents table row selection

            if (action.onClick) action.onClick(rowData, rowIndex, event);
          }}
          onFocus={() => {}} // Needed for mouse events
          onMouseOver={(event) => {
            if (action.onHover) action.onHover(rowData, rowIndex, event);
          }}
          onMouseLeave={(event) => {
            if (action.onLeave) action.onLeave(rowData, rowIndex, event);
          }}
          aria-label={tooltipText(action) ?? iconName}
        >
          <Icon
            key={`icon_${rowIndex}`}
            name={iconName}
            size={action.iconSize ?? 24}
            color={
              action.iconVariant === "secondary" ? "Grey-400" : "Primary-700"
            }
          />
        </button>
      </Fragment>
    );
  };

  return (
    (!rowActionContextMenu && (
      <MobileWrapper rowActions={rowActions} isMobile={isMobile}>
        <span
          className={classnames({
            [styles.tableRowActions]: true,
          })}
        >
          {rowActions.map((action) => {
            if (action?.hideForRow?.(rowData)) {
              return null;
            }
            return btnElement(action);
          })}
        </span>
      </MobileWrapper>
    )) ||
    ((rowActionContextMenu || isMobile) && (
      <>
        <div className="action-button-wrapper">
          <button
            type="button"
            className={styles.rowActionContextMenuBtn}
            onClick={(e) => popupContextRef?.current?.toggle(e)}
          >
            <Icon name="dots-horizontal" size={24} color="Grey-400" />
          </button>
        </div>
        <OverlayPanel ref={popupContextRef} className="actions-overlay">
          {rowActions.map((action) => {
            if (action?.hideForRow?.(rowData)) {
              return null;
            }
            const actionText =
              typeof action.text === "function"
                ? action.text?.(rowData)
                : action.text;
            const iconName =
              typeof action.icon === "string"
                ? action.icon
                : action.icon(rowData);
            return (
              <PopupContextMenuLinkButton
                key={tooltipText(action) ?? iconName}
                icon={iconName}
                text={actionText ?? ""}
                onClick={(e) => {
                  e?.stopPropagation(); // prevents table row selection
                  if (action.onClick) {
                    popupContextRef.current?.hide();
                    action.onClick(rowData, rowIndex);
                  }
                }}
              />
            );
          })}
        </OverlayPanel>
      </>
    ))
  );
};
