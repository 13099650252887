import { useState } from "react";
import { unassociateCommonContact } from "../../../core/api/contacts/contacts";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const RemoveAssociationModal = ({
  isOpen,
  objectToRemove,
  contactId,
  onSubmit,
  onClose,
}: {
  isOpen: boolean;
  objectToRemove?: { name: string; id: string };
  contactId: number;
  onSubmit: () => void;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [deleteLoading, setDeleteLoading] = useState(false);

  if (!objectToRemove) {
    return null;
  }

  const removeConnection = async () => {
    setDeleteLoading(true);
    try {
      await unassociateCommonContact(
        objectToRemove.id,
        contactId,
        authenticatedRequest
      );
      onSubmit();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Contacts:remove_association")}
      isOpen={isOpen}
      onClose={onClose}
      fixedWidth="576px"
      loading={deleteLoading}
      submitBtn={{
        text: t("Objects:remove_from"),
        variant: "destructive",
        onClick: removeConnection,
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: onClose,
      }}
    >
      <p>
        {t("Contacts:remove_association_text")}{" "}
        <strong>{`${objectToRemove.name}?`}</strong>
      </p>
    </PrimeModal>
  );
};
