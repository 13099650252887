import { useState, RefObject, useRef, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import Icon from "../Icon";
import { Button } from "../Button/Button";
import { TimePicker } from "./TimePicker";
import styles from "./DateTime.module.css";
import { dateToTimeString } from "../../../components/DateFilter/timeSpan";
import useLanguage from "../../../core/i18n/useLanguage";
import useTranslations from "../../../core/i18n/useTranslations";
import { TimeSpanType } from "../../../components/DateFilter/types";
import { getLocale } from "../../utils/localeHelper";

const HOUR_FORMAT = "24";
const DATE_FORMAT = "yy-mm-dd";
const TIME_FORMAT = "HH:mm";

export interface PopupActions {
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface DateTimeRangePickerProps {
  popupRef: RefObject<OverlayPanel>;
  onPresetClick?: () => void;
  minDate?: Date;
  maxDate?: Date;
  initialRange?: Date[];
  onSubmit: (range?: Date[]) => void;
  setDateType?: (args?: TimeSpanType) => void;
  prevDateType?: TimeSpanType;
}

const DateTimeRangePicker = ({
  popupRef,
  onPresetClick,
  minDate,
  maxDate,
  initialRange,
  onSubmit,
  setDateType,
  prevDateType,
}: DateTimeRangePickerProps) => {
  const t = useTranslations();
  const language = useLanguage();

  const locale = getLocale(language);

  const initialTimeRange = initialRange
    ? [
        dateToTimeString(initialRange[0], locale),
        dateToTimeString(initialRange[1], locale),
      ]
    : ["00:00", "23:59"];

  const inputRef = useRef<HTMLInputElement>(null);
  const [dateRange, setDateRange] = useState(initialRange);
  const [timeRange, setTimeRange] = useState(initialTimeRange);

  const canSubmit = dateRange?.[0];

  useEffect(() => {
    setDateRange(initialRange);
    setTimeRange(initialTimeRange);
  }, [initialRange]);

  return (
    <OverlayPanel ref={popupRef}>
      <div className={styles.popupCalendarContainer}>
        {onPresetClick && (
          <button
            type="button"
            className={styles.popupCalendarBackButton}
            onClick={onPresetClick}
          >
            <Icon name="chevron-left" size={16} className="mr-4" />
            <span>Presets</span>
          </button>
        )}

        <Calendar
          inline
          yearNavigator
          yearRange={`1990:${new Date().getFullYear()}`}
          selectionMode="range"
          inputRef={inputRef}
          showTime={false}
          dateFormat={DATE_FORMAT}
          hourFormat={HOUR_FORMAT}
          minDate={minDate}
          maxDate={maxDate}
          value={dateRange}
          locale={locale}
          className="datePickerCustomPopup__p-calendar datePickerWithBackButtonCustomPopup__p-calendar"
          onChange={(e) => {
            if (!e.value) {
              return;
            }
            const range = e.value as Date[];

            setDateRange(range);
          }}
        />
      </div>

      <div className={styles.popupTimeContainer}>
        <TimePicker
          hourFormat={HOUR_FORMAT}
          value={timeRange[0]}
          onChange={(time) => {
            if (!time) {
              return;
            }
            const updatedTimeRange = [time, timeRange[1]];
            setTimeRange(updatedTimeRange);
          }}
        />
        <span className={styles.timeSeparatorLine} />
        <TimePicker
          hourFormat={HOUR_FORMAT}
          value={timeRange[1]}
          onChange={(time) => {
            if (!time) {
              return;
            }
            const updatedTimeRange = [timeRange[0], time];
            setTimeRange(updatedTimeRange);
          }}
        />
      </div>

      <div className={styles.popupButtonsContainer}>
        <Button
          variant="primary"
          text={t("Table:apply")}
          onClick={() => {
            if (!dateRange) {
              return;
            }

            const range = DateTimeHelper.buildDateTimeRange(
              [dateRange[0], dateRange[1] ?? dateRange[0]],
              timeRange,
              TIME_FORMAT
            );
            onSubmit(range);
            popupRef.current?.hide();
          }}
          className="mr-6"
          disabled={!canSubmit}
          dataCy="date_filter_apply_btn"
        />
        <Button
          variant="secondary"
          text={t("Common:cancel")}
          onClick={() => {
            setDateRange(initialRange);
            setTimeRange(initialTimeRange);
            if (setDateType) {
              setDateType(prevDateType ?? undefined);
            }
            popupRef.current?.hide();
          }}
          dataCy="date_filter_cancel_btn"
        />
      </div>
    </OverlayPanel>
  );
};
export default DateTimeRangePicker;
