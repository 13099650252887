import { useState } from "react";
import { AxiosInstance } from "axios";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Spacer } from "../../../components/Layout/Layout";
import { CustomSettingsWrapper } from "./CustomSettings/CustomSettingsWrapper";
import {
  IExpectedUserSettings,
  IAllIntegrationTypes,
  IIntegrationType,
  IOrgIntegration,
  IIntegrationSettings,
} from "../../../core/api/organizations/types";
import {
  createIntegration,
  updateIntegration,
} from "../../../core/api/organizations/organizations";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { IntegrationSettings } from "./IntegrationSetting/IntegrationSettings";
import useTranslations from "../../../core/i18n/useTranslations";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import Divider from "../../../ui-lib/components/Divider/Divider";
import Button from "../../../ui-lib/components/Button/Button";
import CollapsibleBlock from "../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";
import useUser from "../../../core/user/useUser";

export const AddIntegartionsModal = ({
  open,
  orgId,
  integrationData,
  types,
  isNew,
  close,
  getOrganizationIntegrations,
}: {
  open: boolean;
  orgId: string;
  integrationData?: IOrgIntegration;
  types?: IAllIntegrationTypes;
  isNew?: boolean;
  close: () => void;
  getOrganizationIntegrations: (
    orgId: string,
    authenticatedRequest: AxiosInstance
  ) => Promise<void>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const methods = useForm<IOrgIntegration>({
    defaultValues: integrationData,
  });

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  const [customSettings, setCustomSettings] = useState<IExpectedUserSettings[]>(
    integrationData?.expectedUserSettings ?? []
  );

  const [integrationSettings, setIntegrationSettings] = useState<
    IIntegrationSettings[]
  >(integrationData?.integrationSettings ?? []);

  const [selectedType, setSelectedType] = useState<
    IIntegrationType | undefined
  >(types?.[integrationData?.integrationType as keyof IAllIntegrationTypes]);

  const onClose = () => {
    close();
    reset();
  };

  const removeCustomSetting = (indexToRemove: number) => {
    const updatedArray = getValues("expectedUserSettings")?.filter(
      (setting, index) => index !== indexToRemove
    );

    setCustomSettings(updatedArray || []);
    setValue("expectedUserSettings", updatedArray, { shouldDirty: true });
  };

  const removeIntegrationSetting = (indexToRemove: number) => {
    const updatedArray = getValues("integrationSettings")?.filter(
      (setting, index) => index !== indexToRemove
    );

    setIntegrationSettings(updatedArray || []);
    setValue("integrationSettings", updatedArray, { shouldDirty: true });
  };

  const onSubmit = async (data: IOrgIntegration) => {
    setIsLoading(true);

    try {
      if (!isNew && integrationData) {
        await updateIntegration(
          orgId,
          integrationData.integrationId,
          authenticatedRequest,
          data
        );
        onClose();
      } else {
        await createIntegration(orgId, authenticatedRequest, data);
        onClose();
      }
      notify({
        message: isNew
          ? t("Organizations:instance_created_successfully")
          : t("Organizations:instance_edited_successfully"),
      });
      await getOrganizationIntegrations(orgId, authenticatedRequest);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      loading={isLoading}
      fixedWidth="848px"
      isOpen={open}
      onClose={() => {
        reset();
        onClose();
      }}
      header={
        isNew
          ? t("Organizations:add_instance")
          : t("Organizations:edit_instance")
      }
      submitBtn={{
        text: t("Common:save_changes"),
        onClick: handleSubmit(onSubmit),
        disabled: !isDirty,
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: () => {
          reset();
          onClose();
        },
      }}
    >
      <p>{t("Organizations:new_integration_description")}</p>
      <Spacer size={16} />
      <FormProvider {...methods}>
        <Controller
          name="integrationType"
          control={control}
          render={({ field }) => {
            const integrationTypes = types
              ? Object.keys(types).map((type) => ({
                  id: type,
                  name: type,
                  isSelected: type === field.value,
                }))
              : [];
            return (
              <Dropdown
                items={integrationTypes}
                selectedItem={integrationTypes.find((item) => item.isSelected)}
                onSelectItem={(val) => {
                  const { id } = val;
                  field.onChange(id);
                  setSelectedType(types?.[id]);
                }}
                title={t("Common:integration")}
                disabled={!!integrationData?.integrationType || !types}
              />
            );
          }}
        />

        <Spacer size={16} />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              disabled={!isNew}
              inputRef={field.ref}
              label={t("Organizations:instance_name")}
              placeholder={t("Organizations:instance_name_placeholder")}
              value={field.value}
              onChange={field.onChange}
              validationError={errors.name && t("Errors:input_field_required")}
            />
          )}
        />
        <Spacer size={16} />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Cameras:labels_description")}
              placeholder={t("Organizations:instance_description_placeholder")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={16} />
        <Controller
          name="url"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:url")}
              placeholder={t("Common:url_placeholder")}
              value={field.value}
              onChange={field.onChange}
              validationError={errors.url && t("Errors:input_field_required")}
            />
          )}
        />
        <Spacer size={16} />
        <Controller
          name="apiKey"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:api_key_label")}
              placeholder={t("Common:api_key_placeholder")}
              description={t("Common:api_key_input_description")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.apiKey && t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={32} />
        {!!selectedType?.supportedOperations.length && (
          <>
            <Divider />
            <Spacer size={32} />
            <h2>{t("Common:endpoints")}</h2>
            <p>{t("Common:endpoints_description")}</p>
            <Spacer size={16} />
            {selectedType?.supportedOperations.includes("Create") && (
              <>
                <Controller
                  name="endpoints.create"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={`${t("Common:label_create").toLowerCase()}`}
                      description={t(
                        "Organizations:create_endpoint_input_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.endpoints?.create &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}
            {selectedType?.supportedOperations.includes("Update") && (
              <>
                <Controller
                  name="endpoints.update"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={`${t("Common:update").toLowerCase()}`}
                      description={t(
                        "Organizations:update_endpoint_input_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.endpoints?.update &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}
            {selectedType?.supportedOperations.includes("Delete") && (
              <>
                <Controller
                  name="endpoints.delete"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={`${t("Common:delete").toLowerCase()}`}
                      description={t(
                        "Organizations:delete_endpoint_input_description"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.endpoints?.delete &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}
            {selectedType?.supportedOperations.includes(
              "DeleteIntegration"
            ) && (
              <Controller
                name="endpoints.deleteIntegration"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    inputRef={field.ref}
                    label={`${t(
                      "Organizations:deleteIntegration"
                    ).toLowerCase()}`}
                    description={t(
                      "Organizations:deleteIntegration_endpoint_input_description"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.endpoints?.deleteIntegration &&
                      t("Errors:input_field_required")
                    }
                  />
                )}
              />
            )}
            <Spacer size={32} />
          </>
        )}
        <CollapsibleBlock
          withScroll={!!integrationSettings.length}
          title={t("Organizations:integration_settings")}
          visibility={!!errors.integrationSettings?.length}
        >
          {integrationSettings.map((i, index) => (
            <>
              {!!integrationSettings.length && (
                <Spacer key={`${i.key}_spacer1`} size={16} />
              )}
              <IntegrationSettings
                key={i.key}
                index={index}
                onRemoveSetting={() => {
                  removeIntegrationSetting(index);
                }}
              />
              {integrationSettings.length > 1 && (
                <Spacer key={`${i.key}_spacer2`} size={16} />
              )}
            </>
          ))}

          <Column align="start">
            <Spacer size={16} />
            <Button
              text={t("Organizations:add_integration_setting")}
              image="plus"
              variant="secondary"
              onClick={async () => {
                await trigger([
                  `integrationSettings.${integrationSettings.length - 1}`,
                ]);
                if (errors.integrationSettings) {
                  return;
                } else {
                  const updatedIntegrationsSettings = [
                    ...(getValues("integrationSettings") ?? []),
                  ];
                  updatedIntegrationsSettings?.push({
                    key: "",
                    value: "",
                  });
                  setValue("integrationSettings", updatedIntegrationsSettings, {
                    shouldDirty: true,
                  });
                  setIntegrationSettings(updatedIntegrationsSettings);
                }
              }}
            />
          </Column>
        </CollapsibleBlock>

        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />

        <Column align="start">
          <CollapsibleBlock
            withScroll={!!customSettings.length}
            title={t("Common:custom_settings")}
            visibility={!!errors.expectedUserSettings?.length}
          >
            {customSettings.map((setting, index) => (
              <>
                {!!customSettings.length && <Spacer size={16} />}
                <CustomSettingsWrapper
                  key={`${setting.parameterName}_${index}`}
                  settingIndex={index}
                  removeCustomSetting={() => {
                    removeCustomSetting(index);
                  }}
                />
                {customSettings.length > 1 && (
                  <Spacer key={`${setting.parameterName}_spacer`} size={16} />
                )}
              </>
            ))}
            <Spacer size={16} />
            <Button
              text={t("Organizations:add_custom_setting")}
              image="plus"
              variant="secondary"
              onClick={async () => {
                await trigger([
                  `expectedUserSettings.${customSettings.length - 1}`,
                ]);
                if (errors.expectedUserSettings) {
                  return;
                } else {
                  const updatedCustomSettings = [
                    ...(getValues("expectedUserSettings") ?? []),
                  ];
                  updatedCustomSettings?.push({
                    parameterName: "",
                    displayName: "",
                    settingType: "Int",
                    required: false,
                  });
                  setValue("expectedUserSettings", updatedCustomSettings, {
                    shouldDirty: true,
                  });
                  setCustomSettings(updatedCustomSettings);
                }
              }}
            />
          </CollapsibleBlock>
        </Column>
      </FormProvider>
      <Spacer size={16} />
    </PrimeModal>
  );
};
