import useTranslations from "../../../../core/i18n/useTranslations";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";
import { useState } from "react";

export const OverlapModal = ({
  isOpen,
  onSubmit,
  onClose,
}: {
  isOpen: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={isOpen}
      onClose={onClose}
      fixedWidth="576px"
      header={t("Objects:confirm_new_absence_event")}
      loading={isLoading}
      submitBtn={{
        text: t("Common:yes_create"),
        onClick: async () => {
          setIsLoading(true);
          await onSubmit();
          setIsLoading(false);
        },
        disabled: isLoading,
      }}
      cancelBtn={{ text: t("Common:no_cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:absence_overlap_text")}
      </p>
    </PrimeModal>
  );
};
