import { Controller } from "react-hook-form";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import { ObjectDetailsModel } from "../../../core/api/objects/types";
import CentralColorWidget from "../ObjectAdvanced/CentralColorWidget/CentralColorWidget";
import Divider from "../../../ui-lib/components/Divider/Divider";
import styles from "../ObjectAdvanced/CentralColorWidget/ColorPicker.module.css";

const alarmActionOptions = [4, 3, 2, 0];
const alarmRecallOptions = [1, 2, 3, 0];

export const ObjectResponseForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: ObjectDetailsModel;
  onSubmit: (values: ObjectDetailsModel) => Promise<void>;
}) => {
  const t = useTranslations();

  const formRef = useObjectFormRef();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useObjectForm<ObjectDetailsModel>({ defaultValues });

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
        reset(values);
      })}
      className="w-100"
    >
      <Row align="start">
        <Controller
          name="pin"
          control={control}
          rules={{
            minLength: 4,
            maxLength: 60,
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Objects:response_input_security_code")}
              value={field.value}
              onChange={field.onChange}
              validationError={errors.pin && t("Errors:input_response_pin")}
            />
          )}
        />
        <Spacer size={32} />
        <Column
          style={{
            width: "100%",
            flex: "auto",
          }}
        />
      </Row>

      <Spacer size={16} />

      <Controller
        name="threatPin"
        control={control}
        render={({ field }) => (
          <TextInput
            label={t("Objects:response_input_threat_code")}
            value={field.value}
            onChange={field.onChange}
            description={t("Objects:response_input_threat_code_description")}
          />
        )}
      />

      <Spacer size={16} />

      <Controller
        name="alarmAction"
        control={control}
        render={({ field }) => (
          <TextArea
            label={t("Objects:response_input_alarm_action")}
            placeholder={t("Objects:response_input_alarm_action_placeholder")}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <Spacer size={16} />

      <Controller
        name="alarmResponseAction"
        control={control}
        render={({ field }) => {
          const radioGroupItems = alarmActionOptions.map((id) => ({
            id,
            value: `alarm-response-action-${id}`,
            text: t(`Objects:response_input_alarm_action_${id}`),
            idForText: `alarm_action_${id}`,
          }));

          const selectedItem = radioGroupItems.find(
            (i) => i.id === (field.value ?? 0)
          );

          return (
            <RadioGroup
              label={t("Objects:response_input_alarm_action_label")}
              name={field.name}
              items={radioGroupItems}
              selectedValue={selectedItem?.value}
              onChange={(value) => {
                const item = radioGroupItems.find((i) => i.value === value);

                setValue(field.name, item?.id === 0 ? null : item?.id);
              }}
            />
          );
        }}
      />

      <Spacer size={16} />

      <Controller
        name="alarmRecallAction"
        control={control}
        render={({ field }) => {
          const radioGroupItems = alarmRecallOptions.map((id) => ({
            id,
            value: `alarm-recall-action-${id}`,
            text: t(`Objects:response_input_recall_action_${id}`),
            idForText: `recall_action_${id}`,
          }));

          const selectedItem = radioGroupItems.find(
            (i) => i.id === (field.value ?? 0)
          );

          return (
            <RadioGroup
              label={t("Objects:response_input_recall_action_label")}
              name={field.name}
              items={radioGroupItems}
              selectedValue={selectedItem?.value}
              onChange={(value) => {
                const item = radioGroupItems.find((i) => i.value === value);

                setValue(field.name, item?.id === 0 ? null : item?.id);
              }}
            />
          );
        }}
      />

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="threatAssessment"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Objects:response_input_threat_assessment")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="companyToUseForResponse"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Objects:response_input_company")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="policeInvestigationNumber"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Objects:response_input_police_number")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="contractNumber"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Objects:response_input_contract_number")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Controller
        name="reportedToPolice"
        control={control}
        render={({ field }) => (
          <Checkbox
            label={t("Objects:response_input_reported_to_police")}
            checked={field.value}
            onChange={field.onChange}
            style={{ padding: 0 }}
          />
        )}
      />

      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <h2 data-cy="userAcknowledgement">
        {t("Objects:acknowledgement_title")}
      </h2>
      <Spacer size={16} />
      <p className={styles.widgetText} data-cy="userAcknowledgementDescription">
        {t("Objects:acknowledgement_title_description")}
      </p>
      <Spacer size={16} />
      <Controller
        name="acknowledgement"
        control={control}
        rules={{ maxLength: 1000 }}
        render={({ field }) => (
          <TextArea
            inputRef={field.ref}
            label={t("Objects:message_text")}
            placeholder={t("Objects:enter_text_here")}
            value={field.value}
            onChange={field.onChange}
            validationError={
              errors.acknowledgement &&
              t("Errors:length_to_long").replace("{0}", "1000")
            }
          />
        )}
      />

      <CentralColorWidget
        defaultColor={defaultValues?.color}
        watch={watch}
        control={control}
        setValue={setValue}
      />
    </form>
  );
};
