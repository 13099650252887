/* eslint-disable react/jsx-props-no-spreading */
import { observer } from "mobx-react-lite";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  updateAlarmHandling,
  useAlarmHandling,
  useRespondentGroups,
} from "../../../../core/api/responsecenters/responsecenters";
import {
  AlarmHandlingDetailsParams,
  Calendar,
  RespondentGroupResponse,
  ResponseCenterDetails,
} from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { RespondentGroupControl } from "./Controls/RespondentGroupControl";
import { TimeoutControl } from "./Controls/TimeoutControl";
import { EditAlarmHandlingRules } from "./EditAlarmHandlingRules";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

interface EditAlarmHandlingProps {
  defaultValues: AlarmHandlingDetailsParams;
  respondentGroups: RespondentGroupResponse[];
  calendars?: Calendar[];
  onSubmit: (values: AlarmHandlingDetailsParams) => Promise<void>;
  setModalFullScreen: (value: boolean) => void;
  isModalFullScreen: boolean;
}

type EditAlarmHandlingActions = {
  submitForm: () => Promise<void>;
};

const EditAlarmHandlingForm = forwardRef<
  EditAlarmHandlingActions,
  EditAlarmHandlingProps
>(
  (
    {
      defaultValues,
      respondentGroups,
      calendars,
      onSubmit,
      setModalFullScreen,
      isModalFullScreen,
    }: EditAlarmHandlingProps,
    ref
  ) => {
    const t = useTranslations();

    const methods = useForm<AlarmHandlingDetailsParams>({
      defaultValues,
    });

    const {
      control,
      formState: { errors },
      setValue,
      handleSubmit,
    } = methods;

    const [enableThirdRespondentGroup, setEnableThirdRespondentGroup] =
      useState<boolean>(!!defaultValues.secondaryRespondentGroupId);

    useImperativeHandle<EditAlarmHandlingActions, EditAlarmHandlingActions>(
      ref,
      () => ({
        submitForm: async () => {
          try {
            handleSubmit(onSubmit)();
          } catch (error: any) {
            console.log(error);
          }
        },
      })
    );

    return (
      <FormProvider {...methods}>
        <form
          onSubmit={async (e) => {
            // To stop main form to submit
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Row align="start">
            <Column>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    inputRef={field.ref}
                    label={t("Common:name")}
                    description={t("AlarmReceptions:input_name_description")}
                    placeholder={t("AlarmReceptions:input_name_placeholder")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.name && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="primaryRespondentGroupId"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <RespondentGroupControl
                    isFullScreen={isModalFullScreen}
                    required
                    title={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_primary"
                    )}
                    selected={field.value}
                    onSelect={(id) => setValue(field.name, id as number)}
                    respondentGroups={respondentGroups}
                    validationError={
                      errors.primaryRespondentGroupId &&
                      t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column style={{ width: "100%" }}>
              <Controller
                name="secondaryRespondentGroupId"
                control={control}
                render={({ field }) => (
                  <RespondentGroupControl
                    isFullScreen={isModalFullScreen}
                    title={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_secondary"
                    )}
                    selected={field.value}
                    onSelect={(id) => {
                      if (id! > 0) {
                        setEnableThirdRespondentGroup(true);
                        setValue(field.name, id);
                      } else {
                        setEnableThirdRespondentGroup(false);
                        setValue(field.name, null);
                        setValue("secondaryRespondentGroupTimeout", 0);
                        setValue("thirdRespondentGroupId", null);
                        setValue("thirdRespondentGroupTimeout", 0);
                      }
                    }}
                    respondentGroups={respondentGroups}
                    withClearItem
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              <Controller
                name="secondaryRespondentGroupTimeout"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeoutControl
                    required
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_secondary_timeout"
                    )}
                    min={0}
                    max={3600}
                    value={field.value}
                    onChange={(value) => {
                      setValue(field.name, value, { shouldValidate: true });
                    }}
                    hasError={!!errors.secondaryRespondentGroupTimeout}
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column style={{ width: "100%" }}>
              <Controller
                name="thirdRespondentGroupId"
                control={control}
                render={({ field }) => (
                  <RespondentGroupControl
                    isFullScreen={isModalFullScreen}
                    required
                    title={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_third"
                    )}
                    selected={field.value}
                    onSelect={(id) => {
                      if (id! < 0) {
                        setValue("thirdRespondentGroupTimeout", 0);
                        setValue(field.name, null);
                      } else {
                        setValue(field.name, id);
                      }
                    }}
                    respondentGroups={respondentGroups}
                    withClearItem
                    disabled={!enableThirdRespondentGroup}
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              <Controller
                name="thirdRespondentGroupTimeout"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeoutControl
                    required
                    inputRef={field.ref}
                    label={t(
                      "AlarmReceptions:alarm_handling_input_respondent_group_third_timeout"
                    )}
                    min={0}
                    max={3600}
                    value={field.value}
                    onChange={(value) => {
                      setValue(field.name, value, { shouldValidate: true });
                    }}
                    disabled={!enableThirdRespondentGroup}
                    hasError={!!errors.thirdRespondentGroupTimeout}
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={32} />
          <Divider />
          <Spacer size={32} />

          <EditAlarmHandlingRules
            respondentGroups={respondentGroups}
            calendars={calendars}
            setModalFullScreen={setModalFullScreen}
            isModalFullScreen={isModalFullScreen}
          />
        </form>
      </FormProvider>
    );
  }
);

const EditAlarmHandlingModal = ({
  responseCenter,
  alarmHandlingId,
  modalOpen,
  onClose,
  onEdit,
}: {
  responseCenter: ResponseCenterDetails;
  alarmHandlingId: number | string;
  modalOpen: boolean;
  onClose: () => void;
  onEdit: (
    handlingId: string | number,
    handlingParams: {
      name: string;
      primaryRespondentGroup: string;
    }
  ) => void;
}) => {
  const EditAlarmHandlingFormRef = useRef<EditAlarmHandlingActions>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const { data } = useAlarmHandling(responseCenter.id, alarmHandlingId);
  const { data: respondentGroups } = useRespondentGroups(responseCenter.id);

  if (!responseCenter) {
    return <></>;
  }

  const onSubmit = async (params: AlarmHandlingDetailsParams) => {
    setIsSubmitting(true);
    // If Technical settings -> Advanced settings -> Allow calendars for alarm handling is disabled then
    // remove all calendarIds from rules
    // in other hand setup selected calendarId or default value = 1
    const updated = params.rules.map((rule, index) => {
      if (!responseCenter.allowCalendars) {
        delete rule.calendarId;
      } else {
        rule.calendarId = params.rules[index].calendarId ?? 1;
      }
      // Change order for new rule added on top with reodering prev items
      rule.order = index + 1;
      return rule;
    });
    try {
      await updateAlarmHandling(
        responseCenter.id,
        alarmHandlingId,
        { ...params, rules: updated },
        authenticatedRequest
      );
      notify({
        message: t("Messages:edit_alarm_handling_success"),
      });
      const respondentGroupName = respondentGroups?.find(
        (group) => group.id === params.primaryRespondentGroupId
      )?.description;
      onEdit(alarmHandlingId, {
        name: params.name,
        primaryRespondentGroup: respondentGroupName!,
      });
    } catch (error: any) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      fixedWidth={fullScreen ? "100vw" : "1000px"}
      isOpen={modalOpen}
      onClose={onClose}
      header={t("AlarmReceptions:alarm_handling_edit")}
      submitBtn={{
        text: t("Common:save_changes"),
        onClick: () => EditAlarmHandlingFormRef.current?.submitForm(),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
      loading={isSubmitting}
    >
      {!respondentGroups || !data ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <>
          <p style={{ color: "var(--Grey-600)" }}>
            {t("AlarmReceptions:alarm_handling_edit_description")}
          </p>
          <Spacer size={16} />
          <EditAlarmHandlingForm
            ref={EditAlarmHandlingFormRef}
            defaultValues={{ ...data }}
            respondentGroups={respondentGroups}
            calendars={responseCenter.calendars}
            onSubmit={onSubmit}
            setModalFullScreen={setFullScreen}
            isModalFullScreen={fullScreen}
          />
        </>
      )}
    </PrimeModal>
  );
};

export default observer(EditAlarmHandlingModal);
