import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../core/i18n/useTranslations";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { notifyError } from "../../components/Errors/ErrorAlert";
import { Column, Spacer } from "../../components/Layout/Layout";
import PasswordInput from "../../ui-lib/components/Inputs/PasswordInput";
import { PasswordFormValues } from "../Administrators/AdministratorDetails/General/ChangePasswordModal";
import { PasswordConstraints } from "../../core/api/administrators/types";
import authApi from "../../core/api/auth";
import {
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validateUpperCase,
} from "../../core/helpers/validation";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import {
  useWindowDimensions,
  breakpoints,
} from "../../core/hooks/dimensionProvider";
import { ResponsiveWrapper } from "../../components/ResponsiveWrapper/ResponsiveWrapper";

export const ResetPasswordContent = ({
  isOpen,
  data,
  resetPasswordToken,
  onPasswordReset,
  onClose,
  onTwofactor,
}: {
  isOpen: boolean;
  data?: PasswordConstraints;
  resetPasswordToken?: string;
  onPasswordReset: () => void;
  onTwofactor: (twoFAData: {
    token: string;
    authType: string;
    username: string;
    password: string;
    phoneNumber?: string;
    resetPasswordToken?: string;
  }) => void;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordFormValues>();

  const onSubmit = async (values: PasswordFormValues) => {
    setLoading(true);
    try {
      const dataToSend = {
        token: resetPasswordToken!,
        password: values.password,
      };
      await authApi.resetPassword(dataToSend);

      notify({
        message: t("Administrator:change_password_success"),
      });

      onPasswordReset();
    } catch (error: any) {
      if (
        error?.response?.data?.errors[0]?.errorMessage ===
        "TwoFactorAuthenticationRequiredException"
      ) {
        onTwofactor({
          token: resetPasswordToken!,
          authType: error.response?.data?.data?.description,
          username: "",
          password: values.password,
          resetPasswordToken: resetPasswordToken,
        });
        return;
      } else {
        notifyError({ error, t });
      }
    }
    setLoading(false);
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      fixedWidth="848px"
      isOpen={isOpen}
      header={t("Administrator:change_password")}
      onClose={onClose}
      loading={loading}
      submitBtn={{
        text: t("Administrator:change_password"),
        onClick: handleSubmit(onSubmit),
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: onClose,
      }}
    >
      <form>
        <p className="grey-600">
          {t("Administrator:change_password_description")}
        </p>

        <Spacer size={16} />

        <ResponsiveWrapper rowAlign="start">
          <Column className="w-100">
            <Controller
              name="password"
              control={control}
              rules={
                data && {
                  required: true,
                  minLength: data.minLength,
                  validate: {
                    lowerCase: (v) => validateLowerCase(v, data),
                    upperCase: (v) => validateUpperCase(v, data),
                    countNumbers: (v) => validateNumberCount(v, data),
                    forbiddenChars: (v) => validateForbiddenChars(v, data),
                  },
                }
              }
              render={({ field }) => (
                <PasswordInput
                  withIndicator
                  withHint
                  id="password"
                  invalid={!!errors.password}
                  passwordConstraints={data}
                  inputRef={field.ref}
                  label={t("Administrator:change_password_input_new")}
                  placeholder={t(
                    "Administrator:change_password_input_new_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Column>
          <Spacer size={width < breakpoints.desktop ? 16 : 32} />
          <Column className="w-100">
            <Controller
              name="passwordRepeat"
              control={control}
              rules={{
                required: true,
                validate: (v) => watch("password") === v,
              }}
              render={({ field }) => (
                <PasswordInput
                  inputRef={field.ref}
                  label={t("Administrator:change_password_input_new_repeat")}
                  placeholder={t(
                    "Administrator:change_password_input_new_placeholder"
                  )}
                  value={field.value}
                  onChange={field.onChange}
                  validationError={
                    errors.passwordRepeat && t("Errors:password_mismatch")
                  }
                />
              )}
            />
          </Column>
        </ResponsiveWrapper>
      </form>
    </PrimeModal>
  );
};
