import useTranslations from "../../../core/i18n/useTranslations";
import useSharedReseller from "../SharedResellerContext/useSharedReseller";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { Spacer } from "../../../components/Layout/Layout";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";
import { RetentionForm } from "../../../components/RetentionForm/RetentionForm";
import styles from "../../Organizations/RetentionDetails/RetentionDetails.module.css";

export const ResellerRetention = () => {
  const t = useTranslations();
  const {
    fetchRetentionOptions,
    retentionOptions,
    fetchRetentionDetails,
    retentionDetails,
    isLoading,
    updateRetention,
  } = useSharedReseller();
  const [options, setOptions] = useState<IDropdownItem[]>([]);

  useEffect(() => {
    if (retentionOptions && Object.entries(retentionOptions).length) {
      const dropdownOptions: IDropdownItem[] = Object.entries(
        retentionOptions
      ).flatMap(([key, value]) => ({
        id: key,
        name: t(`Organizations:${value}`),
        isSelected: false,
      }));
      setOptions(dropdownOptions);
    }
  }, [retentionOptions]);

  const onSubmit = async (values: any) => {
    try {
      await updateRetention(values);

      notify({ message: t("Messages:reseller_updated") });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const checkForData = async () => {
    if (!retentionDetails) {
      await fetchRetentionDetails();
    }
    if (!retentionOptions) {
      await fetchRetentionOptions();
    }
  };

  useEffect(() => {
    checkForData();
  }, []);

  if (isLoading || !retentionDetails || !options) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <div className={styles.retentionWrapper}>
      <h1>{t("Menu:Organization_RetentionDetails")}</h1>
      <Spacer size={8} />
      <p>{t("Reseller:retention_details_description")}</p>

      <Spacer size={32} />

      <RetentionForm
        retentionOptions={options}
        onSubmit={onSubmit}
        defaultValues={retentionDetails}
        formType="reseller"
      />
    </div>
  );
};
