import { Controller, useFormContext } from "react-hook-form";
import { Spacer, TwoColGrid } from "../../../components/Layout/Layout";
import { validatePhoneNumberIfExists } from "../../../core/helpers/validation";
import Autocomplete from "../../../ui-lib/components/Autocomplete/Autocomplete";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import CollapsibleBlock from "../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import NumberInput from "../../../ui-lib/components/Inputs/NumberInput";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";

import styles from "./ObjectGeneral.module.css";

const genderOptions = [1, 2, 3];
const maritalOptions = [1, 2, 3, 4, 5, 6];
const hairColorOptions = Array.from(Array(12).keys()).slice(1);

const ObjectGeneralMorePersonalInfo = () => {
  const t = useTranslations();
  const { config, dateFormat } = useUser();
  const { objectFields } = useSharedObject();

  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  const hasGbgAccess = config?.show.includes("Gbg");
  const hasShowObjectDetailsVariant1Permission = config?.show.includes(
    "ShowObjectDetailsVariant1"
  );
  return (
    !config?.show.includes("CareiumHideFields") && (
      <div className={styles.morePersonalInfoWrapper}>
        <CollapsibleBlock
          withScroll
          title={t("Objects:personal_extra_section")}
        >
          <>
            <Spacer size={32} />
            <h2>{t("Objects:general_personal_advanced_title")}</h2>
            <Spacer size={8} />
            <p>{t("Objects:general_personal_advanced_description")}</p>

            <Spacer size={16} />

            {!hasGbgAccess && (
              <>
                <Controller
                  name="exchangeTelephoneNumber"
                  control={control}
                  rules={{
                    validate: validatePhoneNumberIfExists,
                  }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t("Common:labels_showen_number")}
                      placeholder={t("Common:phone_number_placeholder")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.exchangeTelephoneNumber &&
                        t("Objects:Validation_identifier_format")
                      }
                    />
                  )}
                />
                <Spacer size={16} />
              </>
            )}

            <TwoColGrid>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t("Common:labels_dob")}
                    value={field.value ? new Date(field.value) : undefined}
                    placeholder={dateFormat?.split("/").join("-")}
                    onChange={(dateString) => {
                      field.onChange(
                        dateString
                          ? DateTimeHelper.formatDateISOFormat(dateString)
                          : null
                      );
                    }}
                    dateFormat={dateFormat?.split("/").join("-")}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    withClearItem
                    items={genderOptions.map((id) => ({
                      id,
                      name: t(`CommonEnum:Gender0${id}`),
                      isSelected: field.value === id,
                    }))}
                    selectedItem={
                      field.value
                        ? {
                            name: t(`CommonEnum:Gender0${field.value}`),
                            id: field.value || "",
                            isSelected: true,
                          }
                        : undefined
                    }
                    onSelectItem={(item) => {
                      field.onChange(
                        item.id === -1 ? null : (item.id as number)
                      );
                      item.isSelected = true;
                    }}
                    title={t("Common:labels_gender")}
                    placeholder={t("Common:placeholder_gender")}
                  />
                )}
              />
            </TwoColGrid>

            {!hasShowObjectDetailsVariant1Permission && (
              <>
                <Spacer size={16} />

                <TwoColGrid>
                  <Controller
                    name="height"
                    control={control}
                    rules={{
                      validate: (v) => {
                        if (!v) return true;
                        return v <= 10000 && v % 1 === 0;
                      },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        inputRef={field.ref}
                        label={t("Common:labels_height")}
                        value={`${field.value}`}
                        placeholder={t("Common:placeholder_height")}
                        onChange={(e) => {
                          if (e.target.value) {
                            setValue(field.name, +e.target.value);
                          } else {
                            setValue(field.name, null);
                          }
                        }}
                        validationError={
                          errors?.height &&
                          t("Errors:value_should_be_less_and_integer").replace(
                            "{0}",
                            "10000"
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    name="weight"
                    control={control}
                    rules={{
                      validate: (v) => {
                        if (!v) return true;
                        return v <= 10000 && v % 1 === 0;
                      },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        inputRef={field.ref}
                        label={t("Common:labels_weight")}
                        value={`${field.value}`}
                        placeholder={t("Common:placeholder_weight")}
                        onChange={(e) => {
                          if (e.target.value) {
                            setValue(field.name, +e.target.value);
                          } else {
                            setValue(field.name, null);
                          }
                        }}
                        validationError={
                          errors?.weight &&
                          t("Errors:value_should_be_less_and_integer").replace(
                            "{0}",
                            "10000"
                          )
                        }
                      />
                    )}
                  />
                </TwoColGrid>
              </>
            )}

            <Spacer size={16} />

            <TwoColGrid>
              {!hasShowObjectDetailsVariant1Permission && (
                <Controller
                  name="hairColour"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      items={hairColorOptions.map((id) => ({
                        id,
                        name: t(`CommonEnum:HairColour${id}`),
                        isSelected: field.value === id,
                      }))}
                      selectedItem={
                        field.value
                          ? {
                              name: t(`CommonEnum:HairColour${field.value}`),
                              id: field.value || "",
                              isSelected: true,
                            }
                          : undefined
                      }
                      onSelectItem={(item) => {
                        setValue("hairColour", item.id as number);
                        item.isSelected = true;
                      }}
                      title={t("Common:labels_hair_color")}
                      placeholder={t("Common:placeholder_hair_color")}
                    />
                  )}
                />
              )}
              <Controller
                name="maritalStatus"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    items={maritalOptions.map((id) => ({
                      id,
                      name: t(`CommonEnum:MaritalStatus_${id}`),
                      isSelected: field.value === id,
                    }))}
                    selectedItem={
                      field.value
                        ? {
                            name: t(`CommonEnum:MaritalStatus_${field.value}`),
                            id: field.value || "",
                            isSelected: true,
                          }
                        : undefined
                    }
                    onSelectItem={(item) => {
                      setValue("maritalStatus", +item.id);
                      item.isSelected = true;
                    }}
                    title={t("Common:labels_marital_status")}
                    placeholder={t("Common:placelholder_select_marital_status")}
                  />
                )}
              />
            </TwoColGrid>

            {!hasShowObjectDetailsVariant1Permission && (
              <>
                <Spacer size={16} />

                <TwoColGrid>
                  <Autocomplete
                    fieldData={objectFields?.debtorNumber}
                    name="debtorNumber"
                    defaultLabel={t("Common:labels_debtor_number")}
                    defaultPlaceholder={t("Common:language_debtor_number")}
                  />

                  <Autocomplete
                    fieldData={objectFields?.personalOther}
                    name="personalOther"
                    defaultLabel={t("Common:labels_personal_other")}
                    defaultPlaceholder={t("Common:language_personal_other")}
                  />
                </TwoColGrid>
              </>
            )}

            <Spacer size={32} />

            <h2>{t("Objects:general_personal_language_title")}</h2>
            <Spacer size={8} />
            <p>{t("Objects:general_personal_language_description")}</p>

            <Spacer size={16} />

            <TwoColGrid>
              <Autocomplete
                fieldData={objectFields?.firstLanguage}
                name="firstLanguage"
                defaultPlaceholder={t("Common:language_english")}
                defaultLabel={`${t("Common:part_label_first")} ${t(
                  "Common:part_label_language"
                )}`}
              />

              <Autocomplete
                fieldData={objectFields?.secondLanguage}
                name="secondLanguage"
                defaultLabel={`${t("Common:part_label_second")} ${t(
                  "Common:part_label_language"
                )}`}
              />
            </TwoColGrid>

            <Spacer size={16} />

            <TwoColGrid>
              <Autocomplete
                fieldData={objectFields?.thirdLanguage}
                name="thirdLanguage"
                defaultLabel={`${t("Common:part_label_third")} ${t(
                  "Common:part_label_language"
                )}`}
              />
              {!hasShowObjectDetailsVariant1Permission && (
                <Autocomplete
                  fieldData={objectFields?.citizenShip}
                  name="citizenShip"
                  defaultPlaceholder={t("Common:placeholder_citizenship")}
                  defaultLabel={t("Common:label_citizenship")}
                />
              )}
            </TwoColGrid>

            <Spacer size={32} />
            {!hasShowObjectDetailsVariant1Permission && (
              <>
                <h2>{t("Objects:general_personal_miscellaneous_title")}</h2>

                <Spacer size={16} />

                <TwoColGrid>
                  <Controller
                    name="insurance"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label={t("Common:labels_insurance_information")}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Autocomplete
                    fieldData={objectFields?.keyPlacement}
                    name="keyPlacement"
                    defaultLabel={t("Common:labels_key_marking")}
                  />
                </TwoColGrid>
                <Spacer size={16} />
                {objectFields?.["referalNotes"] ? (
                  <Autocomplete
                    fieldData={objectFields?.referalNotes}
                    name="referalNotes"
                    defaultLabel={t("Common:labels_referal_notes")}
                    defaultPlaceholder={t("Common:placeholder_referal_notes")}
                  />
                ) : (
                  <Controller
                    name="referalNotes"
                    control={control}
                    rules={{ maxLength: 1000 }}
                    render={({ field }) => (
                      <TextArea
                        inputRef={field.ref}
                        label={t("Common:labels_referal_notes")}
                        placeholder={t("Common:placeholder_referal_notes")}
                        value={field.value}
                        onChange={field.onChange}
                        validationError={
                          errors?.referalNotes &&
                          t("Errors:max_chars_exceeded").replace("{0}", "1000")
                        }
                      />
                    )}
                  />
                )}

                <Spacer size={16} />

                {objectFields?.["generalNotes"] ? (
                  <Autocomplete
                    fieldData={objectFields?.generalNotes}
                    name="generalNotes"
                    defaultLabel={t("Common:labels_general_notes")}
                    defaultPlaceholder={t("Common:placeholder_general_notes")}
                  />
                ) : (
                  <Controller
                    name="generalNotes"
                    control={control}
                    rules={{ maxLength: 1000 }}
                    render={({ field }) => (
                      <TextArea
                        inputRef={field.ref}
                        label={t("Common:labels_general_notes")}
                        placeholder={t("Common:placeholder_general_notes")}
                        value={field.value}
                        onChange={field.onChange}
                        validationError={
                          errors?.generalNotes &&
                          t("Errors:max_chars_exceeded").replace("{0}", "1000")
                        }
                      />
                    )}
                  />
                )}
              </>
            )}
          </>
        </CollapsibleBlock>
      </div>
    )
  );
};

export default ObjectGeneralMorePersonalInfo;
