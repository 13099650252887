import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import useUser from "./useUser";

const REFRESH_CHECK_INTERVAL = 10000; // Check every 10 seconds
const REFRESH_IN_PROGRESS_KEY = "refreshInProgress";

const setIntervalAndExecute = (fn: () => void) => {
  fn();

  return setInterval(fn, REFRESH_CHECK_INTERVAL);
};

const UserInitializer = () => {
  const user = useUser();
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === "visible");
  }, [document.visibilityState]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Initialize logged in user when component mounts
  useEffect(() => {
    if (user.loggedIn && !user.initialized) {
      user.initialize().catch(() => {
        user.logOut();
      });
    }
  }, []);

  // Setup token refresh handling
  useEffect(() => {
    const refreshTimer = setIntervalAndExecute(async () => {
      try {
        if (user.needsTokenRefresh()) {
          if (
            localStorage.getItem(REFRESH_IN_PROGRESS_KEY) === "true" ||
            !isTabVisible
          ) {
            return;
          }

          localStorage.setItem(REFRESH_IN_PROGRESS_KEY, "true");

          try {
            await user.refreshToken();
          } finally {
            localStorage.removeItem(REFRESH_IN_PROGRESS_KEY);
          }
        }
      } catch {
        // Logout user if refresh token fails
        user.logOut();
      }
    });

    return () => {
      // Stop checking when component unmounts
      clearTimeout(refreshTimer);
    };
  }, []);

  return <></>;
};

export default observer(UserInitializer);
