import { useMemo } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { IDropdownItem } from "../Dropdown/DropdownItem";
import Dropdown from "../Dropdown/Dropdown";

export const SingleChoice = ({
  name,
  options,
  placeholder,
  withClearOption,
  field,
}: {
  name: string;
  options: string[];
  placeholder?: string;
  withClearOption?: boolean;
  field: ControllerRenderProps<FieldValues, string>;
}) => {
  const { watch } = useFormContext();

  const selectedValue = watch(name);

  const dropdownOptions: IDropdownItem[] = useMemo(
    () =>
      options.map((item, index) => ({
        id: index,
        name: item,
        isSelected: item === selectedValue,
      })),
    [selectedValue]
  );

  return (
    <Dropdown
      withFilter
      withClearItem={withClearOption}
      placeholder={placeholder}
      selectedItem={dropdownOptions.find((o) => o.isSelected)}
      items={dropdownOptions}
      onSelectItem={(option) => {
        if (option.id === -1) {
          return field.onChange(null);
        }
        field.onChange(option.name);
      }}
      maxVisible={7}
    />
  );
};
