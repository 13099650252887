import { useMemo } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import DropdownWithCheckboxes from "../Dropdown/DropdownWIthCheckboxes/DropdownWIthCheckboxes";
import { ICheckbox } from "../Checkbox/Checkbox";

export const MultiChoice = ({
  name,
  options,
  field,
  placeholder,
}: {
  name: string;
  options: string[];
  field: ControllerRenderProps<FieldValues, string>;
  placeholder?: string;
}) => {
  const { watch } = useFormContext();
  const value = watch(name)
    ?.replace(/\s*,?\s*\n/g, ", ")
    .split(", ");

  const dropdownOptions: ICheckbox[] = useMemo(() => {
    return options.map((o, idx) => {
      return {
        id: idx,
        label: o,
        checked: value?.some((v: string) => v === o),
      };
    });
  }, []);

  return (
    <DropdownWithCheckboxes
      withFilter
      maxVisible={7}
      id={`${name}_multichoice`}
      items={dropdownOptions}
      getSelectedItems={(values) => {
        field.onChange(values.map((v) => v.label).join(", "));
      }}
      placeholder={placeholder}
    />
  );
};
