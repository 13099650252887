import classnames from "classnames";
import { snakeCase } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DateFilterDropdown, {
  useDateDropdownFilter,
} from "../../components/DateFilter/DateFilterDropdown";
import ExportAlarmHistorySettings from "../../components/ExportModal/ExportAlarmHistorySettings";
import TableSearch from "../../components/FilterSearch/TableSearch";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import {
  BaseColumnsProps,
  BaseTableDataResponse,
  FilterData,
  getTableDataWithoutHook,
  postSelectedTableColumns,
  useBaseFilters,
  useTableColumns,
  useTableData,
} from "../../core/api/common_table";
import { sortParamsToString } from "../../core/helpers/helpers";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useSearchFilter } from "../../core/hooks/filters/useSearchFilter";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { CustomFilters } from "../../ui-lib/components/Tables/CustomFilters";
import { AlarmHistoryDetails } from "./AlarmHistoryDetails/AlarmHistoryDetails";
import { AlarmHistoryTable } from "./AlarmHistoryTable";
import {
  storeTableState,
  useStoredTableState,
} from "../../core/hooks/filters/useStoredTableState";
import { searchStringToFilterMap } from "../../ui-lib/utils/customFilters";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import {
  MoreState,
  toMoreState,
  useMoreFilter,
} from "../../core/hooks/filters/useMoreFilter";
import { useAutoDeactivatedFilter } from "../../core/hooks/filters/useAutoDeactivatedFilter";
import { urlSearchParser } from "../../ui-lib/utils/urlSearchParser";
import useUser from "../../core/user/useUser";
import MultiStringInput from "../../ui-lib/components/MultiInput/MultiStringInput";
import { useAlarmCodesFilter } from "../../core/hooks/filters/useAlarmCodesFilter";
import { useCustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import { useOrganisationFilter } from "../../core/hooks/filters/useOrganisationFilter";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import Tree from "../../ui-lib/components/Hierarchy/Tree";
import { useModalIdHook } from "../../core/hooks/modalIdsHandlers/useIdModalHook";
import { TimeSpanType } from "../../components/DateFilter/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import TableDataControlButton from "../../ui-lib/components/Tables/TableDataControlButton";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { ExactMatchTogller } from "../../components/ExactMatchTogller";
import { Role, RoleFeature } from "../../components/RoleFeature";

import styles from "../PagesLayout.module.css";

const PAGE_URL = "api/v2/alarms";
// This component is massive and complex, we should probably split it up. Also it has many similarities with Objects.
const AlarmHistory = ({
  objectId,
  sectionsToHide,
  enableColumnSelect,
}: {
  objectId?: string;
  sectionsToHide?: string[];
  enableColumnSelect?: boolean;
}) => {
  const t = useTranslations();
  const pageState = usePageState();
  const user = useUser();
  const [alarmDetailsId, setAlarmDetailsId] = useState<string>("");
  const { data: treeData } = useOrganizationsTree();
  const history = useHistory();
  const tableState = useStoredTableState(!objectId ? PAGE_URL : "");
  const searchState = urlSearchParser(tableState);

  const viserPermission = user.config?.show.includes("SensioHideFields");

  // Table settings
  const { searchFilter, setSearchFilter, resetSearchFilter } =
    useSearchFilter(searchState);
  const {
    autoDeactivatedFilter,
    setAutoDeactivatedFilter,
    resetAutoDeactivatedFilter,
  } = useAutoDeactivatedFilter(searchState);
  const {
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    resetDateSettings,
  } = useDateDropdownFilter({ searchState });
  const { sortParams, setSortParams } = useSortParams(searchState);
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter(
    searchState ?? {
      page: 1,
      pageSize: objectId ? 10 : 20,
    }
  );
  const { resetMoreFilter } = useMoreFilter(searchState);
  const { alarmCodesFilter, setAlarmCodesFilter, resetAlarmCodesFilter } =
    useAlarmCodesFilter(searchState);
  const { customersFilter, setCustomersFilter, resetCustomersFilter } =
    useCustomersFilter(searchState);
  const organisationFilter = useOrganisationFilter(customersFilter, treeData);

  const [alarmId, setAlarmId, resetAlarmId] = useModalIdHook(
    searchState?.alarmId,
    "alarmId"
  );

  // Table columns
  const [selectedColumns, setSelectedColumns] = useState<BaseColumnsProps>();
  const { data: allAlarmColumns, isLoading: allColumnsLoading } =
    useTableColumns(PAGE_URL);
  const { data: selectedAlarmColumns, isLoading: selectedColumnsLoading } =
    useTableColumns(PAGE_URL, true);

  // Custom filters
  const [customFilters, setCustomFilters] = useState<FilterData[]>();
  const [unsavedCustomFilters, setUnsavedCustomFilters] = useState<MoreState>(
    {}
  );

  const [exportModalOpen, setExportModalOpen] = useState(false);

  const { data: alarmFilters } = useBaseFilters(PAGE_URL);

  const availableFilters = useCallback(() => {
    const otherFilters: FilterData[] = [];
    if (searchFilter?.id) otherFilters.push(searchFilter as FilterData);

    const allFilters: FilterData[] = [...otherFilters];
    if (customFilters) allFilters.push(...customFilters);

    return {
      // 4 - is max amount of filters we can pass to back-end
      amount: 4 - otherFilters.length,
      available:
        alarmFilters?.filter(
          (el) => !allFilters.find((item) => item.id === el.id)
        ) || [],
      notAvailable: allFilters,
    };
  }, [searchFilter, autoDeactivatedFilter, customFilters, alarmFilters])();

  // Data fetch
  const [tableData, setTableData] = useState<BaseTableDataResponse>();

  const fetchParams = {
    start: dateFilter.start
      ? moment(dateFilter.start).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    end: dateFilter.end
      ? moment(dateFilter.end).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    sort: sortParamsToString(sortParams),
    page: pageFilter?.page,
    pageSize: pageFilter?.pageSize,
    filter: customFilters,
    hideautodeactivated: autoDeactivatedFilter.hideautodeactivated,
    ...(objectId ? { user: objectId } : {}),
    alarmCodes: alarmCodesFilter.alarmCodes,
    customer: organisationFilter,
    organizationIds: [],
    // uncomment when it will be available on back-end
    // exact: searchFilter.exact,
  };

  const { data, isLoading } = useTableData(fetchParams, PAGE_URL, searchFilter);

  const resetTablePage = () => {
    setPageFilter({
      page: 1,
      pageSize: pageFilter.pageSize,
    });
  };

  useEffect(() => {
    setCustomFilters(searchStringToFilterMap(tableState ?? ""));
  }, []);

  useLayoutEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const refetchData = async () => {
    const { data: updated } = await getTableDataWithoutHook(
      fetchParams,
      PAGE_URL,
      searchFilter
    );

    if (updated) {
      setTableData(updated);
    }
  };

  useEffect(() => {
    if (alarmId.id && !alarmDetailsId) {
      setAlarmDetailsId(`${alarmId.id}`);
    }
  }, [alarmId]);

  // Effects
  const onClear = () => {
    resetSearchFilter();
    resetDateSettings();
    resetPageFilter();
    setCustomFilters(undefined);
    resetMoreFilter();
    setUnsavedCustomFilters({});
    resetAutoDeactivatedFilter();
    resetAlarmCodesFilter();
    resetCustomersFilter();
    history.push({ search: "page=1&pageSize=10" });
  };

  useEffect(() => {
    if (selectedAlarmColumns && !selectedColumns)
      setSelectedColumns(selectedAlarmColumns);
  }, [selectedAlarmColumns, selectedColumns]);

  const onSelectedColumnChange = async (columns: BaseColumnsProps) => {
    await postSelectedTableColumns(PAGE_URL, columns);
    setSelectedColumns(columns);

    await refetchData();
  };

  // Clear table state on sub page on unmount
  useEffect(
    () => () => {
      if (objectId) {
        localStorage.removeItem("table-sub-alarms");
      }
    },
    []
  );

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:alarmhistory"),
      })
    );
  }, []);

  if (!objectId) storeTableState(PAGE_URL);

  const isFiltersApplied =
    searchFilter.value ||
    searchFilter.id !== -1 ||
    autoDeactivatedFilter.hideautodeactivated ||
    dateFilter.start ||
    alarmCodesFilter.alarmCodes?.length ||
    customersFilter.customers ||
    customFilters?.length;

  return (
    <>
      {exportModalOpen && (
        <ExportAlarmHistorySettings
          isOpen={exportModalOpen}
          labels={{
            title: t("AlarmHistory:export"),
            description: t("AlarmHistory:all"),
          }}
          tableParams={{
            filter: customFilters,
            sort: sortParamsToString(sortParams),
            preselectedDateType:
              dateType === TimeSpanType.Custom ||
              dateType === TimeSpanType.Last7Days
                ? dateType
                : TimeSpanType.Today,
            customers: customersFilter.customers,
            alarmCodes: alarmCodesFilter.alarmCodes,
            hideautodeactivated: autoDeactivatedFilter.hideautodeactivated,
            // Condition fields start/end
            ...(dateType === TimeSpanType.Custom
              ? {
                  start: dateFilter.start?.toISOString(),
                  end: dateFilter.end?.toISOString(),
                }
              : {}),
          }}
          searchFilter={searchFilter}
          columnInfo={selectedColumns?.columns}
          onClose={() => setExportModalOpen(false)}
        />
      )}
      {!sectionsToHide?.includes("headerExport") && (
        <PageHeader
          title={t("Menu:alarmhistory")}
          pageTitle
          icon={pageState.pageIcon}
        >
          {user.config?.show?.includes("Reports") && !viserPermission && (
            <Button
              variant="secondary"
              text={t("Common:export")}
              onClick={() => setExportModalOpen(true)}
            />
          )}
        </PageHeader>
      )}
      <Column className={styles.content}>
        {!sectionsToHide?.includes("tableSearch") && (
          <>
            <Row className={styles.settingsRow}>
              <TableSearch
                placeholder={t(`AlarmHistory:search_placeholder`)}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
                resetPageFilter={resetTablePage}
                types={
                  alarmFilters
                    ? [
                        ...alarmFilters
                          .filter((alarmFilter) => alarmFilter.id !== 2)
                          .map((i) => ({
                            id: i.id,
                            name: t(`AlarmHistory:${snakeCase(i.keyName)}`),
                            disabled: !!availableFilters.notAvailable.find(
                              (el) => el.id === i.id
                            ),
                          })),
                        {
                          id: -2,
                          name: t("AlarmHistory:alarm_text_label"),
                          disabled: false,
                        },
                      ]
                    : []
                }
              />
              <RoleFeature requires={[Role.BetaAccess]}>
                <Spacer size={16} />
                <ExactMatchTogller
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                />
              </RoleFeature>
            </Row>
            <Spacer size={16} />
            <Divider />
          </>
        )}

        <Spacer size={16} />
        <Row
          className={classnames({
            [styles.settingsRow]: true,
            [styles.filtersRow]: true,
          })}
        >
          {!objectId && treeData.length > 0 && (
            <>
              <Tree
                withCheckboxTootip
                label={t("Common:select_organisation")}
                placeholder={`--${t("Common:organisation")}--`}
                hideLabel
                className={styles.treeFilter}
                disabled={!organisationFilter && !availableFilters.amount}
                items={treeData}
                selectedTreeItem={customersFilter.customers}
                onSelectItem={(ids: string) => {
                  if (!ids) {
                    resetCustomersFilter();
                  } else {
                    setCustomersFilter({ customers: ids });
                  }
                  // Do not reset page filters if value same as previous
                  if (customersFilter.customers !== ids) resetTablePage();
                }}
                selectionMode="multiple"
                showClearOption={false}
              />
              <Spacer size={8} />
            </>
          )}
          <DateFilterDropdown
            placeholder={`--${t("AlarmHistory:date_filter_placeholder")}--`}
            dateType={dateType}
            setDateType={setDateType}
            dateFilter={dateFilter}
            setDateFilter={(dateData) => {
              setDateFilter(dateData);
              // Do not reset page filters if value same as previous
              if (
                dateData.start?.getDate() !== dateFilter.start?.getDate() &&
                dateData.end?.getDate() !== dateFilter.end?.getDate()
              ) {
                resetTablePage();
              }
            }}
            searchState={searchState}
          />
          <Spacer size={8} />
          <div style={{ width: "480px", maxWidth: "480px" }}>
            <MultiStringInput
              placeholder={t("AlarmHistory:alarm_codes_input")}
              values={
                alarmCodesFilter.alarmCodes?.length
                  ? alarmCodesFilter.alarmCodes.split(",")
                  : []
              }
              setValues={(updated) => {
                setAlarmCodesFilter({ alarmCodes: updated.join(",") });
                resetTablePage();
              }}
              className="alarmHistoryCodes"
            />
          </div>
          <Spacer size={8} />
          {!sectionsToHide?.includes("customFilters") && alarmFilters && (
            <>
              <CustomFilters
                filterColumns={alarmFilters.filter(
                  (alarmFilter) => alarmFilter.id !== 2
                )}
                notAvailableFilters={availableFilters.notAvailable}
                filters={customFilters}
                maxFiltersAmount={availableFilters.amount}
                applyFilters={setCustomFilters}
                translate={(col) => t(`AlarmHistory:${snakeCase(col.keyName)}`)}
                tableState={searchState}
                setUnsavedCustomFilters={setUnsavedCustomFilters}
                autoDeactivatedFilter={autoDeactivatedFilter}
                setAutoDeactivatedFilter={setAutoDeactivatedFilter}
                position="bottom right"
                resetPageFilter={resetTablePage}
              />
              <Spacer size={8} />
            </>
          )}
          <ClearFilter
            text={t("Common:labels_clear_all_filters")}
            onClearClick={onClear}
            filtersToWatch={[
              searchFilter,
              autoDeactivatedFilter,
              dateFilter,
              alarmCodesFilter,
              customersFilter,
              !Object.values(toMoreState(customFilters || [])).find(
                (el) => !!el
              )
                ? {}
                : unsavedCustomFilters,
            ]}
            propertiesToExclude={{
              id: -1,
              exact: searchFilter.exact === "true" ? undefined : "false",
            }}
          />
          {!viserPermission &&
            !isLoading &&
            !allColumnsLoading &&
            !selectedColumnsLoading &&
            tableData && (
              <TableDataControlButton
                allTableColumns={allAlarmColumns}
                selectedTableColumns={selectedColumns}
                onVisibleColumnsChange={onSelectedColumnChange}
              />
            )}
        </Row>
        <Spacer size={16} />
        <Divider />
        <Column className={styles.tableContainer} type="top">
          {isLoading ||
          selectedColumnsLoading ||
          allColumnsLoading ||
          !tableData ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <AlarmHistoryTable
              data={tableData}
              pageSettings={pageFilter}
              isFiltersApplied={!!isFiltersApplied}
              onPageSettingsChange={setPageFilter}
              sortParams={sortParams}
              onSortChange={setSortParams}
              setOpenedAlarmId={setAlarmId}
              alarmTableAction={setAlarmDetailsId}
              onClear={onClear}
              enableColumnSelect={enableColumnSelect}
              tableName={objectId && "table-sub-alarms"}
            />
          )}
        </Column>
      </Column>
      {alarmDetailsId && (
        <PrimeModal
          withFooter
          withHeader
          isOpen={Boolean(alarmDetailsId.length)}
          onClose={() => {
            setAlarmDetailsId("");
            resetAlarmId();
          }}
          header={t("AlarmHistory:details_page_title")}
          className="alarmDetails"
          contentClassName="alarmDetailsContainer"
          submitBtn={{
            text: t("AlarmHistory:copy_alarm_url"),
            variant: "secondary",
            image: "link",
            onClick: async () => {
              const { location } = window;
              if ("clipboard" in navigator) {
                await navigator.clipboard.writeText(
                  `${location.origin}${location.pathname}?alarmId=${alarmId.id}`
                );
                notify({
                  message: t("AlarmHistory:link_copied"),
                });
              }
            },
          }}
          cancelBtn={{
            text: t("Common:close"),
            onClick: () => {
              setAlarmDetailsId("");
              resetAlarmId();
            },
          }}
        >
          <AlarmHistoryDetails id={alarmDetailsId} />
        </PrimeModal>
      )}
    </>
  );
};

export default AlarmHistory;
