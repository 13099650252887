import { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import useTranslations from "../../../core/i18n/useTranslations";
import { ObjectDetailsModel } from "../../../core/api/objects/types";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import {
  AlarmHandling,
  saveTemporaryHandling,
} from "../../../core/api/alarmhandlings";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import { Spacer, Row } from "../../../components/Layout/Layout";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import TimePicker from "../../../ui-lib/components/DateAndTime/TimePicker";
import Button from "../../../ui-lib/components/Button/Button";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import {
  breakpoints,
  useWindowDimensions,
} from "../../../core/hooks/dimensionProvider";

interface ITemporaryAlarmHandling {
  temporaryAlarmHandlingId?: number;
  temporaryAlarmHandlingEndDate?: string;
  temporaryAlarmHandlingEndTime?: string;
}

const TemporaryAlarmHandlingModal = ({
  isOpen,
  setIsOpen,
  alarmHandlings,
  objectData,
  objectId,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  alarmHandlings: AlarmHandling[];
  objectData: ObjectDetailsModel;
  objectId: string;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, dateFormat } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchObject } = useSharedObject();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpoints.desktop;

  const [alarmHandlingList, setAlarmHandlingList] = useState<IItemProp[]>([]);
  const [endAfterOptions, setEndAfterOptions] = useState([
    {
      id: 10,
      name: t("Common:x_minutes").replace("{0}", "10"),
      isSelected: false,
    },
    {
      id: 15,
      name: t("Common:x_minutes").replace("{0}", "15"),
      isSelected: false,
    },
    {
      id: 20,
      name: t("Common:x_minutes").replace("{0}", "20"),
      isSelected: false,
    },
    {
      id: 30,
      name: t("Common:x_minutes").replace("{0}", "30"),
      isSelected: false,
    },
    {
      id: 45,
      name: t("Common:x_minutes").replace("{0}", "45"),
      isSelected: false,
    },
    {
      id: 60,
      name: t("Common:x_minutes").replace("{0}", "60"),
      isSelected: false,
    },
    {
      id: -1,
      name: `${t("Common:custom")}...`,
      isSelected: true,
    },
  ]);
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<string>();

  const resetEndAfter = () => {
    setEndAfterOptions(
      endAfterOptions.map((option) => ({
        ...option,
        isSelected: option.id === -1,
      }))
    );
  };

  // Check if temporary alarm handling is not expired
  const actualTempAlarm =
    moment(objectData.temporaryAlarmHandlingEnd).unix() > moment().unix();

  useEffect(() => {
    if (alarmHandlings) {
      setAlarmHandlingList(
        alarmHandlings.map(
          (alarm) =>
            ({
              isSelected:
                objectData.temporaryAlarmHandlingId && actualTempAlarm
                  ? objectData.temporaryAlarmHandlingId === alarm.id
                  : objectData.alarmHandlingId === alarm.id,
              id: alarm.id,
              name: alarm.name,
            } as IItemProp)
        )
      );
    }
  }, [alarmHandlings, isOpen]);

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<ITemporaryAlarmHandling>({
    shouldUnregister: true,
    defaultValues: {
      temporaryAlarmHandlingId: objectData.alarmHandlingId,
    },
  });

  useEffect(() => {
    // if TAH exist and it not expired - set default values
    if (objectData.temporaryAlarmHandlingEnd && actualTempAlarm) {
      const utcDateTime = moment(objectData.temporaryAlarmHandlingEnd);
      setValue("temporaryAlarmHandlingId", objectData.temporaryAlarmHandlingId);
      setValue(
        "temporaryAlarmHandlingEndDate",
        moment(utcDateTime).format("YYYY-MM-DD")
      );
      setDate(moment(utcDateTime).toDate());
      setValue(
        "temporaryAlarmHandlingEndTime",
        moment(utcDateTime).format("HH:mm")
      );
      setTime(moment(utcDateTime).format("HH:mm"));
    } else {
      // set alarm handling id from odject data
      setValue("temporaryAlarmHandlingId", objectData.alarmHandlingId);
    }
  }, [isOpen]);

  const closeModal = () => {
    reset();
    setIsOpen(false);
    setIsLoading(false);
    setTime(undefined);
    setDate(undefined);
    resetEndAfter();
  };

  const updateTemporaryHandling = async (withData = true) => {
    await trigger();
    if (
      !errors.temporaryAlarmHandlingId &&
      !errors.temporaryAlarmHandlingEndDate &&
      !errors.temporaryAlarmHandlingEndTime
    ) {
      setIsLoading(true);
      const dataToSave = {
        id: withData ? getValues("temporaryAlarmHandlingId") : undefined,
        end: withData
          ? new Date(
              `${getValues("temporaryAlarmHandlingEndDate")}T${getValues(
                "temporaryAlarmHandlingEndTime"
              )}`
            )
          : undefined,
      };
      try {
        await saveTemporaryHandling(authenticatedRequest, objectId, dataToSave);
        notify({
          message: withData
            ? t("Objects:temporary_alarm_handling_set")
            : t("Objects:temporary_alarm_handling_removed"),
        });
        fetchObject();
      } catch (error) {
        const errorKey = getErrorKey(error);
        notify({
          message: t(`Errors:${errorKey}`)
            .replace("{FieldName}", "Alarm handling")
            .replace("'{FieldValue}'", ""),
          variant: "error",
        });
      } finally {
        closeModal();
      }
    }
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setMonth(today.getMonth() + 2);
    today.setDate(today.getDate() - 1);
    return today;
  };

  const setCustomEndAfterOption = () => {
    if (endAfterOptions[6].isSelected) return;
    const updatedEndAfterOptions = endAfterOptions.map((option) => ({
      ...option,
      isSelected: option.id === -1,
    }));
    setEndAfterOptions(updatedEndAfterOptions);
  };

  return (
    <PrimeModal
      withHeader
      onClose={closeModal}
      isOpen={isOpen}
      header={
        objectData.temporaryAlarmHandlingEnd &&
        moment.utc() < moment(objectData.temporaryAlarmHandlingEnd)
          ? t("Objects:edit_temporary_alarm_handling")
          : t("Objects:set_temporary_alarm_handling")
      }
      loading={isLoading}
      fixedWidth="848px"
      customFooter={
        <>
          <Button
            text={t("Common:save")}
            loading={isLoading && isDirty}
            disabled={isLoading || !isDirty}
            onClick={async () => {
              await updateTemporaryHandling();
            }}
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={closeModal}
          />
          <Spacer size={8} />
          {actualTempAlarm && (
            <Button
              variant="destructive"
              loading={isLoading && !isDirty}
              text={t("Objects:cancel_temporary_alarm_handling")}
              onClick={async () => {
                await updateTemporaryHandling(false);
                setDate(undefined);
                setTime(undefined);
              }}
              customStyles={{ overflow: "hidden", marginLeft: "auto" }}
            />
          )}
        </>
      }
    >
      <p>{t("Objects:temporary_alarm_handling_description")}</p>

      <Spacer size={16} />

      <form>
        <Controller
          name="temporaryAlarmHandlingId"
          control={control}
          rules={{ required: true }}
          render={() => (
            <Dropdown
              required
              onSelectItem={(item) => {
                const updatedHandlingList = alarmHandlingList.map((alarm) => ({
                  ...alarm,
                  isSelected: alarm.id === item.id,
                }));
                setAlarmHandlingList(updatedHandlingList);
                setValue("temporaryAlarmHandlingId", item.id as number, {
                  shouldDirty: true,
                });
              }}
              items={alarmHandlingList}
              title={t("AlarmHistory:alarm_query_filter_alarm_handling")}
              selectedItem={alarmHandlingList.find((alarm) => alarm.isSelected)}
              validationError={
                errors.temporaryAlarmHandlingId &&
                t("Objects:missing_temporary_handling_id")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Dropdown
          onSelectItem={(item) => {
            setEndAfterOptions(
              endAfterOptions.map((option) => ({
                ...option,
                isSelected: option.id === item.id,
              }))
            );
            if (item.id === -1) return;
            const newDate = moment().add(item.id, "minutes").toDate();
            const formattedDate = moment(newDate).format("YYYY-MM-DD");
            setValue("temporaryAlarmHandlingEndDate", formattedDate, {
              shouldDirty: true,
            });

            setDate(newDate);
            // set current time with date selection
            const currentTime = moment()
              .add(item.id, "minutes")
              .format("HH:mm");
            setValue("temporaryAlarmHandlingEndTime", currentTime);
            setTime(currentTime);
          }}
          items={endAfterOptions}
          title={t("Objects:end_after")}
          selectedItem={endAfterOptions.find((option) => option.isSelected)}
        />

        <Spacer size={16} />

        <Row responsive={isMobile}>
          <Controller
            name="temporaryAlarmHandlingEndDate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                required
                minDate={new Date()}
                maxDate={getMaxDate()}
                dateFormat={dateFormat?.split("/").join("-")}
                placeholder={dateFormat?.split("/").join("-")}
                value={date}
                onChange={(newDate) => {
                  if (newDate) {
                    const formattedDate = moment(newDate).format("YYYY-MM-DD");
                    setValue(field.name, formattedDate, { shouldDirty: true });
                    setDate(newDate);
                    // set current time with date selection
                    const currentTime = moment().format("HH:mm");
                    setValue("temporaryAlarmHandlingEndTime", currentTime);
                    setTime(currentTime);
                  } else {
                    setDate(undefined);
                    setValue(field.name, undefined, { shouldDirty: true });
                  }
                  setCustomEndAfterOption();
                  trigger("temporaryAlarmHandlingEndDate");
                }}
                label={t("Objects:end_date")}
                validationError={
                  errors.temporaryAlarmHandlingEndDate &&
                  t("Objects:specify_the_end_time")
                }
              />
            )}
          />

          <Spacer size={isMobile ? 32 : 16} />

          <Controller
            name="temporaryAlarmHandlingEndTime"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TimePicker
                required
                label={t("Contacts:input_end_time")}
                value={time}
                onChange={(newHours) => {
                  setValue(field.name, newHours, {
                    shouldDirty: true,
                  });
                  setTime(newHours);
                  setCustomEndAfterOption();
                  trigger("temporaryAlarmHandlingEndTime");
                }}
                hourFormat="24"
                validationError={
                  errors.temporaryAlarmHandlingEndTime &&
                  t("Objects:specify_the_end_time")
                }
              />
            )}
          />
        </Row>
      </form>
    </PrimeModal>
  );
};

export default TemporaryAlarmHandlingModal;
