import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { Column, Row, Spacer } from "../Layout/Layout";
import styles from "./Transmitters.module.css";
import useUser from "../../core/user/useUser";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";

import { notifyApiErrors } from "../../core/helpers/helpers";
import { ConfigurationFile } from "../../core/api/objects/types";
import {
  downloadConfiguration,
  getConfigurations,
} from "../../core/api/objects/objects";
import Icon from "../../ui-lib/components/Icon";
import useTranslations from "../../core/i18n/useTranslations";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export declare type ConfigurationFilesActions = {
  open: (objectId: string) => void;
};
export interface ConfigurationFilesProps {
  onClose?: () => void;
}
export const ConfigurationFilesModal = forwardRef<
  ConfigurationFilesActions,
  ConfigurationFilesProps
>((props: ConfigurationFilesProps, ref) => {
  const { onClose } = props;
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isConfigurationFilesOpen, setIsConfigurationFilesOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const objectId = useRef<string | undefined>(undefined);
  const [configurationFiles, setConfigurationFiles] =
    useState<ConfigurationFile[]>();

  const closeModal = () => {
    setIsConfigurationFilesOpen(false);
    setIsLoading(false);
    onClose?.();
  };
  async function loadData() {
    setIsLoading(true);
    try {
      const result = await getConfigurations(
        objectId.current!,
        authenticatedRequest
      );
      setConfigurationFiles(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsLoading(false);
  }

  useImperativeHandle<ConfigurationFilesActions, ConfigurationFilesActions>(
    ref,
    () => {
      const actions: ConfigurationFilesActions = {
        open: function open(id: string) {
          setIsConfigurationFilesOpen(true);
          objectId.current = id;
          loadData();
        },
      };
      return actions;
    }
  );
  const downloadFile = async (key: string) => {
    try {
      await downloadConfiguration(objectId.current!, key);
    } catch (error) {
      notifyApiErrors(null, 404);
    }
  };

  return (
    <>
      <PrimeModal
        isOpen={isConfigurationFilesOpen}
        onClose={closeModal}
        withHeader
        header={t("Transmitters:configuration_files_modal_title")}
        loading={isLoading}
        fixedWidth="960px"
        withFooter
        cancelBtn={{
          text: t("Common:close"),
          onClick: closeModal,
        }}
      >
        <Column type="top" align="start">
          <Table<ConfigurationFile>
            columns={[
              {
                header: t("Transmitters:configuration_files_table_filename"),
                fieldTemplate: (rowData) => (
                  <Row type="left" align="center">
                    <Icon name="download" size={24} color="Grey-300" />
                    <Spacer size={8} />
                    <button
                      className={classnames(styles.linkButton)}
                      type="button"
                      onClick={() => {
                        downloadFile(rowData.key);
                      }}
                    >
                      <span>{`${rowData.key}`}</span>
                    </button>
                  </Row>
                ),
              },
              {
                header: t(
                  "Transmitters:configuration_files_table_lastmodified"
                ),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={moment(rowData.lastModified).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  />
                ),
              },

              {
                header: t("Transmitters:configuration_files_table_size"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={rowData.size}
                    className="cellContentEllipsis alignRight"
                  />
                ),
              },
            ]}
            items={configurationFiles}
            // hideHeader
            hideEmptyMessage
            noRowsMessage={t("Transmitters:configuration_files_table_nomatch")}
            showRowHover
            isLoading={isLoading}
          />
        </Column>
      </PrimeModal>
    </>
  );
});
