import { useState } from "react";
import { getAllEnumEntries } from "enum-for";
import { Row, Spacer } from "../../components/Layout/Layout";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../ui-lib/components/Tables/TableCell";
import useUser from "../../core/user/useUser";
import { Camera } from "../../core/api/cameras/types";
import { CameraAllowedViewing } from "../../core/enumerations/enumerations";
import { updateObject } from "../../core/api/objects/objects";
import {
  addCameraToObject,
  removeCameraFromObject,
} from "../../core/api/cameras/cameras";
import { AddCameraModal } from "./AddCameraModal";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import { ObjectDetailsModel } from "../../core/api/objects/types";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import useSharedObject from "../../pages/Objects/SharedObjectContext/useSharedObject";

import styles from "./ObjectAccessories.module.css";
import { ResponsiveWrapper } from "../ResponsiveWrapper/ResponsiveWrapper";
import {
  breakpoints,
  useWindowDimensions,
} from "../../core/hooks/dimensionProvider";

const columns = (t: (key: string) => string) => [
  {
    header: t("Objects:accessories_table_name"),
    fieldTemplate: (rowData: Camera) => <TableCell value={rowData.title} />,
  },
  {
    header: t("Objects:accessories_table_model"),
    fieldTemplate: (rowData: Camera) => (
      <MissingValueTableCell value={rowData.description} missingValueText="" />
    ),
  },
  {
    header: t("Table:Customer"),
    fieldTemplate: (rowData: Camera) => (
      <TableCell
        value={rowData.organizationName}
        iconColor="Grey-300"
        leftIcon="office-building"
      />
    ),
  },
  {
    header: t("Common:type"),
    fieldTemplate: (rowData: Camera) => (
      <TableCell
        value={t(`Common:CameraType_${rowData.cameraType}`)}
        iconColor="Grey-300"
        leftIcon="video-camera"
      />
    ),
  },
];

const ObjectCameras = ({
  objectId,
  cameras,
  loadCameras,
  objectData,
}: {
  objectId: string;
  cameras: Camera[];
  loadCameras(): Promise<void>;
  objectData: ObjectDetailsModel;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const viserPermission = config?.show.includes("SensioHideFields");
  const [camerasLoading, setCamerasLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newCameraAllowance, setNewCameraAllowance] = useState(
    objectData.cameraViewingAllowance
  );
  const { fetchObject } = useSharedObject();
  const { width } = useWindowDimensions();
  const isTablet = width < breakpoints.desktop;
  const isMobile = width < breakpoints.tablet;

  const [cameraAllowedItems, setCameraAllowedItems] = useState<IDropdownItem[]>(
    getAllEnumEntries(CameraAllowedViewing).map((val) => ({
      id: val[1].toString(),
      name: t(`Objects:${val[0]}`),
      isSelected: val[1] === newCameraAllowance,
    }))
  );

  const onCameraViewChange = async (allowanceId: string | number) => {
    setIsSubmitting(true);
    try {
      await updateObject(
        objectId,
        {
          ...objectData,
          cameraViewingAllowance: +allowanceId,
        },
        authenticatedRequest
      );
      fetchObject();
      notify({ message: t("Objects:edited_success") });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadInteralCameras = () => {
    setCamerasLoading(true);
    loadCameras();
    setCamerasLoading(false);
  };

  const removeCamera = async (cameraId: number) => {
    setCamerasLoading(true);
    try {
      await removeCameraFromObject(objectId, cameraId, authenticatedRequest);
      notify({ message: t("Objects:removed_camera_from_object") });
      loadInteralCameras();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setCamerasLoading(false);
    }
  };

  const addCamera = (
    <AddCameraModal
      id={objectId}
      apiSaveMethod={addCameraToObject}
      onSubmit={() => {
        loadInteralCameras();
      }}
      alreadySelectedCameras={cameras?.map((c) => c.id) || []}
    />
  );

  return (
    <>
      <ResponsiveWrapper
        rowAlign="center"
        rowType="space"
        colAlign="start"
        className="stretch"
      >
        <Row type={isTablet ? "space" : "left"} className="stretch">
          <h2>{t("Objects:accessories_cameras_title")}</h2>
          {isTablet && addCamera}
        </Row>
        {isTablet && <Spacer size={8} />}
        {config?.show.includes("Cameras") && !viserPermission && (
          <Dropdown
            showTooltip
            innerButtonStyles={{ width: isMobile ? "150px" : "210px" }}
            className={styles.camerasDropdown}
            buttonStyle={{
              text: { overflow: "hidden", textOverflow: "ellipsis" },
            }}
            title={t("Objects:title_camera_allowance")}
            id="allowedcamera_dd"
            placeholder={t("Objects:accessories_select_camera_allowed")}
            selectedItem={cameraAllowedItems.find((item) => item.isSelected)}
            items={cameraAllowedItems}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onSelectItem={(item) => {
              setCameraAllowedItems(
                cameraAllowedItems.map((cameraItem) => ({
                  ...cameraItem,
                  isSelected: cameraItem.id === item.id,
                }))
              );
              setNewCameraAllowance(item.id as number);
              onCameraViewChange(item.id);
            }}
          />
        )}
        {!isTablet && (
          <>
            <Spacer size={16} />
            {addCamera}
          </>
        )}
      </ResponsiveWrapper>
      <Spacer size={16} />
      <Table<Camera>
        columns={columns(t)}
        items={cameras}
        rowActions={[
          {
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            text: t("Objects:accessories_table_remove"),
            onClick: (rowData) => removeCamera(rowData.id),
          },
        ]}
        rowActionsFixed
        rowActionsColWidth={45}
        showRowHover
        hideEmptyMessage
        noRowsMessage={t("Objects:accessories_no_cameras")}
        isLoading={camerasLoading}
        withShowMore
      />
    </>
  );
};
export default ObjectCameras;
