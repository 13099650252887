import { FormProvider } from "react-hook-form";
import { AdministratorDetails } from "../../../../core/api/administrators/types";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import RespondentGroupList from "../../../../components/Administrator/RespondentGroupList";

export const RespondentGroupForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues: AdministratorDetails;
  onSubmit: (values: AdministratorDetails) => Promise<void>;
}) => {
  const formRef = useObjectFormRef();
  const methods = useObjectForm<AdministratorDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const { handleSubmit, reset } = methods;

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset(values);
        })}
        className="w-100"
      >
        <RespondentGroupList
          respondentGroups={defaultValues.respondentGroups || []}
        />
      </form>
    </FormProvider>
  );
};
