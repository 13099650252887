import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";

const OrganisationFields = ({
  adminType,
  organizationId,
  organizationsById,
  sellers,
  organisations,
  alarmRes,
  sellerId,
  customerId,
  arcId,
  publicResponceCenter,
  isLoading,
  isArcLoading,
  setSellerId,
  setCustomerId,
  setArcId,
}: {
  adminType: string;
  organizationId?: number;
  organizationsById?: ITreeData | null;
  sellers?: ITreeData[];
  organisations: ITreeData[];
  alarmRes: ITreeData[];
  sellerId?: number;
  customerId?: number;
  arcId?: number;
  publicResponceCenter?: boolean;
  isLoading?: boolean;
  isArcLoading?: boolean;
  setSellerId: Dispatch<SetStateAction<number | undefined>>;
  setCustomerId: Dispatch<SetStateAction<number | undefined>>;
  setArcId: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const { t } = useTranslation();
  const {
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Row align="start" type="left">
      {!publicResponceCenter && (
        <>
          <Column style={{ maxWidth: "48%" }}>
            {adminType === "seller" && (
              <Controller
                name="sellerId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Tree
                    required
                    label={t("Common:belongs_to")}
                    placeholder={t("Administrator:seller_placeholder")}
                    items={sellers || []}
                    disabled={!sellers || sellers.length < 2}
                    selectedTreeItem={sellerId}
                    onSelectItem={(value?: ITreeData) => {
                      if (!value?.key) return;
                      field.onChange(value?.key as number);
                      setSellerId(value?.key as number);
                      trigger(field.name);
                    }}
                    showClearOption={false}
                    validationError={
                      errors?.sellerId && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            )}
            {(adminType === "admin" || adminType === "respondent") &&
              !publicResponceCenter && (
                <Controller
                  name="organizationId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    isLoading ? (
                      <LoadingSpinner size={38} />
                    ) : (
                      <Tree
                        required
                        label={
                          adminType === "admin"
                            ? t("Common:belongs_to")
                            : t("Common:organisation")
                        }
                        placeholder={t("Common:select_organisation")}
                        items={
                          organizationId
                            ? ([organizationsById] as ITreeData[])
                            : organisations
                        }
                        disabled={
                          !organisations.length ||
                          (organisations.length < 2 &&
                            !organisations[0].children)
                        }
                        selectedTreeItem={organizationId ?? customerId}
                        onSelectItem={(value?: ITreeData) => {
                          if (!value?.key) return;
                          field.onChange(value?.key as number);
                          setCustomerId(value?.key as number);
                          trigger(field.name);
                        }}
                        showClearOption={false}
                        validationError={
                          errors?.organizationId &&
                          t("Errors:input_field_required")
                        }
                      />
                    )
                  }
                />
              )}
          </Column>
          <Spacer size={32} />
        </>
      )}
      {adminType === "respondent" && (
        <Column style={{ maxWidth: "48%" }}>
          <Controller
            name="responseCenterId"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              isLoading ? (
                <LoadingSpinner size={38} />
              ) : (
                <Tree
                  required
                  label={t("Common:belongs_to")}
                  placeholder={
                    !customerId
                      ? t("Administrator:choose_organisation_first")
                      : t("Common:select_alarm_reception")
                  }
                  items={alarmRes}
                  disabled={
                    (!customerId && !publicResponceCenter) ||
                    !alarmRes.length ||
                    isArcLoading
                  }
                  selectedTreeItem={arcId}
                  onSelectItem={(item?: ITreeData) => {
                    if (item) {
                      field.onChange(item.key);
                      setArcId(item.key as number);
                    }
                  }}
                  showClearOption={false}
                  validationError={
                    errors?.responseCenterId && t("Errors:input_field_required")
                  }
                />
              )
            }
          />
        </Column>
      )}
    </Row>
  );
};

export default OrganisationFields;
