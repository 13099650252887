import useTranslations from "../../../core/i18n/useTranslations";
import { Spacer } from "../../../components/Layout/Layout";
import { ITwoFactorData } from "../../../core/api/administrators/types";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const TOTPModal = ({
  onClose,
  isOpen,
  data,
}: {
  onClose: () => void;
  isOpen: boolean;
  data?: ITwoFactorData;
}) => {
  const t = useTranslations();
  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={onClose}
      isOpen={isOpen}
      header={t(
        "Administrator:general_account_security_input_auth_type_google"
      )}
      fixedWidth="960px"
      submitBtn={{ text: t("Common:ok"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>{t("Administrator:totp_text")}</p>
      <Spacer size={16} />
      <p>{data?.preSharedSecret}</p>
    </PrimeModal>
  );
};
