import {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import useUser from "../../core/user/useUser";
import {
  TransmitterCredentials,
  TransmitterModel,
} from "../../core/api/transmitters/types";
import {
  getTransmitter,
  getTransmitterCredentials,
} from "../../core/api/transmitters/transmitters";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import {
  EditTransmitterActions,
  TransmitterEditForm,
} from "./TransmitterEditForm";
import { Spacer } from "../Layout/Layout";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import usePageState from "../../core/pagestate/usePageState";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export declare type DialogActions = {
  open: () => void;
  setTransmitter: (transmitterId: number) => Promise<boolean>;
};
export interface DialogProps {
  onSubmit?: (transmitterId?: string | number) => void;
  onClose?: () => void;
  modalTitle: string;
  saveButtonTitle: string;
  customerId?: number;
  editTransmitter?: boolean;
  toObject?: boolean;
  onStockTransmitterSave?: (createMore?: boolean, id?: number) => void;
}
export const EditTransmitter = forwardRef<DialogActions, DialogProps>(
  (props: DialogProps, ref) => {
    const {
      onSubmit,
      onClose,
      customerId,
      modalTitle,
      saveButtonTitle,
      editTransmitter,
      toObject = false,
      onStockTransmitterSave,
    } = props;
    const t = useTranslations();
    const { authenticatedRequest } = useUser();
    const pageState = usePageState();
    const [isEditTransmitterOpen, setIsEditTransmitterOpen] = useState(false);
    const editTransmitterRef = useRef<EditTransmitterActions>(null);
    const [transmitterModel, setTransmitterModel] =
      useState<TransmitterModel>();
    const [credentials, setCredentials] = useState<TransmitterCredentials[]>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const closeModal = () => {
      setCredentials(undefined);
      editTransmitterRef.current?.reset();
      setIsEditTransmitterOpen(false);
      pageState.setHideSaveButton(false);
      if (onClose !== undefined) {
        onClose();
      }
    };

    const getCreds = async () => {
      if (transmitterModel) {
        setIsSubmitting(true);
        try {
          const credentialsResult = await getTransmitterCredentials(
            transmitterModel?.id,
            authenticatedRequest
          );

          setCredentials(credentialsResult.data);
        } catch (error: any) {
          notifyApiErrors(error.response?.data?.errors);
        } finally {
          setIsSubmitting(false);
        }
      }
    };

    const submit = async () => {
      setIsSubmitting(true);
      try {
        const result = await editTransmitterRef.current?.submitForm();
        if (result?.status) {
          if (!result.createMoreTransmitter) {
            onSubmit?.(result?.transmitterId);
            closeModal();
          } else {
            editTransmitterRef.current?.reset();
          }
          notify({
            message: editTransmitter
              ? t("Transmitters:transmitter_edit_success")
              : t("Transmitters:transmitter_create_success"),
          });
        }
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      } finally {
        setIsSubmitting(false);
      }
    };

    useImperativeHandle<DialogActions, DialogActions>(ref, () => {
      const actions: DialogActions = {
        open: function open() {
          pageState.setHideSaveButton(true);
          setIsEditTransmitterOpen(true);
        },
        setTransmitter: async function setTransmitter(
          transmitterId: number
        ): Promise<boolean> {
          setIsSubmitting(true);
          try {
            const transmitterResult = await getTransmitter(
              transmitterId,
              authenticatedRequest
            );
            setTransmitterModel(transmitterResult.data);
            if (!transmitterResult.data) {
              notify({
                message: t("Transmitters:error_transmitter_not_found"),
                variant: "error",
              });
            }
            return true;
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          } finally {
            setIsSubmitting(false);
          }

          return false;
        },
      };
      return actions;
    });

    useEffect(() => {
      if (isEditTransmitterOpen && !!transmitterModel?.sipCredentialType) {
        getCreds();
      }
    }, [isEditTransmitterOpen]);

    return (
      <PrimeModal
        withHeader
        withFooter
        fixedWidth="848px"
        header={modalTitle}
        isOpen={isEditTransmitterOpen}
        onClose={closeModal}
        loading={isSubmitting}
        submitBtn={{
          text: saveButtonTitle,
          variant: "primary",
          onClick: submit,
        }}
        cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
      >
        <p>{t("Transmitters:create_new_description")}</p>
        <Spacer size={16} />
        <TransmitterEditForm
          ref={editTransmitterRef}
          customerId={customerId}
          transmitter={transmitterModel}
          toObject={toObject}
          onClose={closeModal}
          closeStockModal={onStockTransmitterSave}
          credentials={credentials}
          dataLoading={isSubmitting}
          uploadTransmitterData={getCreds}
        />
      </PrimeModal>
    );
  }
);
