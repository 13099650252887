import useTranslations from "../../../core/i18n/useTranslations";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { useState } from "react";
import { deleteActivity } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const DeleteActivityModal = ({
  activityId,
  userId,
  onClose,
}: {
  activityId?: string;
  userId: number;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isLoading, setIsLoading] = useState(false);

  const onRemove = async () => {
    try {
      setIsLoading(true);
      await deleteActivity(userId, activityId!, authenticatedRequest);
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      isOpen={!!activityId}
      onClose={onClose}
      fixedWidth="576px"
      loading={isLoading}
      header={t("Objects:delete_activity")}
      submitBtn={{
        text: t("Objects:delete_activity"),
        variant: "primary",
        onClick: onRemove,
        disabled: isLoading,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:delete_activity_description")}
      </p>
    </PrimeModal>
  );
};
