import User from "../../core/user/User";

interface HomeData {
  username: string;
  searchBar?: number;
  alarmActivity?: number;
}

export const homeDataRetriever = (user: User): HomeData => {
  const homeData = JSON.parse(
    localStorage.getItem("homeData") ?? "[]"
  ) as HomeData[];
  const curUserData = homeData.find(
    (data) => data.username === user.data?.username
  );
  return curUserData || ({} as HomeData);
};

export const homeDataSaver = (
  user: User,
  type: "searchBar" | "alarmActivity",
  value: number
) => {
  const homeData = JSON.parse(
    localStorage.getItem("homeData") ?? "[]"
  ) as HomeData[];
  const curUserIndex = homeData.findIndex(
    (data) => data.username === user.data?.username
  );
  if (curUserIndex !== -1) {
    homeData[curUserIndex][type] = value;
  } else {
    homeData.push({
      username: user.data?.username!,
      [type]: value,
    });
  }
  localStorage.setItem("homeData", JSON.stringify(homeData));
};
