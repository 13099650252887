import React, { useState } from "react";
import classnames from "classnames";
import Icon from "../Icon";
import { AlertItem, AlertItemProps } from "./AlertItem";
import styles from "./Alert.module.css";

export interface AlertProps {
  variant?:
    | "secondary"
    | "info"
    | "info-outline"
    | "warning"
    | "warning-outline"
    | "error"
    | "error-outline";
  title: any;
  icon?: string;
  text?: string;
  /** Shows default icon according to variant passed */
  withDefaultIcon?: boolean;
  canBeClosed?: boolean;
  onClosed?: () => void;
  items?: AlertItemProps[];
  style?: React.CSSProperties;
  dataCy?: string;
}

const defaultIcons = {
  secondary: "information-circle",
  info: "information-circle",
  "info-outline": "information-circle",
  warning: "exclamation-circle",
  "warning-outline": "exclamation-circle",
  error: "exclamation-circle",
  "error-outline": "exclamation-circle",
};

export const Alert = ({
  variant = "secondary",
  title,
  icon = undefined,
  withDefaultIcon = false,
  canBeClosed = false,
  onClosed = undefined,
  items = undefined,
  text = undefined,
  style,
  dataCy,
}: AlertProps) => {
  const [isVisible, setIsVisible] = useState(true);

  let iconName = icon;
  if (withDefaultIcon) {
    iconName = defaultIcons[variant];
  }

  return isVisible ? (
    <div
      className={classnames({
        [styles.alert]: true,
        [styles.alertInfo]: variant === "info",
        [styles.alertInfoOutline]: variant === "info-outline",
        [styles.alertWarning]: variant === "warning",
        [styles.alertWarningOutline]: variant === "warning-outline",
        [styles.alertError]: variant === "error",
        [styles.alertErrorOutline]: variant === "error-outline",
      })}
      data-cy={dataCy}
    >
      <div className={styles.alertTitle}>
        {iconName ? (
          <Icon
            name={iconName}
            size={24}
            className={`mr-12 ${styles.alertIcon}`}
          />
        ) : null}
        <span style={{ ...style }}>{title}</span>
        {canBeClosed ? (
          <span className="ml-auto">
            <Icon
              name="x"
              size={24}
              className={`${styles.alertIcon} ${styles.closeIcon}`}
              onClick={() => {
                setIsVisible(false);
                if (onClosed) {
                  onClosed();
                }
              }}
            />
          </span>
        ) : null}
      </div>
      {text && (
        <div
          className={classnames(styles.alertText, { [styles.icon]: iconName })}
        >
          {text}
        </div>
      )}
      {/* items */}
      {items
        ? items.map((item) => (
            <AlertItem
              key={window.crypto.randomUUID()}
              title={item.title}
              note={item.note}
              canBeClosed={item.canBeClosed}
              onClosed={item.onClosed}
            />
          ))
        : null}
    </div>
  ) : null;
};
export default Alert;
