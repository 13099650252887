import { AxiosInstance } from "axios";
import { IResellerDetails, IResellerType } from "./types";
import {
  IPFilter,
  IpWhiteList,
  IRetentionDetails,
} from "../organizations/types";

const getSellers = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<IResellerType[]>(`/api/v2/sellers`);

const getSingleSeller = (authenticatedRequest: AxiosInstance, id: number) =>
  authenticatedRequest.get<IResellerDetails>(`/api/v2/sellers/${id}`);

const createReseller = (
  authenticatedRequest: AxiosInstance,
  data: { name: string }
) =>
  authenticatedRequest.post<number>(`/api/v2/sellers`, JSON.stringify(data), {
    headers: { "Content-Type": "application/json" },
  });

const updateReseller = (
  authenticatedRequest: AxiosInstance,
  id: number,
  data: IResellerDetails
) =>
  authenticatedRequest.put<IResellerDetails>(
    `/api/v2/sellers/${id}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getResellerIPWhitList = (
  id: number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.get<IpWhiteList>(`/api/v2/sellers/${id}/ipfilter`);

const putResellerIP = (
  id: number,
  authenticatedRequest: AxiosInstance,
  data: { filters: IPFilter[] }
) =>
  authenticatedRequest.put<any>(
    `/api/v2/sellers/${id}/ipfilter`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getRetentionOptions = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get("/api/v2/sellers/retentions");

const getRetentionDetails = (id: number, authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get(`/api/v2/sellers/${id}/retentions`);

const updateRetentionDetails = (
  id: number,
  data: IRetentionDetails,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<IRetentionDetails>(
    `/api/v2/sellers/${id}/retentions`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export {
  getSellers,
  createReseller,
  getSingleSeller,
  updateReseller,
  getResellerIPWhitList,
  putResellerIP,
  getRetentionOptions,
  getRetentionDetails,
  updateRetentionDetails,
};
