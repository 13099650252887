import {
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { IResellerDetails } from "../../../core/api/sellers/types";
import {
  getRetentionDetails,
  getRetentionOptions,
  getSingleSeller,
  updateReseller,
  updateRetentionDetails,
} from "../../../core/api/sellers/sellers";
import useUser from "../../../core/user/useUser";
import { IRetentionDetails } from "../../../core/api/organizations/types";

export const SharedResellerContext = createContext<{
  data?: IResellerDetails;
  isLoading: boolean;
  isError: boolean;
  fetchReseller: () => Promise<void>;
  update: (data: IResellerDetails) => Promise<void>;
  fetchRetentionOptions: () => Promise<void>;
  retentionOptions?: {
    [key: string]: string;
  };
  fetchRetentionDetails: () => Promise<void>;
  retentionDetails?: IRetentionDetails;
  updateRetention: (data: any) => Promise<void>;
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  fetchReseller: async () => {},
  update: async () => {},
  fetchRetentionOptions: async () => {},
  retentionOptions: undefined,
  fetchRetentionDetails: async () => {},
  retentionDetails: undefined,
  updateRetention: async () => {},
});

export const SharedResellerProvider = ({
  sellerId,
  children,
}: {
  sellerId: string;
  children: ReactNode;
}) => {
  const { authenticatedRequest } = useUser();
  const isAlive = useRef(false);

  const [data, setData] = useState<IResellerDetails>();
  const [retentionOptions, setRetentionOptions] = useState<{
    [key: string]: string;
  }>();
  const [retentionDetails, setRetentionDetails] = useState<IRetentionDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchReseller = async () => {
    setData(undefined);
    setIsLoading(true);

    try {
      const result = await getSingleSeller(authenticatedRequest, +sellerId);
      if (isAlive.current) {
        setData(result.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const update = async (sellerData: IResellerDetails) => {
    setIsLoading(true);

    try {
      const response = await updateReseller(
        authenticatedRequest,
        +sellerId,
        sellerData
      );
      setData(response.data);
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  };

  const fetchRetentionOptions = async () => {
    setRetentionOptions(undefined);
    setIsLoading(true);

    try {
      const result = await getRetentionOptions(authenticatedRequest);
      if (isAlive.current) {
        setRetentionOptions(result.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const fetchRetentionDetails = async () => {
    setRetentionDetails(undefined);
    setIsLoading(true);

    try {
      const result = await getRetentionDetails(+sellerId, authenticatedRequest);
      if (isAlive.current) {
        setRetentionDetails(result.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const updateRetention = async (valuesToUpdate: IRetentionDetails) => {
    setIsLoading(true);

    try {
      await updateRetentionDetails(
        +sellerId,
        valuesToUpdate,
        authenticatedRequest
      );
      setRetentionDetails(valuesToUpdate);
    } catch (error) {
      setIsError(true);
      throw error;
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  };

  const contextValues = useMemo(
    () => ({
      data,
      isLoading,
      isError,
      fetchReseller,
      update,
      fetchRetentionOptions,
      retentionOptions,
      fetchRetentionDetails,
      retentionDetails,
      updateRetention,
    }),
    [data, isLoading, isError, retentionOptions, retentionDetails]
  );

  useEffect(() => {
    fetchReseller();
  }, [sellerId]);

  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  return (
    <SharedResellerContext.Provider value={contextValues}>
      {children}
    </SharedResellerContext.Provider>
  );
};
