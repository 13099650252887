import { Dispatch, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Column, Spacer } from "../../../../components/Layout/Layout";
import Button from "../../../../ui-lib/components/Button/Button";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import useTranslations from "../../../../core/i18n/useTranslations";
import { createContactCredentails } from "../../../../core/api/contacts/contacts";
import useUser from "../../../../core/user/useUser";
import { ContactCredentails } from "../../../../core/api/contacts/types";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import Information from "../../../../ui-lib/components/Information/Information";
import Icon from "../../../../ui-lib/components/Icon";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

export const LoginModal = ({
  contactId,
  showModal,
  setShowModal,
  onClose,
  userId,
  isPrivate,
}: {
  contactId?: number;
  showModal: boolean;
  setShowModal: Dispatch<boolean>;
  onClose: () => void;
  userId?: string;
  isPrivate?: boolean;
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);
  const { authenticatedRequest } = useUser();
  const history = useHistory();
  const [credData, setCredData] = useState<ContactCredentails>();

  const fetchCredentails = async () => {
    if (contactId) {
      try {
        setIsLoading(true);
        const result = await createContactCredentails(
          contactId,
          authenticatedRequest,
          isPrivate,
          userId
        );
        setCredData(result.data);
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors, error.response?.status);
        setShowModal(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      fetchCredentails();
    }
  }, [showModal, contactId]);

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={() => {
        setShowModal(false);
        onClose?.();
      }}
      isOpen={showModal}
      header={t("Contacts:login_title")}
      fixedWidth="960px"
      loading={isLoading}
      submitBtn={{
        text: t("Common:ok"),
        variant: "secondary",
        onClick: onClose,
      }}
    >
      {!isLoading ? (
        credData && (
          <>
            <p style={{ color: "var(--Grey-600)" }}>
              {t("Contacts:login_modal_desc")}
            </p>

            <Spacer size={16} />

            <Row align="start">
              <Column>
                <TextInput
                  label={t("Contact:Login")}
                  disabled
                  value={`${credData.email || credData.telephone}`}
                />
              </Column>

              <Spacer size={32} />

              <Column>
                <TextInput
                  label={t("Contact:Password")}
                  disabled
                  value={credData.password}
                />
              </Column>
            </Row>

            <Spacer size={32} />

            <Information
              fullWidth
              title={t("Objects:labels_important_information")}
              description={t("Contacts:change_password_title").replace(
                "{}",
                credData.email || credData.telephone
              )}
            />
            <Spacer size={16} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="link"
                text={t("Contacts:change_password_link")}
                onClick={() =>
                  history.push(
                    `/adminportal/administrators/${credData.adminId}`
                  )
                }
              />
              <Icon
                name="chevron-right"
                size={14}
                color="Primary-700"
                margin="2px 0 0"
              />
            </div>
          </>
        )
      ) : (
        <LoadingSpinner theme="primary" />
      )}
    </PrimeModal>
  );
};
