import { useState } from "react";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { contactsBulkDelete } from "../../core/api/contacts/contacts";
import useTranslations from "../../core/i18n/useTranslations";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import useUser from "../../core/user/useUser";

interface IBulkDeleteContacts {
  isOpen: boolean;
  contactIds: string[];
  onClose: () => void;
  closeModal: () => void;
  fetchContacts: () => Promise<void>;
}

export const BulkDeleteContacts = ({
  isOpen,
  contactIds,
  onClose,
  closeModal,
  fetchContacts,
}: IBulkDeleteContacts) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [loading, setLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const closeInfoModal = () => {
    setInfoModalOpen(false);
    closeModal();
    fetchContacts();
  };

  const deleteContacts = async () => {
    setLoading(true);
    setInfoModalOpen(true);
    try {
      await contactsBulkDelete(
        { limit: contactIds.length, contactIds },
        authenticatedRequest
      );
      onClose();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PrimeModal
        withFooter
        withHeader
        header={t("Contacts:delete_contacts")}
        isOpen={isOpen}
        onClose={closeModal}
        loading={loading}
        submitBtn={{
          text: t("Common:delete"),
          variant: "destructive",
          onClick: deleteContacts,
        }}
        cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
        fixedWidth="576px"
      >
        <p>
          {t("Common:delete_modal_confirm_batch")
            .replace("{count}", contactIds.length.toString())
            .replace(
              "{type}",
              t("AlarmReceptions:contacts_type_multi").toLocaleLowerCase()
            )}{" "}
          <span className="fw-600">
            {t("Contacts:contacts_batch_delete_subtext")}
          </span>
        </p>
      </PrimeModal>
      <PrimeModal
        withFooter
        withHeader
        fixedWidth="576px"
        header={t("Contacts:bulk_delete_info_header")}
        submitBtn={{
          text: t("Common:ok"),
          variant: "secondary",
          onClick: closeInfoModal,
        }}
        isOpen={infoModalOpen}
        onClose={closeInfoModal}
      >
        <p>{t("Contacts:bulk_delete_info")}</p>
      </PrimeModal>
    </>
  );
};
