import { Fragment, useEffect, useState } from "react";
import { RouteProps } from "react-router-dom";
import classnames from "classnames";
import he from "he";
import { SideBarLink } from "./SideBarLink";
import { SideBarLinkButton } from "./SideBarLinkButton";
import { SideBarHorizontalDivider } from "./SideBarHorizontalDivider";
import {
  PopupContextMenu,
  PopupContextPosition,
} from "../PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLink } from "../PopupContextMenu/PopupContextMenuLink";
import { PopupContextMenuLinkButton } from "../PopupContextMenu/PopupContextMenuLinkButton";
import styles from "../Menu.module.css";
import ResponsiveLayout from "../../../../components/ResponsiveLayout";
import { breakpoints } from "../../../../core/hooks/dimensionProvider";
import useBranding from "../../../../core/branding/useBranding";
import { SubNavLink } from "../../../../core/interfaces/SubNav";
import PrimeModal from "../../PrimeModal/PrimeModal";

export type NavigationItemType =
  | "link"
  | "action"
  | "divider"
  | "context"
  | "context_link"
  | "context_action"
  | "context_popup_action"
  | "context_modal_action";

export type menuPosition = "main" | "footer";
export interface NavigationItem {
  id: number;
  type: NavigationItemType;
  dropdown?: boolean;
  showTooltip?: boolean;
  to?: string;
  matchProps?: RouteProps;
  action?: Function;
  text?: string;
  subtitle?: string;
  minimizedText?: string;
  minimizedIcon?: string;
  icon?: string;
  flag?: string;
  position?: PopupContextPosition;
  fillColor?: string;
  content?: () => JSX.Element;
  submenuItems?: Array<NavigationItem>;
  menu?: menuPosition;
  className?: string;
  modal?: boolean;
  truncateText?: boolean;
  subItems?: SubNavLink[];
  title?: string;
  cancelButtonText?: string;
}
export interface SideBarProps {
  minimized?: boolean;
  expandText?: string;
  minimizeText?: string;
  mainNavigation: Array<NavigationItem>;
  footerNavigation: Array<NavigationItem>;
  closeNavigation?(): void;
}

interface ModalState {
  [id: string]: boolean;
}

/** SideBar - main sidebar with predefined links */
export const SideBar = ({
  minimized = false,
  closeNavigation,
  expandText = "Expand sidebar",
  minimizeText = "Minimize sidebar",
  mainNavigation = [],
  footerNavigation = [],
}: SideBarProps) => {
  const [isMinimized, setIsMinimized] = useState(minimized);
  const [modalsState, setModalsState] = useState<ModalState>({});

  useEffect(() => {
    setIsMinimized(minimized);
  }, [minimized]);

  const renderMenu = (items: Array<NavigationItem>) =>
    items.map((navigationItem) => {
      switch (navigationItem.type) {
        case "action":
          return (
            <SideBarLinkButton
              key={`nav_item_${navigationItem.id}`}
              text={
                isMinimized && navigationItem.minimizedText
                  ? he.decode(navigationItem.minimizedText || "")
                  : he.decode(navigationItem.text ?? "")
              }
              icon={
                isMinimized && navigationItem.minimizedIcon
                  ? navigationItem.minimizedIcon
                  : navigationItem.icon ?? ""
              }
              onClick={() => {
                if (closeNavigation !== undefined) {
                  closeNavigation();
                }

                if (navigationItem.action) {
                  navigationItem.action();
                }
              }}
              hideText={isMinimized}
              showTooltip={navigationItem.showTooltip && isMinimized}
            />
          );
        case "context":
          return (
            <PopupContextMenu
              key={`nav_item_${navigationItem.id}`}
              position={navigationItem.position}
              nested
              trigger={() => (
                <div>
                  <SideBarLinkButton
                    key={`nav_link_${navigationItem.id}`}
                    text={he.decode(navigationItem.text ?? "")}
                    hideText={isMinimized}
                    icon={navigationItem.icon ?? ""}
                    fillColor={navigationItem.fillColor}
                    showTooltip={navigationItem.showTooltip && isMinimized}
                    truncateText={navigationItem.truncateText}
                  />
                </div>
              )}
              className={navigationItem.className}
            >
              {renderMenu(navigationItem.submenuItems ?? [])}
            </PopupContextMenu>
          );
        case "context_link":
          return (
            <PopupContextMenuLink
              key={`nav_item_${navigationItem.id}`}
              text={he.decode(navigationItem.text ?? "")}
              to={navigationItem.to ?? ""}
              icon={navigationItem.icon}
            />
          );
        case "context_action":
          return (
            <PopupContextMenuLinkButton
              key={`nav_item_${navigationItem.id}`}
              text={he.decode(navigationItem.text ?? "")}
              icon={navigationItem.icon}
              flag={navigationItem.flag}
              onClick={() => {
                if (closeNavigation !== undefined) {
                  closeNavigation();
                }

                if (navigationItem.action) {
                  navigationItem.action();
                }
              }}
            />
          );
        case "context_popup_action": {
          const modalClose = () => {
            setModalsState((prev) => {
              const newState = { ...prev };
              delete newState[navigationItem.id];
              return newState;
            });
          };
          return (
            <Fragment key={`fragment_${navigationItem.id}`}>
              {navigationItem.modal ? (
                <SideBarLinkButton
                  key={`nav_button_item_${navigationItem.id}`}
                  text={he.decode(navigationItem.text ?? "")}
                  icon={navigationItem.icon ?? ""}
                  hideText={isMinimized}
                  showTooltip={navigationItem.showTooltip && isMinimized}
                  onClick={() => {
                    closeNavigation?.();
                    navigationItem.action?.();
                    setModalsState((prev) => ({
                      ...prev,
                      [navigationItem.id]: true,
                    }));
                  }}
                />
              ) : (
                <PopupContextMenuLinkButton
                  key={`nav_link_item_${navigationItem.id}`}
                  text={he.decode(navigationItem.text ?? "")}
                  icon={navigationItem.icon}
                  onClick={() => {
                    if (closeNavigation !== undefined) {
                      closeNavigation();
                    }
                    if (navigationItem.action) {
                      navigationItem.action();
                    }
                  }}
                />
              )}
              <PrimeModal
                key={`nav_modal_${navigationItem.id}`}
                fixedWidth="576px"
                isOpen={modalsState[navigationItem.id]}
                onClose={modalClose}
                withHeader={!!navigationItem.title}
                header={navigationItem.title}
                withFooter={!!navigationItem.cancelButtonText}
                cancelBtn={{
                  text: navigationItem.cancelButtonText!,
                  variant: "secondary",
                  onClick: modalClose,
                }}
              >
                {navigationItem.content ? <navigationItem.content /> : null}
              </PrimeModal>
            </Fragment>
          );
        }
        case "divider":
          return (
            <SideBarHorizontalDivider
              key={`nav_divider_${navigationItem.id}`}
            />
          );
        default:
          return (
            <SideBarLink
              key={`nav_item_${navigationItem.id}`}
              text={he.decode(navigationItem.text ?? "")}
              to={navigationItem.to ?? ""}
              matchProps={navigationItem.matchProps}
              icon={
                isMinimized && navigationItem.minimizedIcon
                  ? navigationItem.minimizedIcon
                  : navigationItem.icon
              }
              hideText={isMinimized}
              showTooltip={navigationItem.showTooltip && isMinimized}
              onClick={closeNavigation}
            />
          );
      }
    });

  const brandingStore = useBranding();

  return (
    <nav
      className={classnames({
        [styles.sideBar]: true,
        [styles.sideBarMinimized]: isMinimized,
      })}
    >
      {/* Profile top */}
      <div className={styles.sideBarProfileContainerTop}>
        <img
          src={brandingStore.currentFavicon}
          alt="Icon"
          className={styles.sideBarProfileImageSecondary}
        />

        {!isMinimized ? (
          <div className={styles.sideBarProfileName}>
            {brandingStore.currentTitle}
          </div>
        ) : null}
      </div>

      <div key="header_menu" className={styles.sideBarMainContainer}>
        {renderMenu(mainNavigation)}
      </div>

      <div className={styles.sideBarFooterContainer}>
        <ResponsiveLayout
          breakPoint={breakpoints.desktop}
          renderMobile={() => null}
          renderDesktop={() => (
            <SideBarLinkButton
              key="nav_item_toggle"
              text={he.decode(isMinimized ? expandText : minimizeText)}
              icon={isMinimized ? "expand_menu" : "collapse_menu"}
              onClick={() => {
                setIsMinimized(!isMinimized);
              }}
              hideText={isMinimized}
              showTooltip={isMinimized}
            />
          )}
        />
        {renderMenu(footerNavigation)}
      </div>
    </nav>
  );
};
export default SideBar;
