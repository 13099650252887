import { ReactNode, useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useSaveObject } from "../../../core/SaveObjectContext/hooks";
import { Column } from "../../../components/Layout/Layout";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import { SharedResellerProvider } from "../SharedResellerContext/SharedResellerContext";
import { SaveObjectProvider } from "../../../core/SaveObjectContext/SaveObjectContext";
import { IResellerDetails } from "../../../core/api/sellers/types";

import Button from "../../../ui-lib/components/Button/Button";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import useSharedReseller from "../SharedResellerContext/useSharedReseller";
import useTranslations from "../../../core/i18n/useTranslations";
import ResellerMenuAlternatives from "../ResellerMenuAlternatives/ResellerMenuAlternatives";
import ResellerGeneral from "../ResellerGeneral/ResellerGeneral";
import ResellerIPWhiteList from "../ResellerIPWhiteList/ResellerIPWhiteList";

import styles from "../../PagesLayout.module.css";
import { ResellerRetention } from "../ResellerRetention/ResellerRetention";

const parentPath = "/adminportal/resellers";

interface ISubNav {
  key: string;
  to: string;
  icon?: string;
}

export const subMenuItems = (url: string, baseUrl?: string) => {
  const sub: ISubNav[] = [
    { key: "Menu:general", to: baseUrl ?? url },
    {
      key: "Menu:Menu_Alternatives",
      to: `${baseUrl ?? url}/menu-alternatives`,
    },
    {
      key: "Organizations:settings_ip_white_list",
      to: `${baseUrl ?? url}/ip-white-list`,
    },
    {
      key: "Menu:Organization_RetentionDetails",
      to: `${baseUrl ?? url}/retention-details`,
    },
  ];

  return { sub };
};

const ResellerDetailsWrapper = ({
  data,
  children,
}: {
  data?: IResellerDetails;
  children: ReactNode;
}) => {
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const { t } = useTranslation();

  return (
    <Column className="flex0 w-100">
      <PageHeader title={data?.name} icon="star">
        {canSave && (
          <Button
            text={t("Common:save")}
            disabled={!isDirty}
            loading={isSaving}
            onClick={onSave}
          />
        )}
      </PageHeader>
      <Column className={styles.content} type="top" align="start">
        {children}
      </Column>
    </Column>
  );
};

const ResellerDetails = () => {
  const { path } = useRouteMatch();
  const { data } = useSharedReseller();

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Switch>
      <Route path={`${path}/ip-white-list`}>
        <ResellerDetailsWrapper data={data}>
          <ResellerIPWhiteList id={data.id} />
        </ResellerDetailsWrapper>
      </Route>
      <Route path={`${path}/menu-alternatives`}>
        <ResellerDetailsWrapper data={data}>
          <ResellerMenuAlternatives id={data?.id} />
        </ResellerDetailsWrapper>
      </Route>
      <Route path={`${path}/retention-details`}>
        <ResellerDetailsWrapper data={data}>
          <ResellerRetention />
        </ResellerDetailsWrapper>
      </Route>
      <Route path={path}>
        <ResellerDetailsWrapper data={data}>
          <ResellerGeneral />
        </ResellerDetailsWrapper>
      </Route>
    </Switch>
  );
};

const ResellerDetailsLoader = () => {
  const { data } = useSharedReseller();

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return <ResellerDetails />;
};

const ResellerDetailsContainer = () => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { sub } = subMenuItems(url);
  const { setSubNav } = useSubNav();

  useEffect(() => {
    const nav = {
      menu: sub,
      backUrl: parentPath,
      backText: t("Common:sellers"),
    };
    setSubNav(nav);
    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <SharedResellerProvider sellerId={id}>
      <SaveObjectProvider>
        <ResellerDetailsLoader />
      </SaveObjectProvider>
    </SharedResellerProvider>
  );
};

export default observer(ResellerDetailsContainer);
