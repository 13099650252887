import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { Column, Row, Spacer } from "../Layout/Layout";
import { getTransmitter } from "../../core/api/transmitters/transmitters";
import useUser from "../../core/user/useUser";
import Table from "../../ui-lib/components/Tables/Table";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import {
  TransmitterLog,
  TransmitterModel,
} from "../../core/api/transmitters/types";

const columns = (
  t: (key: string) => string,
  getLogText: (log: TransmitterLog) => string
) => [
  {
    header: t("Transmitters:eventlog_table_time"),
    fieldTemplate: (rowData: TransmitterLog) => (
      <TableCell value={moment(rowData.created).format("HH:mm:ss")} />
    ),
  },
  {
    header: t("Transmitters:eventlog_table_text"),
    fieldTemplate: (rowData: TransmitterLog) => (
      <TableCell
        value={`${rowData.name} ${getLogText(rowData)}`}
        iconColor="Grey-300"
        className="cellContentEllipsis"
      />
    ),
  },
  {
    header: t("Transmitters:eventlog_table_day"),
    fieldTemplate: (rowData: TransmitterLog) => (
      <TableCell
        value={DateTimeHelper.prettyDateFromNow(
          rowData.created,
          t("Common:last"),
          t("Common:yesterday"),
          t("Common:today"),
          t("Common:tomorrow")
        )}
        className="cellContentEllipsis alignRight"
      />
    ),
  },
];

import { notifyApiErrors } from "../../core/helpers/helpers";
import { CreateTransmitterEventModal } from "./CreateTransmitterEventModal";
import useTranslations from "../../core/i18n/useTranslations";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export declare type TransmitterEventsActions = {
  open: (transmitterId: number) => void;
};
export interface TransmitterEventsProps {
  onClose?: () => void;
}
export const TransmitterEventsModal = forwardRef<
  TransmitterEventsActions,
  TransmitterEventsProps
>((props: TransmitterEventsProps, ref) => {
  const { onClose } = props;
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isTransmitterEventsOpen, setIsTransmitterEventsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const active = useRef(false);
  const transmitterId = useRef<number | undefined>(undefined);
  const [transmitter, setTransmitter] = useState<TransmitterModel>();

  const closeModal = () => {
    setIsTransmitterEventsOpen(false);
    setIsLoading(false);
    onClose?.();
  };
  const getLogText = (log: TransmitterLog): string =>
    log.logType === 0
      ? log.logText
      : t(`Transmitters:TransmitterLogType_${log.logType}`);

  async function loadData() {
    setIsLoading(true);
    try {
      if (transmitterId.current) {
        const result = await getTransmitter(
          transmitterId.current,
          authenticatedRequest
        );
        if (active.current) {
          setTransmitter(result.data);
        }
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsLoading(false);
  }

  useImperativeHandle<TransmitterEventsActions, TransmitterEventsActions>(
    ref,
    () => {
      const actions: TransmitterEventsActions = {
        open: function open(id?: number) {
          setIsTransmitterEventsOpen(true);
          transmitterId.current = id;
          loadData();
        },
      };
      return actions;
    }
  );
  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (isTransmitterEventsOpen) loadData();
  }, [isTransmitterEventsOpen]);

  return (
    <PrimeModal
      isOpen={isTransmitterEventsOpen}
      onClose={closeModal}
      withHeader
      header={t("Transmitters:eventlog_modal_title")}
      loading={isLoading}
      withFooter
      cancelBtn={{
        text: t("Common:close"),
        onClick: closeModal,
      }}
      fixedWidth="960px"
    >
      <Column type="top" align="start">
        <p>{t("Transmitters:eventlog_table_description")}</p>
        <Spacer size={16} />
        <Row type="space" style={{ alignSelf: "stretch" }}>
          <h2>{t("Transmitters:eventlog_table_title")}</h2>
          {transmitter && (
            <CreateTransmitterEventModal
              transmitter={transmitter}
              onSubmit={loadData}
            />
          )}
        </Row>
        <Spacer size={16} />
        <Table<TransmitterLog>
          columns={columns(t, getLogText)}
          items={transmitter?.transmitterLogs}
          hideHeader
          hideEmptyMessage
          noRowsMessage={t("Transmitters:eventlog_table_nomatch")}
          showRowHover
          isLoading={isLoading}
        />
      </Column>
    </PrimeModal>
  );
});
