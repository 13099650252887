import { useEffect, useState } from "react";
import { Spacer } from "../../../components/Layout/Layout";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import useTranslations from "../../../core/i18n/useTranslations";
import useSharedOrganization from "../SharedOrganizationContext/useSharedOrganization";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { IRetentionDetails } from "../../../core/api/organizations/types";
import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";
import { RetentionForm } from "../../../components/RetentionForm/RetentionForm";

import useUser from "../../../core/user/useUser";
import styles from "./RetentionDetails.module.css";

export const RetentionDetails = () => {
  const t = useTranslations();
  const { config } = useUser();
  const { retentionOptions, retentionData, updateRetention } =
    useSharedOrganization();
  const [options, setOptions] = useState<IDropdownItem[]>([]);

  useEffect(() => {
    if (retentionOptions && Object.entries(retentionOptions).length) {
      const dropdownOptions: IDropdownItem[] = Object.entries(
        retentionOptions
      ).flatMap(([key, value]) => {
        if (key === "0" && !config?.show.includes("NewAdminBeta")) {
          return [];
        }
        return {
          id: key,
          name: t(`Organizations:${value}`),
          isSelected: false,
        };
      });
      setOptions(dropdownOptions);
    }
  }, [retentionOptions]);

  const onSubmit = async (values: IRetentionDetails) => {
    try {
      await updateRetention(values);
      notify({
        message: t("Organizations:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      throw error;
    }
  };

  return (
    <div className={styles.retentionWrapper}>
      <h1>{t("Menu:Organization_RetentionDetails")}</h1>
      <Spacer size={8} />
      <p>{t("Organizations:retention_description")}</p>
      <Spacer size={32} />
      {retentionData && !!options.length ? (
        <RetentionForm
          defaultValues={retentionData}
          retentionOptions={options}
          onSubmit={onSubmit}
        />
      ) : (
        <LoadingSpinner theme="primary" />
      )}
    </div>
  );
};
