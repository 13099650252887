import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import classNames from "classnames";
import Icon from "../Icon";
import useTranslations from "../../../core/i18n/useTranslations";
import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";
import { SingleChoice } from "./SingleChoice";
import { MultiChoice } from "./MultiChoice";
import {
  IAutocompleteField,
  IValidatioAutocomplite,
} from "../../../core/api/autocomplite/types";

import styles from "./Autocomplete.module.css";
import inputStyles from "../Inputs/Input.module.css";

interface IAutocompleteProps {
  name: string;
  defaultLabel: string;
  defaultPlaceholder?: string;
  fieldData?: IAutocompleteField;
}

const Autocomplete = ({
  name,
  defaultLabel,
  defaultPlaceholder,
  fieldData,
}: IAutocompleteProps) => {
  const t = useTranslations();
  const multiChoice = fieldData?.inputType === "MultiChoice";
  const singleChoice = fieldData?.inputType === "SingleChoice";
  const multiChoiceFreeText = fieldData?.inputType === "MultiChoiceFreeText";
  const multiInputRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const [showtooltip, setShowtooltip] = useState(false);
  const [items, setItems] = useState<string[]>(fieldData?.alternatives ?? []);

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const getErrorMessage = (type: string, maxLength?: string) => {
    switch (type) {
      case "required":
        return t("Errors:input_field_required");
      case "maxLength":
        return t("Errors:max_chars_exceeded").replace("{0}", maxLength ?? "");
      case "pattern":
        return t("Errors:regex_validation");
      case "validate":
        return t("Errors:InvalidAttributeValue");
      default:
        return null;
    }
  };

  const fieldsRules: { [key: string]: IValidatioAutocomplite } = {};

  if (fieldData) {
    for (const field of Object.entries(fieldData)) {
      const key = field[0];
      const value = {
        maxLength: field[1]?.validations?.maxLength,
        pattern: new RegExp(field[1]?.validations?.regex ?? ""),
        required: field[1]?.validations?.required,
      };
      fieldsRules[key] = value;
    }
  }

  const clearMultiInput = () => {
    if (multiInputRef.current) {
      multiInputRef.current.value = "";
    }
  };

  const filterItems = (event: AutoCompleteCompleteEvent) => {
    const query = event.query;
    let filteredItems = [];

    for (const element of fieldData?.alternatives ?? []) {
      if (element.toLowerCase().startsWith(query.toLowerCase())) {
        filteredItems.push(element);
      }
    }

    setItems(filteredItems);
  };

  // MultiChoiceFreeText need handle spaces and comas so value passed to back-end was prettified as a plain string
  const multiChoiceFreeTextHandler = (e: React.KeyboardEvent, val: string) => {
    if (e.key === "Enter") {
      // current entered value
      const target = e.target as HTMLInputElement;
      // remove extra spaces and commas
      const valueToSet = target.value
        .replace(/\s*,?\s*\n/g, ",")
        .trim()
        .split(",")
        .filter(Boolean)
        .join(", ");

      // ignore adding if value is empty
      if (!valueToSet) return;

      // check if value is already in the list and remove it if true
      const parsedValue: string[] = val ? val.toLowerCase().split(", ") : [];
      if (parsedValue.includes(target.value.toLowerCase())) {
        clearMultiInput();
        return;
      }

      // set new value and clear input
      setValue(name, val ? [val, valueToSet].join(", ") : valueToSet);
      clearMultiInput();
    }
  };

  useEffect(() => {
    if (labelRef.current && divRef.current) {
      setShowtooltip(divRef.current.offsetWidth < labelRef.current.offsetWidth);
    }
  }, [labelRef.current]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...fieldsRules[name],
        validate: (v) => (v?.length ? !!v.trim() : true),
      }}
      render={({ field }) => (
        <div className={styles.autocompleteWrapper}>
          <div ref={divRef} className={styles.autocompleteLabel}>
            {showtooltip && <PrimeTooltip target={`#${name}`} />}
            {fieldsRules[name]?.required && (
              <span className={inputStyles.requiredIcon}>*</span>
            )}
            <label
              ref={labelRef}
              id={name}
              htmlFor={name}
              data-pr-tooltip={fieldData?.label || defaultLabel}
            >
              {fieldData?.label || defaultLabel}
            </label>
          </div>
          {singleChoice && (
            <SingleChoice
              withClearOption
              field={field}
              name={name}
              options={items}
              placeholder={fieldData?.inlineHelp || defaultPlaceholder}
            />
          )}
          {multiChoice && (
            <MultiChoice
              name={name}
              options={items}
              field={field}
              placeholder={fieldData?.inlineHelp || defaultPlaceholder}
            />
          )}
          {!singleChoice && !multiChoice && (
            <AutoComplete
              delay={0}
              ref={field.ref}
              inputRef={multiInputRef}
              removeTokenIcon={"pi pi-times"}
              showEmptyMessage={!!fieldData?.alternatives.length}
              emptyMessage={t("Common:no_options")}
              multiple={multiChoiceFreeText}
              placeholder={
                multiChoiceFreeText && field.value?.length
                  ? ""
                  : fieldData?.inlineHelp ?? defaultPlaceholder
              }
              className={classNames({
                [styles.freeText]: true,
                [styles.multiSelect]: multiChoiceFreeText,
                [styles.filledBorder]: !!field.value,
                [styles.validationError]: !!errors[name],
              })}
              inputId={name}
              value={
                multiChoiceFreeText
                  ? field.value?.replace(/\s*,?\s*\n/g, ", ").split(", ")
                  : field.value
              }
              suggestions={items}
              completeMethod={filterItems}
              onChange={(e) => {
                if (multiChoiceFreeText) {
                  field.onChange(e.value?.length ? e.value?.join(", ") : null);
                  return;
                }
                field.onChange(e);
              }}
              onBlur={clearMultiInput}
              onKeyDown={(e) => {
                if (multiChoiceFreeText)
                  multiChoiceFreeTextHandler(e, field.value);
              }}
              panelStyle={{ maxWidth: multiInputRef.current?.offsetWidth }}
            />
          )}
          {errors?.[name] && (
            <div className={inputStyles.validationError}>
              <Icon name="exclamation-circle" size={14} />
              {getErrorMessage(
                errors[name].type as string,
                fieldsRules[name]?.maxLength?.toString()
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default Autocomplete;
