import { Controller, useFormContext } from "react-hook-form";
import { RespondentGroup } from "../../core/api/responsecenters/types";
import useTranslations from "../../core/i18n/useTranslations";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItemsWithIcons } from "../../ui-lib/components/Dropdown/DropdownItem";
import Table from "../../ui-lib/components/Tables/Table";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";

const RespondentGroupList = ({
  respondentGroups,
  disabled = false,
  loading,
}: {
  respondentGroups: RespondentGroup[];
  disabled?: boolean;
  loading?: boolean;
}) => {
  const t = useTranslations();

  const { control, setValue, watch } = useFormContext();

  const generateDropdown = (
    respondentGroup: RespondentGroup,
    index: number
  ) => (
    <Controller
      name={`respondentGroups.${index}`}
      control={control}
      render={({ field }) => {
        if (!field.value) {
          setValue(field.name, respondentGroup);
        }

        const forced = watch(`${field.name}.forced`);
        const allowed = watch(`${field.name}.allowed`);

        const dropdownItems: IDropdownItemsWithIcons[] = [
          {
            id: 0,
            name: t("Common:forced"),
            isSelected: forced?.valueOf()!,
            icon: "check",
            color: "Primary-700",
            onlySelected: true,
          },
          {
            id: 1,
            name: t("Common:optional"),
            isSelected: !forced?.valueOf() && allowed?.valueOf()!,
            icon: "minus",
            color: "Grey-500",
            onlySelected: true,
          },
          {
            id: 2,
            name: t("Common:not_allowed"),
            isSelected: !forced?.valueOf() && !allowed?.valueOf(),
            icon: "x",
            color: "Error-500",
            onlySelected: true,
          },
        ];
        return (
          <Dropdown
            disabled={disabled}
            selectedItem={dropdownItems.find((i) => i.isSelected)}
            items={dropdownItems}
            onSelectItem={(item) => {
              if (item.id === 0) {
                setValue(`${field.name}.forced`, true);
                setValue(`${field.name}.allowed`, true);
              } else if (item.id === 1) {
                setValue(`${field.name}.forced`, false);
                setValue(`${field.name}.allowed`, true);
              } else if (item.id === 2) {
                setValue(`${field.name}.forced`, false);
                setValue(`${field.name}.allowed`, false);
              }
            }}
          />
        );
      }}
    />
  );

  if (loading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <Table<RespondentGroup>
      resizableColumns={false}
      withShowMore
      showRowHover
      hideEmptyMessage
      paginatedItems={{
        items: respondentGroups,
        pagination: {
          offset: 0,
          limit: respondentGroups.length,
          total: respondentGroups.length,
        },
      }}
      columns={[
        {
          header: t("AlarmReceptions:respondent_group"),
          field: (rowData) => rowData.description,
        },
        {
          header: t("Common:access"),
          fieldTemplate: (rowData, index) => generateDropdown(rowData, index),
        },
      ]}
      items={respondentGroups}
    />
  );
};

export default RespondentGroupList;
