import { Controller, FormProvider, useForm } from "react-hook-form";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { NewResponseCenterParams } from "../../../core/api/responsecenters/types";
import useTranslations from "../../../core/i18n/useTranslations";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { CopySettings } from "./CopySettings";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { useEffect, useState } from "react";
import { getSellers } from "../../../core/api/sellers/sellers";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useUser from "../../../core/user/useUser";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";

export const CreateAlarmReceptionForm = ({
  isOpen,
  labels,
  withCopySettings,
  defaultValues,
  organisations,
  onSubmit,
  onClose,
  disabled,
}: {
  isOpen: boolean;
  labels: {
    title: string;
    description?: string;
    submit: string;
  };
  withCopySettings?: boolean;
  defaultValues?: Omit<NewResponseCenterParams, "name" | "subscriptionPeriod">;
  organisations: ITreeData[];
  onSubmit: (values: NewResponseCenterParams) => Promise<void>;
  onClose: () => void;
  disabled?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [resellers, setResellers] = useState<IItemProp[]>([]);
  const [selectedArcType, setSelectedArcType] = useState<number>(1);
  const isSuperAdmin = config?.show?.includes("SkygdSuperAdmin");

  const radioButtons = [
    {
      id: 1,
      value: "1",
      text: t("AlarmReceptions:arc_type_1"),
    },
    {
      id: 0,
      value: "0",
      text: t("AlarmReceptions:arc_type_0"),
    },
  ];

  const fetchResellersDetails = async () => {
    setIsLoading(true);
    try {
      const resellersResult = await getSellers(authenticatedRequest);
      const resellersItems = resellersResult.data.map((reseller) => ({
        id: reseller.id,
        name: reseller.name,
        isSelected: false,
      }));
      setResellers(resellersItems);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuperAdmin) {
      fetchResellersDetails();
    }
  }, [config]);

  const methods = useForm<NewResponseCenterParams>({ defaultValues });
  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    handleSubmit,
    reset,
  } = methods;

  const internalOnClose = () => {
    onClose();
    reset();
    setSelectedArcType(1);
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={internalOnClose}
      isOpen={isOpen}
      fixedWidth="848px"
      header={labels.title}
      loading={isSubmitting || isLoading}
      submitBtn={{
        text: labels.submit,
        onClick: handleSubmit(async (formValues) => {
          await onSubmit(formValues);
          reset();
        }),
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: internalOnClose,
      }}
    >
      {labels.description && (
        <p style={{ color: "var(--Grey-600)" }}>{labels.description}</p>
      )}

      <Spacer size={16} />

      <FormProvider {...methods}>
        <form>
          {isSuperAdmin && (
            <>
              <RadioGroup
                name="arc-type"
                items={radioButtons}
                selectedValue={
                  radioButtons.find((item) => item.id === selectedArcType)
                    ?.value
                }
                onChange={(value) => {
                  setSelectedArcType(Number(value));
                }}
                fullWidth
                label={t("AlarmReceptions:arc_type")}
              />
              <Spacer size={16} />
            </>
          )}
          <Row align="start">
            <Column style={{ width: "100%" }}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    inputRef={field.ref}
                    label={t("Common:name")}
                    description={t("AlarmReceptions:input_name_description")}
                    placeholder={t("AlarmReceptions:input_name_placeholder")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.name && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }}>
              {selectedArcType === 0 && isSuperAdmin && (
                <Controller
                  name="sellerId"
                  control={control}
                  render={() => (
                    <Dropdown
                      required
                      dataCy="sellerSelector"
                      onSelectItem={(item) => {
                        const updatedResellersItems = resellers.map(
                          (reseller) => ({
                            ...reseller,
                            isSelected: reseller.id === item.id,
                          })
                        );
                        setResellers(updatedResellersItems);
                        setValue("sellerId", item.id as number);
                      }}
                      items={resellers}
                      title={t("Reseller:reseller")}
                      selectedItem={resellers.find(
                        (reseller) => reseller.isSelected
                      )}
                    />
                  )}
                />
              )}
              {(selectedArcType === 1 || !isSuperAdmin) && (
                <Controller
                  name="organizationId"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Tree
                      required
                      treeRef={field.ref}
                      label={t("Common:belongs_to")}
                      placeholder={t("Common:organisation")}
                      items={organisations}
                      selectedTreeItem={field.value}
                      onSelectItem={(data?: ITreeData) => {
                        const customerId = data?.key as number | undefined;
                        setValue(field.name, customerId);
                      }}
                      disabled={disabled}
                      showClearOption={false}
                      validationError={
                        errors.organizationId &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
              )}
            </Column>
          </Row>

          <Spacer size={16} />

          {withCopySettings && (
            <>
              <CopySettings />

              <Spacer size={32} />
            </>
          )}
        </form>
      </FormProvider>
    </PrimeModal>
  );
};
