import { Link } from "react-router-dom";
import useTranslations from "../../../core/i18n/useTranslations";
import { RowData } from "../../../pages/Organizations/Organizations";
import { ITreeData } from "../Hierarchy/Tree";
import styles from "./ChildrenTreePopup.module.css";
import PrimeModal from "../PrimeModal/PrimeModal";

const ChildrenTreeModal = ({
  organizations,
  isOpen,
  onClose = () => {},
}: {
  organizations: RowData | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations();

  if (!organizations) return null;

  const renderChildren = (child: ITreeData, step = 1) => (
    <div key={`${child.key}_key`}>
      <Link to={`/adminportal/organizations/${child.key}`}>
        <p
          className={
            child.key === Number(organizations.id || organizations.key)
              ? styles.popupRootChild
              : styles.popupSecondaryChild
          }
          key={child.key}
          style={{ paddingLeft: `${12 * step}px` }}
        >
          {child.label}
        </p>
      </Link>
      {child.children && child.children.length > 0
        ? child.children?.map((innerChild) =>
            renderChildren(innerChild, step + 1)
          )
        : null}
    </div>
  );

  return (
    <PrimeModal
      withHeader
      withFooter
      isOpen={isOpen}
      onClose={onClose}
      header={t("Organizations:organizations_hierarchy")}
      submitBtn={{
        text: t("Common:close"),
        variant: "secondary",
        onClick: onClose,
      }}
      fixedWidth="848px"
    >
      <div>
        {!organizations.root ||
        organizations.id === organizations.root.key?.toString() ? (
          <>
            <Link to={`/adminportal/organizations/${organizations.id}`}>
              <p className={styles.popupMainChild}>{organizations.value}</p>
            </Link>
            {organizations.children?.map((child: ITreeData) =>
              renderChildren(child)
            )}
          </>
        ) : (
          <>
            <Link to={`/adminportal/organizations/${organizations.root.key}`}>
              <p className={styles.popupRoot}>{organizations.root.label}</p>
            </Link>
            {organizations.root.children?.map((child: ITreeData) =>
              renderChildren(child)
            )}
          </>
        )}
      </div>
    </PrimeModal>
  );
};

export default ChildrenTreeModal;
