import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Spacer } from "../../components/Layout/Layout";
import { moveTransmitterToOrganisation } from "../../core/api/transmitters/transmitters";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { Transmitter } from "../../core/api/transmitters/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import useUser from "../../core/user/useUser";
import useTranslations from "../../core/i18n/useTranslations";
import Alert from "../../ui-lib/components/Alerts/Alert";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

const MoveTransmitterModal = ({
  onClose,
  name,
  modalTitle,
  text,
  transmitters,
  isOpen,
  closeModal,
}: {
  onClose?: (success: boolean) => void;
  name: string;
  modalTitle: string;
  text: string;
  transmitters: Transmitter[];
  isOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { data: organisations } = useOrganizationsTree();
  const [selectedOrg, setSelectedOrg] = useState<number>();
  const [errors, setErrors] = useState<string[]>();
  const [isSaveSuccess, setIsSaveSuccess] = useState(true);

  const close = (success: boolean = false) => {
    setSelectedOrg(undefined);
    setErrors(undefined);
    closeModal(false);
    onClose?.(success);
  };

  const handleSubmit = async () => {
    let success = true;
    const newErrors: string[] = [];
    const transmittersToMove: Transmitter[] = [];
    if (selectedOrg) {
      setIsLoading(true);
      try {
        transmitters.forEach((transmitter) => {
          if (transmitter.organizationId === selectedOrg) {
            newErrors.push(transmitter.identifier);
            success = false;
          } else {
            transmittersToMove.push(transmitter);
          }
        });

        if (transmittersToMove.length) {
          const { data } = await moveTransmitterToOrganisation(
            transmittersToMove.map((tr) => tr.id),
            selectedOrg,
            authenticatedRequest
          );
          if (data.validationErrors && data.validationErrors.length > 0) {
            success = false;
            data.validationErrors.forEach((validationError) => {
              const errorId = validationError.parameters.TransmitterId;
              if (errorId) {
                const errorTransmitter = transmitters.find(
                  (tr) => tr.id === errorId
                );
                if (errorTransmitter) {
                  newErrors.push(errorTransmitter.identifier);
                }
              }
            });
          }
        }

        if (newErrors.length > 0) setErrors(newErrors);
        else {
          setErrors(undefined);
          notify({
            message: t("Transmitters:move_success"),
          });
        }
      } catch (error: any) {
        notify({ message: error.message, variant: "error" });
        success = false;
      }
    }
    setIsLoading(false);
    if (success) {
      close(true);
    } else {
      setIsSaveSuccess(false);
    }
  };

  useEffect(() => {
    if (isOpen && !isSaveSuccess) {
      setIsSaveSuccess(true);
    }
    return () => {
      setSelectedOrg(undefined);
      setErrors(undefined);
    };
  }, [isOpen]);

  return (
    <PrimeModal
      withFooter
      withHeader
      header={modalTitle}
      onClose={() => {
        closeModal(false);
      }}
      isOpen={isOpen}
      fixedWidth="960px"
      loading={isLoading}
      submitBtn={{
        text: `${t("Transmitters:button_move_transmitters")} ${
          isSaveSuccess ? `(${transmitters.length})` : ""
        }`,
        onClick: handleSubmit,
        disabled: !selectedOrg || !isSaveSuccess,
      }}
      cancelBtn={{
        text: t("Common:cancel"),
        onClick: () => {
          closeModal(false);
        },
      }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {text.replace("{transmitter}", name)}
      </p>
      <Spacer size={16} />
      <Tree
        label={t("Transmitters:new_organisation")}
        id="transmitter_move_organisation"
        placeholder={t("Common:select_organisation")}
        items={organisations}
        selectedTreeItem={selectedOrg}
        onSelectItem={(data: ITreeData) => {
          if (data?.key) {
            setSelectedOrg(+data.key);
          }
        }}
      />
      {errors && (
        <>
          <Spacer size={32} />
          <Alert
            items={errors.map((err) => ({ title: err }))}
            variant="error"
            icon="exclamation-circle"
            title={t("Transmitters:move_failed").replace(
              "{count}",
              errors.length.toString()
            )}
            text={t("Transmitters:move_failed_explanation")}
          />
        </>
      )}
    </PrimeModal>
  );
};

export default MoveTransmitterModal;
