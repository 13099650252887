import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./IconWithInfo.module.css";
import Icon, { IconColor } from "../Icon";
import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";

export type IconWithInfoProps = {
  iconName?: string;
  iconColor: IconColor;
  addIconBg?: boolean;
  headerText?: string;
  bodyComponents: ReactNode;
  additionalStyles?: any;
  withHeaderIcon?: boolean;
  headerIconText?: string;
};

export const IconWithInfo = ({
  iconName,
  iconColor,
  addIconBg = false,
  headerText = undefined,
  bodyComponents,
  additionalStyles,
  withHeaderIcon,
  headerIconText,
}: IconWithInfoProps) => {
  return (
    <div className={styles.iconWithInfoContainer} style={additionalStyles}>
      {iconName && (
        <div
          className={classNames(styles.iconWrapper, {
            [styles.iconBackground]: addIconBg,
          })}
        >
          <Icon name={iconName} color={iconColor} />
        </div>
      )}

      <div>
        {headerText && (
          <p className={styles.infoHeaderWrapper}>
            {headerText}{" "}
            {withHeaderIcon && headerIconText && (
              <>
                <PrimeTooltip target="#question-mark" position="top" />
                <span id="question-mark" data-pr-tooltip={headerIconText}>
                  <Icon
                    className={styles.iconHeader}
                    name="question-mark-circle"
                    size={18}
                    color="Grey-300"
                    hoverEffect
                  />
                </span>
              </>
            )}
          </p>
        )}
        <span className={styles.infoBodyWrapper}>{bodyComponents}</span>
      </div>
    </div>
  );
};
