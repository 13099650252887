import { AxiosInstance, AxiosResponse } from "axios";
import { capitalize } from "lodash";
import { useState } from "react";
import { notifyApiErrors } from "../core/helpers/helpers";
import useTranslations from "../core/i18n/useTranslations";
import { ApiResponse } from "../core/interfaces/Api";
import useUser from "../core/user/useUser";
import { notify } from "../ui-lib/components/Alerts/Toast";
import Button from "../ui-lib/components/Button/Button";
import { Row, Spacer } from "./Layout/Layout";
import PrimeModal from "../ui-lib/components/PrimeModal/PrimeModal";

const Footer = ({
  isLoading,
  deleteButtonText,
  handleDelete,
  onClose,
}: {
  isLoading: boolean;
  deleteButtonText?: string;
  handleDelete: () => Promise<void>;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const buttonLabel = t("Common:delete");
  return (
    <Row type="left">
      <Button
        variant="destructive"
        text={deleteButtonText ?? buttonLabel}
        loading={isLoading}
        onClick={handleDelete}
      />
      <Spacer size={8} />
      <Button variant="secondary" text={t("Common:cancel")} onClick={onClose} />
    </Row>
  );
};

const BulkDeleteModal = ({
  isOpen,
  onClose = () => {},
  onDelete = () => {},
  labels,
  ids,
  apiFunc,
  deleteButtonText,
  css,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (status: boolean) => void;
  labels: {
    single: string;
    multi: string;
  };
  ids: string[];
  apiFunc(
    Id: string,
    authenticatedRequest: AxiosInstance
  ): Promise<AxiosResponse<ApiResponse<any>>>;
  deleteButtonText?: string;
  css?: React.CSSProperties;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const type = ids.length > 1 ? labels.multi : labels.single;
  const title = t("Common:delete_modal_title").replace("{type}", type);
  const confirmMessage =
    ids.length > 1
      ? t("Common:delete_modal_confirm_batch")
          .replace("{count}", `${ids.length}`)
          .replace("{type}", type)
      : t("Common:delete_modal_confirm").replace("{type}", type);
  const successMessage = capitalize(
    t("Common:delete_success").replace("{type}", type)
  );

  const handleDelete = async () => {
    setIsLoading(true);
    let success = true;
    const promises = ids.map((id) => apiFunc(id, authenticatedRequest));
    await Promise.all(promises).catch((error) => {
      success = false;
      notifyApiErrors(error.response?.data?.errors, error.response?.status);
    });
    setIsLoading(false);

    if (success) {
      notify({
        message: successMessage,
      });
    }
    onDelete(success);
  };

  return (
    <PrimeModal
      withHeader
      isOpen={isOpen}
      onClose={onClose}
      header={title}
      contentStyle={css}
      fixedWidth="576px"
      customFooter={
        <Footer
          isLoading={isLoading}
          deleteButtonText={deleteButtonText}
          handleDelete={handleDelete}
          onClose={onClose}
        />
      }
    >
      <p style={{ color: "var(--Grey-600)" }}>{confirmMessage}</p>
    </PrimeModal>
  );
};

export default BulkDeleteModal;
