import {
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useTranslations from "../../core/i18n/useTranslations";
import { IDropdownItem, IItemProp } from "../components/Dropdown/DropdownItem";
import { Dictionary } from "lodash";
import { ICheckbox } from "../components/Checkbox/Checkbox";

export interface DropdownProps {
  items: Array<IItemProp>;
  selectedItem?: IItemProp;
  withoutCheckIcon?: boolean;
  withFilter?: boolean;
  onSelectItem(arg0: IDropdownItem, e: any): void;
  onOpenStateChanged?(isOpen: boolean): void;
  title?: string;
  placeholder?: string;
  width?: number | string;
  bodyWidth?: number | string;
  className?: string;
  buttonStyle?: Dictionary<CSSProperties>;
  dropdownInPopup?: boolean;
  invalid?: boolean;
  validationError?: string;
  disabled?: boolean;
  maxVisible?: number;
  id?: string;
  withClearItem?: boolean;
  innerButtonStyles?: CSSProperties;
  showTooltip?: boolean;
  dataCy?: string;
  isLoading?: boolean;
  searchPlaceholder?: string;
  ariaLable?: string;
  required?: boolean;
}

export interface IDropdownWithCheckboxes {
  items: ICheckbox[];
  id?: string;
  placeholder?: string;
  width?: number | string;
  containerWidth?: number | string;
  getSelectedItems(arg0: ICheckbox[]): void;
  title?: string;
  withFilter?: boolean;
  maxVisible?: number;
}

const ScrollBuffer = 8;
const useScroll = (
  isOpen: boolean,
  focusedIndex: number,
  optionContainer: RefObject<HTMLDivElement>,
  withButtons?: boolean
) => {
  useEffect(() => {
    if (optionContainer.current && focusedIndex >= 0) {
      const children = optionContainer.current.getElementsByClassName("option");
      const focusedChild = children?.length
        ? (children[focusedIndex] as HTMLDivElement)
        : null;
      if (focusedChild?.getBoundingClientRect) {
        const { height: optionHeight } = focusedChild.getBoundingClientRect();
        const { height: listHeight } =
          optionContainer.current.getBoundingClientRect();
        const scrollTop = withButtons
          ? optionContainer.current.scrollTop + optionHeight
          : optionContainer.current.scrollTop;
        const isAbove = focusedChild.offsetTop <= scrollTop;
        const isInView =
          (focusedChild.offsetTop >= scrollTop || !isAbove) &&
          focusedChild.offsetTop + optionHeight <= scrollTop + listHeight;

        if (!isInView) {
          if (isAbove) {
            optionContainer.current.scrollTo({
              top: focusedChild.offsetHeight - optionHeight,
            });
          } else {
            optionContainer.current.scrollTo({
              top:
                focusedChild.offsetTop -
                listHeight +
                optionHeight +
                ScrollBuffer,
            });
          }
        }
      }
    }
  }, [focusedIndex, isOpen, optionContainer.current?.scrollHeight]);
};

export const useDropdownHooks = (props: DropdownProps) => {
  const t = useTranslations();

  const {
    items: _items,
    onSelectItem,
    onOpenStateChanged,
    selectedItem,
    withFilter = false,
    withoutCheckIcon,
    title,
    buttonStyle = {},
    placeholder = t("Common:select_option"),
    width,
    bodyWidth,
    className = "",
    dropdownInPopup = false,
    invalid = false,
    validationError = undefined,
    disabled = false,
    maxVisible,
    id,
    withClearItem,
    innerButtonStyles,
    showTooltip,
    dataCy,
    isLoading,
    searchPlaceholder,
    ariaLable,
    required,
  } = props;
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [dropdownFocused, setDropdownFocused] = useState(false);

  const items: IItemProp[] = useMemo(
    () =>
      withClearItem
        ? [
            { id: -1, name: `--${placeholder}--`, isSelected: !selectedItem },
            ..._items,
          ]
        : _items,
    [withClearItem, placeholder, _items, selectedItem]
  );

  const selectedIndex = useMemo(
    () => !!items && items.map((o) => o.id).indexOf(selectedItem?.id ?? -1),
    [items, selectedItem]
  );

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
    if (btnRef.current) {
      btnRef.current.focus();
    }
  }, [btnRef.current, isOpen]);

  const openDropdown = useCallback(() => {
    setFocusedIndex(selectedIndex);
    setIsOpen(true);
  }, [selectedIndex, isOpen]);

  const setValue = useCallback(
    (e: any, newOption?: IDropdownItem, shouldClose = false) => {
      if (newOption) {
        onSelectItem(newOption, e);
      }
      if (shouldClose) {
        closeDropdown();
      }
    },
    [onSelectItem, closeDropdown]
  );

  useScroll(isOpen, focusedIndex, container);

  return {
    focusedIndex,
    setFocusedIndex,
    onOpenStateChanged,
    isOpen,
    setIsOpen,
    dropdownFocused,
    setDropdownFocused,
    setValue,
    openDropdown,
    closeDropdown,
    container,
    btnRef,
    selectedItem,
    items,
    withFilter,
    withoutCheckIcon,
    onSelectItem,
    title,
    placeholder,
    width,
    bodyWidth,
    className,
    buttonStyle,
    dropdownInPopup,
    invalid,
    validationError,
    disabled,
    maxVisible,
    id,
    innerButtonStyles,
    showTooltip,
    dataCy,
    isLoading,
    searchPlaceholder,
    ariaLable,
    required,
    withClearItem,
  };
};

export const useDropdownWithCheckboxHooks = (
  props: IDropdownWithCheckboxes,
  withButtons?: boolean
) => {
  const {
    items,
    getSelectedItems,
    title,
    width,
    containerWidth,
    id,
    placeholder,
    withFilter,
    maxVisible,
  } = props;
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [dropdownFocused, setDropdownFocused] = useState(false);

  const closeDropdown = useCallback(
    (focus = false) => {
      setIsOpen(false);
      if (focus && btnRef.current) {
        btnRef.current.focus();
      }
    },
    [btnRef.current, setIsOpen]
  );

  const setItems = useCallback(
    (newOption?: any, shouldClose = false) => {
      if (newOption) {
        getSelectedItems(newOption);
      }
      if (shouldClose) {
        closeDropdown(true);
      }
    },
    [getSelectedItems, closeDropdown]
  );

  useScroll(isOpen, focusedIndex, container, withButtons);

  return {
    focusedIndex,
    setFocusedIndex,
    isOpen,
    setIsOpen,
    dropdownFocused,
    setDropdownFocused,
    setItems,
    closeDropdown,
    container,
    btnRef,
    getSelectedItems,
    items,
    id,
    title,
    width,
    containerWidth,
    placeholder,
    withFilter,
    maxVisible,
  };
};
