import classNames from "classnames";
import { useState, useEffect } from "react";
import { TreeSelect, TreeSelectChangeEvent } from "primereact/treeselect";
import {
  findNodeById,
  getAllOrganizationsRecursive,
  markParentNodeForSelectedItem,
} from "../../../core/utils/organizations";
import { getUniqueId } from "../../../core/hooks/getUniqueId";
import { Spacer } from "../../../components/Layout/Layout";
import { TreeNode } from "primereact/treenode";
import Icon from "../Icon";
import Checkbox from "../Checkbox/Checkbox";
import TruncateValue from "../../../components/TruncateValue";

import styles from "./Hierarchy.module.css";
import inputStyles from "../Inputs/Input.module.css";

export interface ITreeData {
  key?: string | number;
  label?: string;
  data?: any;
  icon?: string;
  children?: ITreeData[];
  style?: object;
  className?: string;
  droppable?: boolean;
  draggable?: boolean;
  selectable?: boolean;
  leaf?: boolean;
  hasSelectedChild?: boolean;
  requireCostCenter?: boolean;
  parentId?: number | null;
}

export interface TreeProps {
  label?: string;
  hideLabel?: boolean;
  items: ITreeData[];
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  className?: string;
  selectedTreeItem?: string | number | null;
  onSelectItem: (data: any) => any;
  disabled?: boolean;
  placeholder: string;
  validationError?: string;
  id?: string;
  filter?: boolean;
  selectionMode?: "multiple" | "checkbox" | "single";
  showClearOption?: boolean;
  withCheckboxTootip?: boolean;
  required?: boolean;
  treeRef?: any;
}

export default function Tree({
  label,
  hideLabel = false,
  items,
  style,
  wrapperStyle,
  className,
  selectedTreeItem,
  onSelectItem,
  disabled = false,
  placeholder,
  validationError,
  filter = true,
  id = getUniqueId("tree"),
  selectionMode = "single",
  showClearOption = true,
  withCheckboxTootip,
  required,
  treeRef,
}: Readonly<TreeProps>) {
  const clearOption: ITreeData = {
    key: -1,
    label: placeholder,
    children: [],
  };

  const [nodes, setNodes] = useState([] as any);
  const [selectedKey, setSelectedKey] = useState<any>(selectedTreeItem);
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );

  const arrayChecker = (arr: any[], target: any[]) =>
    target.every((v) => arr.includes(v));

  const makeSelection = (key: any) => {
    if (selectionMode === "multiple") {
      let ids = {} as any;
      if (typeof key === "string") {
        const keys = key.split(",");
        keys.forEach((orgId) => {
          const parentNode = items.find(
            (parent) => parent.key === parseInt(orgId, 10)
          );
          if (parentNode) {
            const childrenIds = (parentNode.children || []).map(
              (child) => `${child.key}`
            );
            ids[orgId] = {
              checked: arrayChecker(keys, childrenIds),
              partialChecked: !arrayChecker(keys, childrenIds),
            };
          } else {
            const childNode = findNodeById(items, parseInt(orgId, 10));
            const subChildrenIds = (childNode?.children || []).map(
              (child: ITreeData) => `${child.key}`
            );
            if (subChildrenIds.length > 1) {
              if (subChildrenIds.every((v: string) => keys.includes(v))) {
                ids[orgId] = {
                  checked: true,
                  partialChecked: false,
                };
              } else {
                ids[orgId] = {
                  checked: false,
                  partialChecked: true,
                };
              }
            } else {
              ids[orgId] = {
                checked: true,
                partialChecked: false,
              };
            }
          }
        });
      } else {
        ids = { ...key };
      }
      setSelectedKey(ids);
      onSelectItem(Object.keys(ids).join(","));
    } else {
      if (key === selectedKey) return;
      setSelectedKey(key);
      setNodes((prevNodes: ITreeData[]) =>
        markParentNodeForSelectedItem(key, prevNodes)
      );
      onSelectItem(findNodeById(nodes, key));
    }
  };

  const selectItem = (e: TreeSelectChangeEvent) => {
    const key = e.value as any;
    makeSelection(key || -1);
  };

  const getParentAndAllChildIds = (option: TreeNode) => {
    const allChildOrgs = getAllOrganizationsRecursive()(
      option.children! as ITreeData[]
    );
    const childIds = allChildOrgs.map((item) => item.key?.toString());
    return [...childIds, option.key?.toString()];
  };

  useEffect(() => {
    setNodes(items);
  }, [items]);

  useEffect(() => {
    if (!selectedTreeItem) {
      setSelectedKey(clearOption.key);
    } else {
      makeSelection(selectedTreeItem);
    }
  }, [selectedTreeItem]);

  return (
    <div style={{ alignSelf: "stretch", ...wrapperStyle }}>
      {label && (
        <>
          {required && <span className={inputStyles.requiredIcon}>*</span>}
          <label htmlFor={id} className={hideLabel ? "visuallyHidden" : ""}>
            {label}
          </label>
        </>
      )}
      <TreeSelect
        ref={treeRef}
        selectionMode={selectionMode}
        metaKeySelection={false}
        value={selectedKey}
        disabled={disabled}
        options={showClearOption ? [clearOption, ...nodes] : nodes}
        onChange={selectItem}
        placeholder={placeholder}
        filter={filter}
        filterMode="lenient"
        style={style}
        className={classNames(className ?? "", {
          [styles.inputValidationError]: !!validationError,
        })}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        nodeTemplate={(option) => {
          if (selectionMode === "multiple" && option.children?.length) {
            const idsToSet = getParentAndAllChildIds(option);
            return (
              <>
                <Checkbox
                  tooltip={withCheckboxTootip}
                  checked={arrayChecker(Object.keys(selectedKey), idsToSet)}
                  onChange={(e: any) => {
                    e.stopPropagation();
                    // Reverted state. If checked - make selection either deselection
                    if (e.checked) {
                      // Previously selected ids + option current ids in unique values array
                      const allSelectedIds = Array.from(
                        new Set([...idsToSet, ...Object.keys(selectedKey)])
                      );
                      makeSelection(allSelectedIds.join(","));
                    }
                    if (!e.checked) {
                      // Filtered ids without those that was deselected
                      const idsWithUnselected = Object.keys(selectedKey).filter(
                        (i) => !idsToSet.includes(i)
                      );
                      makeSelection(
                        idsWithUnselected.length
                          ? idsWithUnselected.join(",")
                          : []
                      );
                    }
                  }}
                  style={{ padding: 0 }}
                />
                <TruncateValue
                  value={option.label ?? ""}
                  className="p-treenode-label"
                />
                <Spacer size={38} />
              </>
            );
          }
          return (
            <>
              {selectionMode === "multiple" && <Spacer size={24} />}
              <TruncateValue
                value={option.label ?? ""}
                className="p-treenode-label"
              />
              <Spacer size={38} />
            </>
          );
        }}
        scrollHeight="350px"
        inputId={id}
      />
      {validationError ? (
        <div className={styles.validationError}>
          <Icon name="exclamation-circle" size={14} />
          {validationError}
        </div>
      ) : null}
    </div>
  );
}
