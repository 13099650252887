import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useTranslations from "../core/i18n/useTranslations";
import PrimeModal from "../ui-lib/components/PrimeModal/PrimeModal";

export const UnsavedChanges = ({
  isDirty,
  onReset,
}: {
  isDirty: boolean;
  onReset?(): void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [goTo, setGoTo] = useState<string>();

  useLayoutEffect(() => {
    const unblock = history.block((location) => {
      // Allow navigation if no changes or if modal is open
      if (!isDirty || modalOpen) {
        return undefined;
      }

      // Show modal and block navigation
      setGoTo(location.pathname);
      setModalOpen(true);
      return false;
    });

    return () => {
      unblock();
    };
  }, [isDirty, modalOpen]);

  return (
    <PrimeModal
      withFooter
      withHeader
      fixedWidth="576px"
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      header={t("Common:unsaved_changes_title")}
      submitBtn={{
        text: t("Common:unsaved_changes_stay"),
        onClick: () => setModalOpen(false),
      }}
      cancelBtn={{
        text: t("Common:unsaved_changes_leave"),
        onClick: () => {
          if (goTo) {
            onReset?.();
            history.push(goTo);
          }

          setModalOpen(false);
        },
      }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Common:unsaved_changes_description")}
      </p>
    </PrimeModal>
  );
};
