import { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Icon } from "../Icon";

import styles from "./CollapsibleBlock.module.css";

export interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  visibility?: boolean;
  withScroll?: boolean;
  className?: string;
  onClick?: () => void;
}
export default function CollapsibleBlock({
  title,
  children,
  visibility = false,
  withScroll,
  className,
  onClick,
}: Readonly<CollapsibleProps>) {
  const [show, setShow] = useState<boolean>(false);
  const collapseRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    setShow(!show);
    onClick?.();
  };

  useEffect(() => {
    setShow(visibility);
  }, [visibility]);

  useEffect(() => {
    if (show && withScroll)
      setTimeout(() => {
        collapseRef?.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 100);
  }, [show]);

  return (
    <>
      <button
        ref={collapseRef}
        className={classNames(styles.container, className)}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "Space") {
            handleClick(e);
          }
        }}
      >
        <div
          className={classNames({
            [styles.titleContainer]: true,
          })}
        >
          <span className={styles.title}>{title}</span>
          {show ? (
            <Icon name="chevron-up" color="Primary-700" size={18} />
          ) : (
            <Icon name="chevron-down" color="Primary-700" size={18} />
          )}
        </div>
      </button>
      <div
        className={classNames({
          [styles.hidden]: !show,
          ["w-100"]: true,
        })}
      >
        {children}
      </div>
    </>
  );
}
