import { Controller, useFormContext } from "react-hook-form";
import { Column } from "../Layout/Layout";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import useTranslations from "../../core/i18n/useTranslations";

export const SelectOrganization = ({
  organisations,
  disable,
  respondent,
  mandatory = true,
}: {
  organisations: ITreeData[];
  disable: boolean;
  respondent: boolean;
  mandatory?: boolean;
}) => {
  const t = useTranslations();
  const {
    formState: { errors },
    control,
    trigger,
    setValue,
  } = useFormContext();
  return (
    <Column>
      <Controller
        name="organizationId"
        control={control}
        rules={{ required: mandatory }}
        render={({ field }) => (
          <Tree
            required
            treeRef={field.ref}
            selectedTreeItem={field.value}
            label={
              respondent ? t("Common:organisation") : t("Common:belongs_to")
            }
            placeholder={t("Common:select_organisation")}
            items={organisations}
            disabled={!organisations.length || disable}
            onSelectItem={(selectedOrg?: ITreeData) => {
              setValue(field.name, selectedOrg?.key as number);
              trigger(field.name);
            }}
            validationError={
              errors.organizationId && t("Errors:input_field_required")
            }
          />
        )}
      />
    </Column>
  );
};
