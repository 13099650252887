import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { uniqBy } from "lodash";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Spacer } from "../../../../components/Layout/Layout";
import { getLegacyAdmins } from "../../../../core/api/administrators/administrators";
import { addUserAdminRelation } from "../../../../core/api/useradmins/useradmins";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import SearchInput from "../../../../ui-lib/components/Inputs/SearchInput";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { SelectAdminsTable } from "./SelectAdminsTable";
import { AdministratorResponse } from "../../../../core/api/administrators/types";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

interface AddRelatedAdminParams {
  adminId?: number;
}

const AddRelatedAdminModal = ({
  objectId,
  added,
  isOpen,
  onClose,
  onAdd,
}: {
  objectId: string;
  added: number[];
  isOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();

  const [searchString, setSearchString] = useState("");
  const [selected, setSelected] = useState<number[]>([]);
  const [total, setTotal] = useState<AdministratorResponse[]>([]);
  const [pageSettings, setPageSettings] = useState({
    page: 1,
    pageSize: 10,
  });

  const {
    formState: { isSubmitting },
    control,
    handleSubmit,
  } = useForm<AddRelatedAdminParams>();

  const { authenticatedRequest } = useUser();
  const [data, setData] = useState<AdministratorResponse[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState({
    ...pageSettings,
    q: searchString,
  });

  const fetchAdministrators = async () => {
    try {
      setIsLoading(true);
      const results = await getLegacyAdmins(authenticatedRequest, {
        ...pageSettings,
        q: searchString,
      });
      setData(results.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const [canSubmit, setCanSubmit] = useState(false);
  useEffect(() => {
    setCanSubmit(
      selected.length > 0 &&
        !!data?.find((i) =>
          selected.find((selectedItem) => selectedItem === i.id)
        )
    );

    if (data) {
      if (searchParams.q !== searchString) {
        setTotal([...data]);
      } else {
        setTotal((prev) => {
          const updated = [...prev, ...data];
          return uniqBy(updated, "id");
        });
      }
      setSearchParams({
        ...pageSettings,
        q: searchString,
      });
    }
  }, [selected, data]);

  useEffect(() => {
    fetchAdministrators();
  }, [pageSettings, searchString]);

  const onSubmit = async () => {
    if (selected.length === 0) {
      return;
    }

    let success = true;
    const promises = selected.map((id) =>
      addUserAdminRelation(objectId, id, authenticatedRequest)
    );
    await Promise.all(promises).catch((error) => {
      success = false;
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    });

    if (success) {
      onAdd();
      setCanSubmit(false);
      notify({
        message: t(`Messages:add_related_admins_success`),
      });
    }
  };

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <PrimeModal
      withHeader
      withFooter
      fixedWidth="848px"
      header={t("Objects:add_related_admin")}
      isOpen={isOpen}
      onClose={onClose}
      loading={isSubmitting}
      submitBtn={{
        text: t("Common:save"),
        variant: "primary",
        onClick: handleSubmit(onSubmit),
        disabled: !canSubmit || isSubmitting,
      }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Objects:add_related_admin_description")}
      </p>
      <Spacer size={16} />
      <SearchInput
        placeholder={t("Administrator:administrators_search_placeholder")}
        value={searchString}
        onChange={(value) => {
          setSearchString(value);
          setPageSettings({
            ...pageSettings,
            page: 1,
          });
        }}
        limit={1}
      />
      <Spacer size={20} />
      <form id="add-admin-relation">
        <h2 className="subTitle">{t("Administrator:admins")}</h2>
        <Spacer size={16} />
        <Controller
          name="adminId"
          control={control}
          render={() => (
            <SelectAdminsTable
              admins={total}
              isLoading={isLoading}
              added={added}
              upForAdd={selected}
              pageSettings={pageSettings}
              onPageSettingsChange={setPageSettings}
              setUpForAdd={(adminId) => {
                const indexOfNewItem = selected.indexOf(adminId);
                if (indexOfNewItem === -1) {
                  setSelected([...selected, adminId]);
                } else {
                  const updatedSelected = [...selected];
                  updatedSelected.splice(indexOfNewItem, 1);
                  setSelected(updatedSelected);
                }
              }}
            />
          )}
        />
      </form>
    </PrimeModal>
  );
};

export default observer(AddRelatedAdminModal);
