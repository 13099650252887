import {
  Control,
  Controller,
  FieldError,
  UseFormClearErrors,
  UseFormReset,
  UseFormWatch,
} from "react-hook-form";
import moment from "moment/moment";
import { ReactNode, useState } from "react";
import classNames from "classnames";
import useTranslations from "../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import TextInput from "../Inputs/TextInput";
import { IScheduleItem } from "../../../core/api/contacts/types";
import styles from "./ScheduleItem.module.css";
import Button from "../Button/Button";
import DatePicker from "../DateAndTime/DatePicker";
import Checkbox from "../Checkbox/Checkbox";
import TimePicker from "../DateAndTime/TimePicker";
import Dropdown from "../Dropdown/Dropdown";
import { CustomRepentanceModal } from "../../../pages/CommonContacts/Accessibility/CustomRepentanceModal";
import {
  getCurrentDayNumber,
  getPreselectedRepeatSchedule,
} from "../../utils/schedulerHelper";
import InputContainer from "../Inputs/InputContainer";
import Icon from "../Icon";
import { CustomSelectedDays } from "./CustomSelectedDays";
import { useRepentanceDropdown } from "../../../core/hooks/useRepentanceDropdown";
import PrimeModal from "../PrimeModal/PrimeModal";

export const ScheduleItem = ({
  ref,
  dateFormat,
  defaultValues,
  skipViewMode = false,
  partOfForm = false,
  control,
  errors,
  watch,
  handleSubmit,
  setValue,
  getValues,
  trigger,
  onItemRemove,
  onSubmit,
  onDelete,
  reset,
  clearErrors,
}: {
  ref?: any;
  dateFormat: string;
  defaultValues?: any;
  skipViewMode?: boolean;
  partOfForm?: boolean;
  control: Control<any, any>;
  errors: any;
  watch: UseFormWatch<any>;
  handleSubmit?: any;
  setValue: any;
  getValues: any;
  trigger: any;
  onItemRemove?: () => void;
  onDelete?: (id: number) => void;
  onSubmit?: (values: IScheduleItem) => Promise<void>;
  reset?: UseFormReset<any>;
  clearErrors: UseFormClearErrors<any>;
}) => {
  const t = useTranslations();

  const namePrefix = partOfForm ? "scheduleItem." : "";
  const repeatDays = watch(`${namePrefix}repeatDays`);
  const startTime = watch(`${namePrefix}startTime`);
  const endTime = watch(`${namePrefix}endTime`);
  const preselectedRepeatSchedule = getPreselectedRepeatSchedule(
    defaultValues?.repeatDays,
    defaultValues?.endDate
  );
  const [isAllDay, setIsAllDay] = useState(
    defaultValues
      ? defaultValues[`${namePrefix}startTime`] === "00:00" &&
          defaultValues[`${namePrefix}endTime`] === "23:59"
      : false
  );

  const [isFormEditable, setIsFormEditable] = useState(
    skipViewMode || !defaultValues?.id
  );
  const [isCustomModalOpened, setIsCustomModalOpened] = useState(false);
  const [showCustomSelectedDays, setShowCustomSelectedDays] = useState(
    preselectedRepeatSchedule === 5
  );
  const {
    repentanceDropdownItems,
    setRepentanceDropdownItems,
    setSelectedDropdownItems,
  } = useRepentanceDropdown(preselectedRepeatSchedule);

  const closeRepentanceModal = () => {
    setIsCustomModalOpened(false);
  };

  const validateStartDate = (value: Date) =>
    !getValues(`${namePrefix}endDate`) ||
    new Date(value).getTime() <=
      new Date(getValues(`${namePrefix}endDate`)).getTime();

  const errorStartDate = (error: FieldError) => {
    if (!error) return "";
    if (error?.type === "required") {
      return t("Errors:input_field_required");
    }
    return t("Errors:start_greater_end_date");
  };

  const checkAllDayCheckbox = (value?: string, isStartTime = true) => {
    if (
      (isAllDay && isStartTime && value !== "00:00") ||
      (isAllDay && !isStartTime && value !== "23:59")
    ) {
      setIsAllDay(false);
    }
    if (
      (!isAllDay && isStartTime && value === "00:00" && endTime === "23:59") ||
      (!isAllDay && !isStartTime && value === "23:59" && startTime === "00:00")
    ) {
      setIsAllDay(true);
    }
  };

  const renderParentWrapper = (children: ReactNode) =>
    partOfForm ? (
      <>{children}</>
    ) : (
      <form
        ref={ref}
        onSubmit={handleSubmit(async (values: IScheduleItem) => {
          await onSubmit?.(values);
          setIsFormEditable(false);
        })}
      >
        {children}
      </form>
    );

  const renderContent = () => {
    if (isFormEditable) {
      return renderParentWrapper(
        <Column className={styles.scheduleItem} type="top" align="start">
          <Row type="fill">
            <Column type="fill" align="start">
              <Controller
                name={`${namePrefix}name`}
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:name")}
                    placeholder={t("Contacts:schedule_name")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Spacer size={16} />
              <Row type="fill" align="start">
                <Controller
                  name={`${namePrefix}startDate`}
                  control={control}
                  rules={{ required: true, validate: validateStartDate }}
                  render={({ field }) => (
                    <DatePicker
                      required
                      label={t("Contacts:date")}
                      minDate={new Date()}
                      value={
                        field.value ? moment(field.value).toDate() : undefined
                      }
                      onChange={(value) => {
                        setValue(
                          `${namePrefix}startDate`,
                          moment(value).format("YYYY-MM-DD")
                        );
                        trigger(`${namePrefix}startDate`);
                      }}
                      dateFormat={dateFormat.split("/").join("-")}
                      validationError={errorStartDate(
                        namePrefix
                          ? errors[namePrefix.replace(".", "")]?.startDate
                          : errors.startDate
                      )}
                    />
                  )}
                />
                <Spacer size={32} />
                <Checkbox
                  style={{ marginTop: "22px", flexShrink: 0 }}
                  label={t("Contacts:all_day")}
                  disabled={!isFormEditable}
                  checked={isAllDay}
                  onChange={() => {
                    if (isAllDay) {
                      setValue(`${namePrefix}startTime`, undefined);
                      setValue(`${namePrefix}endTime`, undefined);
                    } else {
                      setValue(`${namePrefix}startTime`, "00:00");
                      setValue(`${namePrefix}endTime`, "23:59");
                    }
                    trigger([`${namePrefix}startTime`, `${namePrefix}endTime`]);
                    setIsAllDay(!isAllDay);
                  }}
                />
                <Spacer size={32} />
                <Controller
                  name={`${namePrefix}startTime`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TimePicker
                      required
                      label={t("Contacts:start_time")}
                      placeholder={t(
                        "AlarmReceptions:edit_contact_label_starttime_placeholder"
                      )}
                      value={field.value}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        checkAllDayCheckbox(value);
                        field.onChange(value);
                        trigger(field.name);
                      }}
                      validationError={
                        (namePrefix
                          ? errors[namePrefix.replace(".", "")]?.startTime
                          : errors.startTime) &&
                        t("Errors:input_field_required")
                      }
                    />
                  )}
                />
                <div className={styles.slash} />
                <Controller
                  name={`${namePrefix}endTime`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TimePicker
                      required
                      label={t("Contacts:end_time")}
                      placeholder={t(
                        "AlarmReceptions:edit_contact_label_starttime_placeholder"
                      )}
                      value={field.value}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        checkAllDayCheckbox(value, false);
                        field.onChange(value);
                        trigger(field.name);
                      }}
                      validationError={
                        (namePrefix
                          ? errors[namePrefix.replace(".", "")]?.endTime
                          : errors.endTime) && t("Errors:input_field_required")
                      }
                    />
                  )}
                />
              </Row>
            </Column>
            <Column type="center" className={styles.buttonsWrapper}>
              <Row>
                {!partOfForm && (
                  <>
                    <Button
                      type="submit"
                      iconVariant="border-link"
                      variant="secondary"
                      image="check"
                      onClick={() => {
                        if (!isFormEditable) {
                          setIsFormEditable(true);
                        }
                      }}
                    />
                    <Spacer size={8} />
                  </>
                )}
                <Button
                  variant="secondary"
                  image="x"
                  onClick={() => {
                    if (partOfForm) {
                      onItemRemove?.();
                    } else if (defaultValues?.id) {
                      setIsFormEditable(false);
                      reset?.();
                      setShowCustomSelectedDays(
                        preselectedRepeatSchedule === 5
                      );
                      setRepentanceDropdownItems(
                        repentanceDropdownItems.map((item) => ({
                          ...item,
                          isSelected: item.id === preselectedRepeatSchedule,
                        }))
                      );
                    } else {
                      onItemRemove?.();
                    }
                  }}
                />
              </Row>
            </Column>
          </Row>
          <Spacer size={12} />
          <Row align="center">
            <Dropdown
              width={250}
              onSelectItem={(item) => {
                if (item.id !== 5) {
                  setValue(`${namePrefix}endDate`, null);
                  setShowCustomSelectedDays(false);
                }
                switch (item.id) {
                  case 0:
                    setValue(`${namePrefix}repeatDays`, [0, 1, 2, 3, 4, 5, 6]);
                    break;
                  case 1:
                    setValue(`${namePrefix}repeatDays`, []);
                    break;
                  case 2:
                    setValue(`${namePrefix}repeatDays`, [
                      getCurrentDayNumber(),
                    ]);
                    break;
                  case 3:
                    setValue(`${namePrefix}repeatDays`, [0, 1, 2, 3, 4]);
                    break;
                  case 4:
                    setValue(`${namePrefix}repeatDays`, [5, 6]);
                    break;
                  case 5:
                    setIsCustomModalOpened(true);
                    if (
                      repentanceDropdownItems.find((i) => i.isSelected)!.id !==
                      5
                    ) {
                      setValue(`${namePrefix}repeatDays`, []);
                    }
                    setShowCustomSelectedDays(true);
                    break;
                  default:
                    setShowCustomSelectedDays(false);
                    break;
                }
                setValue(
                  `${namePrefix}repeatType`,
                  item.id !== 1 ? "RepeatDays" : "NoRepeat"
                );
                setSelectedDropdownItems(+item.id);
              }}
              selectedItem={repentanceDropdownItems.find(
                (item) => item.isSelected
              )}
              items={repentanceDropdownItems}
              title={t("Contacts:repeat")}
              maxVisible={6}
            />
            {showCustomSelectedDays &&
              !!getValues(`${namePrefix}repeatDays`).length && (
                <CustomSelectedDays
                  repeatDays={repeatDays}
                  isFormEditable={isFormEditable}
                  endDateValue={getValues(`${namePrefix}endDate`)}
                />
              )}
          </Row>
        </Column>
      );
    }

    return (
      <Column className={styles.scheduleItem} type="top" align="start">
        <Row type="fill" className="w-100">
          <Column type="fill" align="start">
            {defaultValues?.[`${namePrefix}name`] && (
              <>
                <div className={styles.dataWrapper}>
                  <span>{defaultValues?.[`${namePrefix}name`]}</span>
                </div>
                <Spacer size={16} />
              </>
            )}

            <Row type="fill" align="end">
              <InputContainer
                label={<label htmlFor="date">{t("Contacts:date")}</label>}
                input={
                  <div className={styles.dataWrapper}>
                    <Icon name="calendar" size={24} />
                    <Spacer size={8} />
                    <span style={{ width: "max-content" }}>
                      {moment(defaultValues?.[`${namePrefix}startDate`]).format(
                        dateFormat.split("/").join("-")
                      )}
                    </span>
                  </div>
                }
              />
              <Spacer size={32} />
              <Checkbox
                style={{ flexShrink: 0 }}
                label={t("Contacts:all_day")}
                disabled={!isFormEditable}
                checked={isAllDay}
              />
              <Spacer size={32} />
              <InputContainer
                label={
                  <label htmlFor="between_times">
                    {t("Contacts:between_times")}
                  </label>
                }
                input={
                  <div className={styles.dataWrapper}>
                    <Icon name="clock" size={24} />
                    <Spacer size={8} />
                    <span>{defaultValues?.[`${namePrefix}startTime`]}</span>
                    <Spacer size={8} />
                    <div
                      className={classNames(styles.slash, styles.viewSlash)}
                    />
                    <Spacer size={8} />
                    <Icon name="clock" size={24} />
                    <Spacer size={8} />
                    <span>{defaultValues?.[`${namePrefix}endTime`]}</span>
                  </div>
                }
              />
            </Row>
            <Spacer size={12} />
            <Row align="center">
              <InputContainer
                style={{ width: "min-content" }}
                label={<label htmlFor="repeat">{t("Contacts:repeat")}</label>}
                input={
                  <>
                    {!showCustomSelectedDays ? (
                      <div
                        className={styles.dataWrapper}
                        style={{ width: "max-content" }}
                      >
                        <span>
                          {
                            repentanceDropdownItems.find((i) => i.isSelected)!
                              .name
                          }
                        </span>
                      </div>
                    ) : (
                      <CustomSelectedDays
                        repeatDays={repeatDays}
                        isFormEditable={isFormEditable}
                        endDateValue={getValues(`${namePrefix}endDate`)}
                      />
                    )}
                  </>
                }
              />
            </Row>
          </Column>
          <Column type="center" align="end">
            <Row>
              <Button
                iconVariant="border-link"
                variant="secondary"
                image="pencil"
                onClick={() => {
                  if (!isFormEditable) {
                    setIsFormEditable(true);
                  }
                }}
              />
              <Spacer size={8} />
              <Button
                variant="secondary"
                image="x"
                onClick={() => {
                  if (!partOfForm) {
                    onDelete?.(defaultValues.id);
                  }
                }}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    );
  };

  return (
    <>
      <PrimeModal
        withFooter
        withHeader
        isOpen={isCustomModalOpened}
        onClose={closeRepentanceModal}
        header={t("Contacts:custom_recurrence")}
        fixedWidth="848px"
        submitBtn={{
          text: t("Common:save"),
          onClick: () => {
            trigger(`${namePrefix}endDate`, `${namePrefix}startDate`).then(
              () => {
                if (
                  namePrefix
                    ? !errors[namePrefix.replace(".", "")]?.endDate
                    : !errors.endDate
                ) {
                  setIsCustomModalOpened(false);
                }
              }
            );
          },
        }}
        cancelBtn={{ text: t("Common:cancel"), onClick: closeRepentanceModal }}
      >
        <CustomRepentanceModal
          control={control}
          dateFormat={dateFormat}
          setValue={setValue}
          getValues={getValues}
          namePrefix={namePrefix}
          errors={errors}
          trigger={trigger}
          clearErrors={clearErrors}
        />
      </PrimeModal>
      {renderContent()}
    </>
  );
};
