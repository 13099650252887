import { ReactNode, useState } from "react";
import useTranslations from "../../core/i18n/useTranslations";
import { Spacer } from "../Layout/Layout";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { TransmitterModel } from "../../core/api/transmitters/types";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const AddSipCredentialsModal = ({
  onClose,
  isOpen,
  transmitter,
  renderSipInputs,
  registerCredentials,
}: {
  onClose: () => void;
  isOpen: boolean;
  transmitter?: TransmitterModel;
  renderSipInputs: () => ReactNode;
  registerCredentials: (id: number) => Promise<boolean | undefined>;
}) => {
  const t = useTranslations();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Transmitters:create_sip_credentials")}
      onClose={onClose}
      isOpen={isOpen}
      submitBtn={{
        text: t("Transmitters:create_credentials"),
        onClick: async () => {
          setIsLoading(true);
          try {
            const isResultFailed = await registerCredentials(transmitter?.id!);
            if (!isResultFailed) {
              onClose();
              notify({
                variant: "info",
                message: t("Messages:credentials_created"),
              });
            }
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          }
          setIsLoading(false);
        },
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
      loading={isLoading}
      fixedWidth={"960px"}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Transmitters:create_credentials_description")}
      </p>
      <Spacer size={16} />
      {renderSipInputs()}
    </PrimeModal>
  );
};
