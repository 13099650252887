import { Control, Controller, UseFormClearErrors } from "react-hook-form";
import { useState } from "react";
import moment from "moment";
import { Column, Spacer } from "../../../components/Layout/Layout";
import DaySelector from "../../../ui-lib/components/Tags/DaySelector";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import useTranslations from "../../../core/i18n/useTranslations";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";

export const CustomRepentanceModal = ({
  control,
  setValue,
  getValues,
  dateFormat,
  namePrefix,
  errors,
  trigger,
  clearErrors,
  startFromSunday = false,
  alignWithCron = false,
}: {
  control: Control<any, any>;
  dateFormat?: string;
  setValue: any;
  getValues: any;
  namePrefix: string;
  errors: any;
  trigger: any;
  clearErrors: UseFormClearErrors<any>;
  startFromSunday?: boolean;
  alignWithCron?: boolean;
}) => {
  const t = useTranslations();

  const [isEndDateDisabled, setIsEndDateDisabled] = useState<boolean>(
    !getValues(`${namePrefix}endDate`)
  );

  const radioGroupItems = [
    {
      id: 100,
      value: "0",
      text: t("Contacts:never"),
    },
    { id: 101, value: "1", text: t("Contacts:on") },
  ];

  const validateEndDate = (value: Date) =>
    !value ||
    !getValues(`${namePrefix}startDate`) ||
    new Date(value).getTime() >=
      new Date(getValues(`${namePrefix}startDate`)).getTime();

  return (
    <Column>
      <Controller
        name={`${namePrefix}repeatDays`}
        control={control}
        render={({ field }) => (
          <InputContainer
            label={<label htmlFor="repeat_on">{t("Contacts:repeat_on")}</label>}
            input={
              <DaySelector
                values={field.value}
                setValues={field.onChange}
                startFromSunday={startFromSunday}
                alignWithCron={alignWithCron}
              />
            }
          />
        )}
      />
      <Spacer size={16} />
      <InputContainer
        label={<label htmlFor="repeat_on">{t("Contacts:ends")}</label>}
        input={
          <RadioGroup
            name="endEnabled"
            fullWidth
            items={radioGroupItems}
            selectedValue={isEndDateDisabled ? "0" : "1"}
            onChange={(value) => {
              setIsEndDateDisabled(value === "0");
              if (value === "0") {
                setValue(`${namePrefix}endDate`, null);
                clearErrors(`${namePrefix}endDate`);
              }
            }}
          >
            <Controller
              name={`${namePrefix}endDate`}
              rules={{ validate: validateEndDate }}
              control={control}
              render={({ field }) => (
                <div style={{ width: "250px" }}>
                  <DatePicker
                    disabled={isEndDateDisabled}
                    minDate={new Date()}
                    value={
                      field.value ? moment(field.value).toDate() : undefined
                    }
                    onChange={(value) => {
                      field.onChange(moment(value).format("YYYY-MM-DD"));
                      trigger(field.name);
                    }}
                    dateFormat={dateFormat?.split("/").join("-")}
                    validationError={
                      (namePrefix
                        ? errors[namePrefix.replace(".", "")]?.endDate
                        : errors.endDate) && t("Errors:start_greater_end_date")
                    }
                  />
                </div>
              )}
            />
          </RadioGroup>
        }
      />
    </Column>
  );
};
