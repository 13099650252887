import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import useTranslations from "../../core/i18n/useTranslations";

interface BatchInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  headerText?: string;
}

export const BatchInfoModal = ({
  isOpen,
  onClose,
  headerText,
}: BatchInfoModalProps) => {
  const t = useTranslations();

  return (
    <PrimeModal
      withFooter
      isOpen={isOpen}
      onClose={onClose}
      withHeader={true}
      header={headerText}
      modal={true}
      fixedWidth="576px"
      submitBtn={{
        text: t("Common:ok"),
        variant: "secondary",
        onClick: onClose,
      }}
    >
      <div style={{ padding: "16px 24px" }}>
        {t("Objects:background_work_in_progress")}
      </div>
    </PrimeModal>
  );
};
