import classNames from "classnames";
import {
  Column,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../../components/Layout/Layout";
import { CustomSettingsOptionalFields } from "./CustomSettingsOptionalFields";
import { Controller, useFormContext } from "react-hook-form";
import { IOrgIntegration } from "../../../../core/api/organizations/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Button from "../../../../ui-lib/components/Button/Button";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";

import styles from "./CustomSettingsWrapper.module.css";

export const CustomSettingsWrapper = ({
  settingIndex,
  removeCustomSetting,
}: {
  settingIndex: number;
  removeCustomSetting: (indexToRemove: number) => void;
}) => {
  const t = useTranslations();
  const {
    formState: { errors },
    control,
    clearErrors,
    unregister,
    trigger,
    watch,
  } = useFormContext<IOrgIntegration>();

  const removeFieldsFromForm = (type: string) => {
    switch (type) {
      case "Dynamic":
        unregister([
          `expectedUserSettings.${settingIndex}.regex`,
          `expectedUserSettings.${settingIndex}.selectableEnums`,
          `expectedUserSettings.${settingIndex}.maximum`,
          `expectedUserSettings.${settingIndex}.minimum`,
        ]);
        break;
      case "Int":
        unregister([
          `expectedUserSettings.${settingIndex}.regex`,
          `expectedUserSettings.${settingIndex}.selectableEnums`,
        ]);
        break;
      case "String":
        unregister([
          `expectedUserSettings.${settingIndex}.maximum`,
          `expectedUserSettings.${settingIndex}.minimum`,
          `expectedUserSettings.${settingIndex}.selectableEnums`,
        ]);
        break;
      case "Enum":
        unregister([
          `expectedUserSettings.${settingIndex}.maximum`,
          `expectedUserSettings.${settingIndex}.minimum`,
          `expectedUserSettings.${settingIndex}.regex`,
        ]);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={classNames({
        [styles.customSettingsContainer]: true,
      })}
    >
      <Column type="top" align="fill" className={styles.customSettingsFields}>
        <Row align="start">
          <Controller
            control={control}
            name={`expectedUserSettings.${settingIndex}.parameterName`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                label={t("Organizations:custom_setting_name_label")}
                placeholder={t("Organizations:custom_setting_name_placehoder")}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  trigger(field.name);
                }}
                validationError={
                  errors.expectedUserSettings?.[settingIndex]?.parameterName &&
                  t("Errors:input_field_required")
                }
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            control={control}
            name={`expectedUserSettings.${settingIndex}.displayName`}
            rules={{ required: true }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                label={t("Organizations:displayName")}
                placeholder={t("Organizations:displayName_placeholder")}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  trigger(field.name);
                }}
                validationError={
                  errors.expectedUserSettings?.[settingIndex]?.displayName &&
                  t("Errors:input_field_required")
                }
              />
            )}
          />
        </Row>
        <Spacer size={16} />
        <TwoColGrid>
          <Controller
            control={control}
            name={`expectedUserSettings.${settingIndex}.settingType`}
            render={({ field }) => {
              const settingTypes = [
                {
                  id: "Int",
                  name: "Number",
                  isSelected: field.value === "Int",
                },
                {
                  id: "Dynamic",
                  name: "Dynamic",
                  isSelected: field.value === "Dynamic",
                },
                {
                  id: "String",
                  name: "String",
                  isSelected: field.value === "String",
                },
                {
                  id: "Enum",
                  name: "Enum",
                  isSelected: field.value === "Enum",
                },
              ];
              return (
                <Dropdown
                  title={t("Organizations:setting_type_label")}
                  items={settingTypes}
                  selectedItem={settingTypes.find((i) => i.isSelected)}
                  onSelectItem={(i) => {
                    field.onChange(i.id as string);
                    removeFieldsFromForm(i.id as string);
                  }}
                />
              );
            }}
          />
        </TwoColGrid>
        <Spacer size={16} />
        <CustomSettingsOptionalFields
          settingType={
            watch(`expectedUserSettings.${settingIndex}.settingType`) ?? "Int"
          }
          settingIndex={settingIndex}
        />
        <Spacer size={12} />
        <Controller
          control={control}
          name={`expectedUserSettings.${settingIndex}.required`}
          render={({ field }) => (
            <Checkbox
              style={{ paddingLeft: 0 }}
              label={t("Common:required")}
              checked={field.value}
              onChange={field.onChange}
              htmlFor={`expectedUserSettings.${settingIndex}.required`}
            />
          )}
        />
      </Column>
      <Spacer size={32} />
      <Column>
        <Button
          image="x"
          variant="secondary"
          onClick={() => {
            removeCustomSetting(settingIndex);
            clearErrors("expectedUserSettings");
          }}
        />
      </Column>
    </div>
  );
};
