import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { Column } from "../../components/Layout/Layout";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import DeleteTransmitterModal from "../../components/Transmitters/DeleteTransmitterModal";
import { TransmitterModel } from "../../core/api/transmitters/types";
import { useStoredTableState } from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { PAGE_ID as parentId } from "./Transmitters";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { SharedTransmitterProvider } from "./SharedTransmitterContext/SharedTransmitterContext";
import useSharedTransmitter from "./SharedTransmitterContext/useSharedTransmitter";
import { TransmitterGeneral } from "./TransmitterDetails/TransmitterGeneral";
import { TransmitterLogs } from "./TransmitterDetails/TransmitterLogs";

import styles from "../PagesLayout.module.css";

const parentPath = "/adminportal/transmitters";

const SubItems = (url: string, baseUrl?: string) => {
  const user = useUser();
  const show = user.config?.show ?? [];

  const sub = [
    {
      key: "Menu:Transmitter_details",
      to: baseUrl ?? url,
    },
  ];

  const access = {
    Logs: show.includes("TransmitterUpdateLogs"),
  };

  if (access.Logs) {
    sub.push({
      key: "Menu:Transmitter_logs",
      to: `${baseUrl ?? url}/logs`,
    });
  }

  return {
    sub,
    access,
  };
};

const TransmitterDetailsWrapper = ({
  data,
  children,
  editTransmitterRef,
}: {
  data?: TransmitterModel;
  children: ReactNode;
  editTransmitterRef?: MutableRefObject<any>;
}) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useUser();
  const { transmitterData, fetchTransmitter } = useSharedTransmitter();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  const contextMenuRef = useRef<OverlayPanel>(null);

  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);

  return (
    <>
      <PageHeader title={data?.identifier} icon="rss">
        <Button
          text={t("Common:save")}
          onClick={async () => {
            onSave();
            const res = await editTransmitterRef?.current?.submitForm();
            if (res?.status) {
              notify({
                message: t("Transmitters:transmitter_edit_success"),
              });
              fetchTransmitter();
            }
          }}
          loading={isSaving}
          disabled={!isDirty || isSaving || !canSave}
        />
        {user.config?.show.includes("DeleteTransmitters") &&
          !transmitterData?.userId && (
            <>
              <Button
                className="ml-8"
                type="button"
                variant="secondary"
                image="dots-horizontal"
                text={t("Common:other")}
                onClick={(e) => contextMenuRef.current?.toggle(e)}
              />
              <OverlayPanel ref={contextMenuRef} className="actions-overlay">
                <PopupContextMenuLinkButton
                  icon="trash"
                  text={t("Transmitters:button_delete_transmitter")}
                  onClick={() => {
                    setDeletionModalOpen(true);
                  }}
                />
                <DeleteTransmitterModal
                  onClose={() => {
                    setDeletionModalOpen(false);
                  }}
                  text={t("Transmitters:delete_transmitter_confirm")}
                  isOpen={deletionModalOpen}
                  modalTitle={t("Transmitters:delete_transmitter")}
                  name={transmitterData?.identifier ?? ""}
                  onDelete={(status: boolean) => {
                    if (status) {
                      history.replace(backUrl);
                    }
                  }}
                  transmitterIds={[id]}
                />
              </OverlayPanel>
            </>
          )}
      </PageHeader>
      <Column className={styles.content} align="fill" type="top">
        {children}
      </Column>
    </>
  );
};

const TransmitterDetails = ({
  access,
}: {
  access: {
    Logs: boolean;
  };
}) => {
  const { path } = useRouteMatch();
  const { transmitterData } = useSharedTransmitter();
  const { id } = useParams<{ id: string }>();
  const editTransmitterRef = useRef<any>(null);

  return (
    <Switch>
      {access.Logs && (
        <Route path={`${path}/logs`}>
          <TransmitterDetailsWrapper data={transmitterData}>
            <TransmitterLogs transmitterId={id} />
          </TransmitterDetailsWrapper>
        </Route>
      )}
      <Route path={path}>
        <TransmitterDetailsWrapper
          data={transmitterData}
          editTransmitterRef={editTransmitterRef}
        >
          <TransmitterGeneral editTransmitterRef={editTransmitterRef} />
        </TransmitterDetailsWrapper>
      </Route>
    </Switch>
  );
};

const TransmitterDetailsLoader = () => {
  const t = useTranslations();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentId);
  const { isLoading, isError } = useSharedTransmitter();

  const { sub, access } = SubItems(url);

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  useEffect(() => {
    const nav = {
      menu: sub,
      backUrl: tableState ? `${parentPath}${tableState}` : parentPath,
      backText: t("Menu:transmitter"),
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return <TransmitterDetails access={access} />;
};

const TransmitterDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <SharedTransmitterProvider transmitterId={id}>
      <SaveObjectProvider>
        <TransmitterDetailsLoader />
      </SaveObjectProvider>
    </SharedTransmitterProvider>
  );
};
export default TransmitterDetailsContainer;
