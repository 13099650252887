import { useHistory } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { getAdministrators } from "../../core/api/administrators/administrators";
import { getSellers } from "../../core/api/sellers/sellers";
import { AdministratorMeta } from "../../core/api/administrators/types";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import { DeleteAdministratorModal } from "./AdministratorDetails/DeleteAdministratorModal";
import { AdministratorsTable } from "./AdministratorsTable";
import useUser from "../../core/user/useUser";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import { AddAdministrator } from "./AddAdministrator/AddAdministrator";
import { getResponseCenters } from "../../core/api/responsecenters/responsecenters";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { notifyApiErrors } from "../../core/helpers/helpers";
import { api as reportApi } from "../../core/api/reports/reports";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IItemProp } from "../../ui-lib/components/Dropdown/DropdownItem";
import { useFiltersByAdminType } from "../../core/hooks/filters/useFiltersByAdminType";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { IResellerType } from "../../core/api/sellers/types";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

import styles from "../PagesLayout.module.css";

export const PAGE_ID = "adminportal/administrators";

const Administrators = () => {
  const t = useTranslations();
  const pageState = usePageState();
  const user = useUser();
  const viserPermission = user.config?.show.includes("SensioHideFields");
  storeTableState(PAGE_ID);
  const history = useHistory();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);
  const deleteModalRef = useRef<ModalActions>(null);

  const isSuperAdmin = user.config?.show.includes("SkygdSuperAdmin");
  const isUserRespondent = user.data?.type === "respondent";
  const isUserReseller = user.data?.type === "seller";

  const [tableSettings, setTableSettings] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter(
    searchState ?? {
      page: 1,
      pageSize: 10,
    }
  );
  const { FiltersByAdminType, setFiltersByAdminType, resetFiltersByAdminType } =
    useFiltersByAdminType(searchState);

  const [tableData, setTableData] = useState<AdministratorMeta[]>([]);
  const [sellers, setSellers] = useState<IItemProp[]>([]);
  const [selected, setSelected] = useState<AdministratorMeta>();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [arcList, setArcList] = useState<IItemProp[]>([]);
  const [searchParam, setSearchParam] = useState<string>(
    searchStringParser(searchState?.q) ?? ""
  );
  const [arcLoading, setArcLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<number | undefined>(
    FiltersByAdminType?.orgId ? +FiltersByAdminType.orgId : undefined
  );

  const resetSettingsData = () => {
    resetPageFilter();
    setTableData([]);
  };

  const fetchAlarmReceptions = async () => {
    const query = {
      privateOnly: "true",
      page: 1,
      pageSize: 1000,
      organizationId: selectedOrg,
    };
    try {
      setArcLoading(true);
      const data = await getResponseCenters(user.authenticatedRequest, query);
      const alarmTreeItems = data.data.map((item) => ({
        id: item.id!,
        name: item.name!,
        isSelected: item.id === Number(FiltersByAdminType.responsecenter),
      }));
      setArcList(alarmTreeItems);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setArcLoading(false);
    }
  };

  const { data: adminView, isLoading } = getAdministrators({
    page: pageFilter.page,
    pageSize: pageFilter.pageSize,
    q: tableSettings.q,
    organization:
      FiltersByAdminType.adminType === "Admin"
        ? FiltersByAdminType?.orgId
        : undefined,
    adminType: FiltersByAdminType.adminType,
    seller: FiltersByAdminType.sellerId,
    responsecenter: FiltersByAdminType.responsecenter,
  });

  const adminTypeOptions: IItemProp[] = [
    {
      id: "Respondent",
      name: t("Common:respondent"),
      isSelected: FiltersByAdminType.adminType === "Respondent",
    },
  ];
  if (!isUserRespondent) {
    adminTypeOptions.unshift({
      id: "Admin",
      name: t("Common:admin"),
      isSelected: FiltersByAdminType.adminType === "Admin",
    });
  }
  if (isSuperAdmin) {
    adminTypeOptions.unshift({
      id: "Seller",
      name: t("Common:seller"),
      isSelected: FiltersByAdminType.adminType === "Seller",
    });
  }

  useEffect(() => {
    if (
      !arcList.length &&
      FiltersByAdminType.adminType === "Respondent" &&
      selectedOrg
    ) {
      fetchAlarmReceptions();
    }
  }, [FiltersByAdminType.adminType, selectedOrg]);

  const onDone = () => {
    setIsExportLoading(false);
    setIsExportModalOpen(false);
  };

  const onError = (error: any) => {
    setIsExportLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { downloadReport } = reportApi(
    user.authenticatedRequest,
    onDone,
    onError
  );

  const { data: organisations, isLoading: orgsLoading } =
    useOrganizationsTree();

  const onClear = () => {
    setSearchParam("");
    setTableSettings({ q: "" });
    resetFiltersByAdminType();
    resetPageFilter();
    setTableData([]);
    setArcList([]);
    setSelectedOrg(undefined);
  };

  const fetchSellers = async () => {
    try {
      const { data } = await getSellers(user.authenticatedRequest);
      if (data) {
        const sellersItems = data.map((seller: IResellerType) => ({
          id: seller.id,
          name: seller.name,
          isSelected: seller.id.toString() === FiltersByAdminType.sellerId,
        }));
        setSellers(sellersItems);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  useEffect(() => {
    setTableData(adminView?.admins);
  }, [adminView?.admins]);

  useEffect(() => {
    if (isSuperAdmin || isUserReseller) {
      fetchSellers();
    }
  }, [user.data?.type, user.config]);

  const tryDownloadReport = async () => {
    setIsExportLoading(true);
    await downloadReport({
      reportId: 7,
      reportArguments: {
        runMode: "BySearchCriteria",
        entityIds: [],
        organizationIds: Number(FiltersByAdminType?.orgId)
          ? [Number(FiltersByAdminType?.orgId)]
          : [],
        searchText: tableSettings?.q ?? "",
        adminType: FiltersByAdminType?.adminType,
        responsecenterIds: Number(FiltersByAdminType?.responsecenter)
          ? [Number(FiltersByAdminType?.responsecenter)]
          : [],
      },
    });
  };

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:administrators"),
      })
    );
  }, []);

  const sellersTree = sellers.map(
    (s): ITreeData => ({
      key: s.id,
      label: s.name,
      data: s.name,
    })
  );

  const selectedAdminType = adminTypeOptions.find((type) => type.isSelected);

  const isFiltersApplied =
    !!FiltersByAdminType.orgId ||
    !!FiltersByAdminType.adminType ||
    !!FiltersByAdminType.sellerId ||
    !!FiltersByAdminType.responsecenter ||
    !!tableSettings.q.length;

  const updateList = (
    arr: IItemProp[],
    setter: Dispatch<SetStateAction<IItemProp[]>>,
    id?: string | number
  ) => {
    const updated = arr.map((el) => {
      el.isSelected = id ? el.id === id : false;
      return el;
    });
    setter(updated);
  };

  const getSelectedTreeItem = () => {
    if (FiltersByAdminType.adminType === "Admin") {
      return Number(FiltersByAdminType.orgId);
    }

    if (FiltersByAdminType.adminType === "Respondent") {
      return selectedOrg;
    }
    return undefined;
  };

  const renderAdminTypeFilter = () => (
    <>
      <Dropdown
        withClearItem
        placeholder={`--${t("Administrator:type")}--`}
        width={250}
        selectedItem={selectedAdminType}
        items={adminTypeOptions}
        isLoading={!user.data?.type}
        disabled={!user.data?.type}
        onSelectItem={(item) => {
          if (item.id.toString() === FiltersByAdminType.adminType) return;
          if (item.id === -1) {
            if (FiltersByAdminType.adminType) {
              resetSettingsData();
            }
            resetFiltersByAdminType();
            return;
          }
          // Clear all previosly selected filters
          setSelectedOrg(undefined);
          setArcList([]);
          resetSettingsData();
          resetFiltersByAdminType();
          updateList(sellers, setSellers);
          // Set new filter value
          setFiltersByAdminType({ adminType: item.id.toString() });
        }}
      />
      <Spacer size={8} />
      {FiltersByAdminType.adminType === "Seller" && (
        <Dropdown
          withClearItem
          maxVisible={9}
          width={250}
          placeholder={`--${t("Common:seller")}--`}
          items={sellers}
          selectedItem={sellers.find((i) => i.isSelected)}
          onSelectItem={(item) => {
            if (item.id.toString() === FiltersByAdminType.sellerId) return;
            if (+item.id === -1) {
              if (FiltersByAdminType.sellerId) {
                resetSettingsData();
              }
              updateList(sellers, setSellers, item.id);
              setFiltersByAdminType({
                ...FiltersByAdminType,
                sellerId: undefined,
              });
              return;
            }
            resetSettingsData();
            setFiltersByAdminType({
              ...FiltersByAdminType,
              sellerId: +item.id === -1 ? undefined : item.id.toString(),
            });
            updateList(sellers, setSellers, item.id);
          }}
          disabled={!sellers.length}
        />
      )}
      {(FiltersByAdminType.adminType === "Admin" ||
        FiltersByAdminType.adminType === "Respondent") && (
        <Tree
          showClearOption
          className={styles.treeFilter}
          placeholder={`--${t("Common:organisation")}--`}
          items={organisations}
          selectedTreeItem={getSelectedTreeItem()}
          onSelectItem={(treeItem) => {
            if (FiltersByAdminType.adminType === "Respondent") {
              setArcList([]);
              setSelectedOrg(treeItem?.key);
              if (!treeItem) {
                setFiltersByAdminType({
                  responsecenter: undefined,
                });
              }
            }
            if (FiltersByAdminType.adminType === "Admin") {
              resetSettingsData();
            }
            setFiltersByAdminType({
              orgId: treeItem?.key.toString(),
            });
          }}
          disabled={!organisations.length}
        />
      )}
      {FiltersByAdminType.adminType === "Respondent" && (
        <>
          <Spacer size={8} />
          <Dropdown
            withClearItem
            withFilter
            maxVisible={9}
            bodyWidth={350}
            width={250}
            placeholder={`--${t("Common:labels_alarm_reception")}--`}
            items={arcList}
            disabled={arcLoading || !arcList.length}
            isLoading={arcLoading}
            selectedItem={arcList.find((arc) => arc.isSelected)}
            onSelectItem={(item) => {
              if (item.id.toString() === FiltersByAdminType.responsecenter)
                return;
              if (+item.id === -1) {
                if (FiltersByAdminType.responsecenter) {
                  resetSettingsData();
                }
                updateList(arcList, setArcList, item.id);
                setFiltersByAdminType({
                  ...FiltersByAdminType,
                  responsecenter: undefined,
                });
                return;
              }
              resetSettingsData();
              setFiltersByAdminType({
                ...FiltersByAdminType,
                responsecenter: item.id.toString(),
              });
              updateList(arcList, setArcList, item.id);
            }}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <PrimeModal
        withFooter
        withHeader
        fixedWidth="576px"
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        header={t("Common:export")}
        loading={isExportLoading}
        submitBtn={{ text: t("Common:export"), onClick: tryDownloadReport }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => setIsExportModalOpen(false),
        }}
      >
        <p>{t("Administrator:export_modal_description")}</p>
      </PrimeModal>
      <PageHeader title={t("Menu:administrators")} icon={pageState.pageIcon}>
        {!isUserRespondent && !viserPermission && (
          <AddAdministrator
            variant="primary"
            organisations={organisations}
            isLoading={orgsLoading}
            sellers={sellersTree}
            onSubmit={(adminId: number) => {
              history.push(`/${PAGE_ID}/${adminId}`);
            }}
          />
        )}
        <>
          {!!user.config?.show.find(
            (key) =>
              key === "ListRoleTemplates" || key === "ListAllRoleTemplates"
          ) && (
            <Button
              className="ml-8"
              loading={arcLoading}
              disabled={arcLoading}
              variant="secondary"
              text={t("Administrator:manage_role_templates")}
              onClick={() => history.push("/adminportal/roletemplates")}
            />
          )}
        </>
        {user.config?.show?.includes("Reports") && !viserPermission && (
          <Button
            className="ml-8"
            variant="secondary"
            text={t("Common:export")}
            onClick={() => setIsExportModalOpen(true)}
          />
        )}
      </PageHeader>

      <Column type="top" className={styles.content}>
        <Row className={styles.settingsRow}>
          <SearchInput
            placeholder={t("Administrator:administrators_search_placeholder")}
            limit={1}
            value={searchParam}
            onChange={(value) => {
              if (searchParam !== value) {
                resetSettingsData();
              }
              setSearchParam(value);
              setTableSettings({ q: value });
            }}
          />
        </Row>

        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />
        <Row type="left" align="start" style={{ width: "100%", flex: "unset" }}>
          {renderAdminTypeFilter()}
          <Spacer size={8} />

          <ClearFilter
            text={t("Common:labels_clear_all_filters")}
            onClearClick={onClear}
            filtersToWatch={[searchParam, FiltersByAdminType.adminType]}
          />
        </Row>
        <Spacer size={16} />

        {isLoading ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <AdministratorsTable
            data={tableData}
            adminFiltersApplied={isFiltersApplied}
            onClear={onClear}
            totalRoleAmount={adminView?.totalRoleAmount}
            sellers={sellersTree}
            pageSettings={pageFilter}
            onPageSettingsChange={setPageFilter}
            organisations={organisations}
            onStartDelete={(admin: AdministratorMeta) => {
              setSelected(admin);
              deleteModalRef?.current?.open();
            }}
          />
        )}
      </Column>

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAdministratorModal
            administratorId={
              (!!selected?.identityId && selected?.identityId) ||
              (!!selected?.roles[0].id && selected?.roles[0].id) ||
              ""
            }
            identity={!!selected?.identityId}
            onClose={close}
            onDelete={() => {
              close();
              setTableData((prev) => {
                if (selected?.identityId) {
                  return prev.filter(
                    (c) => c.identityId !== selected?.identityId
                  );
                }
                return prev.filter(
                  (c) => c.roles[0].id !== selected?.roles[0].id
                );
              });
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Administrators;
