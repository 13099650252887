import { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Button from "../../../ui-lib/components/Button/Button";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  IADGroup,
  IRolesTemplates,
  ITemplatesItemsProps,
} from "../../../core/api/adGroup/types";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { NestedOrganizationProps } from "../../../core/api/organizations/organizations";
import { RoleTemplateRow } from "./RoleTemplateRow";
import { updateADGroup } from "../../../core/api/adGroup/adGroup";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { ApiV2Error } from "../../../core/interfaces/Api";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateADGroup = ({
  onClose,
  isOpen,
  adGroupData,
  roleTemplatesData,
  organizationsData,
  organizationId,
  initUpdateTable,
  updateTableFunc,
}: {
  onClose: () => void;
  isOpen: boolean;
  adGroupData?: IADGroup;
  roleTemplatesData?: ITemplatesItemsProps[];
  organizationsData?: NestedOrganizationProps[];
  organizationId: number | string;
  initUpdateTable: number;
  updateTableFunc: Dispatch<number>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [groupIdValid, setGroupIdValid] = useState<boolean>(
    !!adGroupData?.adGroupId
  );
  const [isTemplatesChanging, setIsTemplatesChanging] = useState(false);
  const [groupNameValid, setGroupNameValid] = useState<boolean>(
    !!adGroupData?.adGroupName
  );
  const { authenticatedRequest } = useUser();
  const t = useTranslations();

  const getFormModel = () => {
    if (!adGroupData) {
      return {
        adGroupId: "",
        adGroupName: "",
        roleTemplates: [
          {
            templateId: "",
          },
        ],
      };
    }
    return {
      adGroupId: adGroupData.adGroupId,
      adGroupName: adGroupData.adGroupName,
      roleTemplates: [
        ...adGroupData.roleMappings.map((role) => {
          const orgNameToUse =
            role.type === "customer" ? "organizationId" : "responseCenterId";
          const data = {
            templateId: role.roleTemplateId,
          } as any;
          data[orgNameToUse] = role.belongsToId;
          return data;
        }),
      ],
    };
  };

  const initialFormState = getFormModel();
  const {
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm<any>({
    defaultValues: initialFormState,
  });

  useEffect(() => {
    if (isOpen) {
      const formModel = getFormModel();
      reset(formModel, { keepDefaultValues: false });
      setGroupIdValid(!!adGroupData);
      setGroupNameValid(!!adGroupData);
    }
  }, [isOpen, adGroupData]);

  const checkTemplatesState = () => {
    let success = true;

    if (!getValues("roleTemplates")) {
      return false;
    }

    getValues("roleTemplates").forEach((role: IRolesTemplates) => {
      if (
        !(role.templateId && role.responseCenterId) &&
        !(role.templateId && role.organizationId)
      ) {
        success = false;
      }
      if (!role.templateId && !role.responseCenterId && !role.organizationId) {
        success = true;
      }
    });
    return success;
  };

  const processADGroupData = async () => {
    try {
      const valuesToSend = getValues();
      valuesToSend.roleTemplates = valuesToSend.roleTemplates.filter(
        (role: any) => role.templateId
      );
      setIsLoading(true);
      await updateADGroup(
        organizationId,
        valuesToSend,
        authenticatedRequest,
        !!adGroupData
      );
      onClose();
      notify({
        message: adGroupData
          ? t("Organizations:ad_group_edited")
          : t("Organizations:ad_group_created"),
      });
      updateTableFunc(initUpdateTable + 1);
    } catch (err: any) {
      const errorText = (err[0] as ApiV2Error).errorMessage;
      notify({
        variant: "error",
        message: t(`Errors:${errorText}`),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkChanges = () => {
    const updatedValues = getValues();
    let formHasChanges = false;

    if (updatedValues.adGroupName !== initialFormState.adGroupName) {
      formHasChanges = true;
    }

    if (
      updatedValues?.roleTemplates?.length !==
      initialFormState?.roleTemplates?.length
    ) {
      formHasChanges = true;
    } else {
      updatedValues.roleTemplates.forEach(
        (group: IRolesTemplates, index: number) => {
          if (
            group.templateId !==
              initialFormState.roleTemplates[index].templateId ||
            group.responseCenterId !==
              initialFormState.roleTemplates[index].responseCenterId ||
            group.organizationId !==
              initialFormState.roleTemplates[index].organizationId
          ) {
            formHasChanges = true;
          }
        }
      );
    }

    return formHasChanges;
  };

  const removeTemplateFromForm = async (indexToRemove: number) => {
    setIsTemplatesChanging(true);
    const roleTemplatesValue = getValues("roleTemplates").filter(
      (temp: IRolesTemplates, index: number) => index !== indexToRemove
    );
    setValue("roleTemplates", roleTemplatesValue);
    await trigger("roleTemplates");
    setIsTemplatesChanging(false);
  };

  return (
    <PrimeModal
      withFooter
      withHeader
      onClose={onClose}
      isOpen={isOpen}
      loading={isLoading}
      header={`${
        adGroupData
          ? t("Organizations:ad_edit_mappings")
          : t("Organizations:ad_create_mappings")
      }`}
      fixedWidth="848px"
      submitBtn={{
        text: `${
          adGroupData
            ? t("Organizations:ad_edit_mappings_button")
            : t("Organizations:ad_create_mappings_button")
        }`,
        onClick: processADGroupData,
        disabled:
          isLoading ||
          !checkTemplatesState() ||
          !groupIdValid ||
          !groupNameValid ||
          !checkChanges(),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Organizations:ad_group_description")}
      </p>

      <Spacer size={32} />

      <Row type="left" align="start">
        <div style={{ width: "345px" }}>
          <Controller
            name="adGroupName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                css={{ flex: 1 }}
                label={t("Common:name")}
                placeholder={t("Organizations:ad_group_name_placeholder")}
                value={field.value}
                onChange={(event) => {
                  setGroupNameValid(event.target.value.length > 0);
                  field.onChange(event);
                  trigger("adGroupName");
                }}
                validationError={
                  errors.adGroupName && t("Errors:name_required")
                }
              />
            )}
          />
        </div>

        <Spacer size={32} />

        <div style={{ width: "345px" }}>
          <Controller
            name="adGroupId"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                css={{ flex: 1 }}
                label={t("Organizations:ad_group_id")}
                placeholder={t("Organizations:ad_group_id_placeholder")}
                value={field.value}
                onChange={(event) => {
                  setGroupIdValid(event.target.value.length > 0);
                  field.onChange(event);
                  trigger("adGroupId");
                }}
                validationError={errors.adGroupId && t("Errors:id_required")}
                disabled={!!adGroupData}
              />
            )}
          />
        </div>
      </Row>

      <Spacer size={16} />

      {getValues("roleTemplates") &&
        !isTemplatesChanging &&
        getValues("roleTemplates").map(
          (role: IRolesTemplates, index: number) => (
            <>
              <RoleTemplateRow
                index={index}
                role={role}
                organizationsData={organizationsData}
                generalTemplatesData={roleTemplatesData}
                getValues={getValues}
                setValue={setValue}
                trigger={trigger}
                removeTemplateFromForm={removeTemplateFromForm}
              />

              <Spacer size={8} />
            </>
          )
        )}

      <Spacer size={8} />

      <Button
        image="plus"
        variant="secondary"
        text={t("Organizations:add_role")}
        onClick={() => {
          const updatedTemplates = getValues("roleTemplates") ?? [];
          updatedTemplates.push({
            templateId: "",
          });
          setValue("roleTemplates", updatedTemplates);
          trigger("roleTemplates");
        }}
      />
    </PrimeModal>
  );
};
