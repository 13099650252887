import classnames from "classnames";
import { CSSProperties } from "react";
import styles from "./Panel.module.css";

export interface ExtendedPadding {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
export interface PanelProps {
  children: React.ReactNode;
  className?: string;
  padding?: ExtendedPadding;
  style?: CSSProperties;
}
export default function Panel({
  children,
  className,
  padding,
  style,
}: Readonly<PanelProps>) {
  let paddingDefinition = "24px";
  if (padding !== undefined) {
    paddingDefinition = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px `;
  }
  return (
    <div
      className={classnames(styles.container, className)}
      style={{ ...style, padding: paddingDefinition }}
    >
      {children}
    </div>
  );
}
