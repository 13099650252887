import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { IRetentionDetails } from "../../core/api/organizations/types";
import useTranslations from "../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../core/SaveObjectContext/hooks";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { Spacer, TwoColGrid } from "../Layout/Layout";
import Divider from "../../ui-lib/components/Divider/Divider";

export const RetentionForm = ({
  defaultValues,
  retentionOptions,
  onSubmit,
  formType = "organization",
}: {
  defaultValues?: IRetentionDetails;
  retentionOptions: IDropdownItem[];
  onSubmit: (values: IRetentionDetails) => Promise<void>;
  formType?: "organization" | "reseller";
}) => {
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
  } = useObjectForm<IRetentionDetails>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const retentionValidate = (
    data: number,
    watcher: number,
    message: string,
    fieldName: string
  ) => {
    // validate data retention fields
    if (fieldName === "alarmDataRetention") {
      // if retention is Unlimited or Not defined - fail validation
      if (data === 0 || data === -1)
        return t("Organizations:not_valid_for_field");
      // if data retention set "Unlimited" and watched field is not - refuse validation
      if (data !== 0 && watcher === 0) return message;
      // data retention have to be more than media retention
      if (data >= watcher) return true;
    }
    if (fieldName === "objectDataRetention") {
      // if set "Unlimited" - pass validation
      if (data === 0) return true;
      // if data retention set "Unlimited" and watched field is not - refuse validation
      if (data !== 0 && watcher === 0) return message;
      // data retention have to be more than media retention
      if (data >= watcher) return true;
    }
    // validate media retention fields
    if (
      fieldName === "alarmMediaRetention" ||
      fieldName === "objectMediaRetention"
    ) {
      // if watched field has value 0 - pass validation
      if (watcher === 0) return true;
      // if media retention set "Unlimited" and watched field is not - refuse validation
      if (data === 0 && watcher !== 0) return message;
      // media retention have to be less than data retention
      if (data <= watcher) return true;
    }
    // return error message
    return message;
  };

  const optionsToSelect = (currentSelectedId: number) => {
    let retentionOptionsToUse =
      currentSelectedId !== 0
        ? [...retentionOptions].slice(1)
        : retentionOptions;
    retentionOptionsToUse =
      currentSelectedId !== -1
        ? retentionOptionsToUse.slice(0, retentionOptionsToUse.length - 1)
        : retentionOptionsToUse;
    return retentionOptionsToUse.map((i) => ({
      ...i,
      isSelected: +i.id === currentSelectedId,
    }));
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
        reset();
      })}
    >
      {formType === "reseller" && (
        <>
          <h2>{t("Reseller:data_lake_retention")}</h2>
          <Spacer size={8} />
          <p>{t("Reseller:data_lake_retention_description")}</p>
          <Spacer size={16} />
          <TwoColGrid>
            <Controller
              name="alarmArchiveRetention"
              control={control}
              render={({ field }) => {
                const alarmArchiveRetention = optionsToSelect(
                  field.value ?? -1
                );
                return (
                  <Dropdown
                    className="dropdownItem"
                    selectedItem={alarmArchiveRetention.find(
                      (i) => i.isSelected
                    )}
                    title={t("Reseller:alarm_archive_retention")}
                    onSelectItem={(item) => {
                      setValue(field.name, +item.id);
                      trigger("logArchiveRetention");
                    }}
                    items={alarmArchiveRetention}
                  />
                );
              }}
            />
            <Controller
              name="logArchiveRetention"
              control={control}
              render={({ field }) => {
                const logArchiveRetention = optionsToSelect(field.value ?? -1);
                return (
                  <Dropdown
                    className="dropdownItem"
                    selectedItem={logArchiveRetention.find((i) => i.isSelected)}
                    title={t("Reseller:log_archive_retention")}
                    onSelectItem={(item) => {
                      setValue(field.name, +item.id);
                      trigger("logArchiveRetention");
                    }}
                    items={logArchiveRetention}
                  />
                );
              }}
            />
          </TwoColGrid>

          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
        </>
      )}

      <h2>{t("Organizations:alarm_data_retention")}</h2>
      <p>{t("Organizations:retention_time_for_alarm")}</p>
      <Spacer size={16} />
      <TwoColGrid>
        <Controller
          name="alarmDataRetention"
          control={control}
          rules={{
            validate: {
              organisationData: (value: number) =>
                retentionValidate(
                  value,
                  watch("alarmMediaRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:media_retention"
                  )}`,
                  "alarmDataRetention"
                ),
            },
          }}
          render={({ field }) => {
            const alarmRetentionData = optionsToSelect(field.value);
            return (
              <Dropdown
                className="dropdownItem"
                selectedItem={alarmRetentionData.find((i) => i.isSelected)}
                title={t("Organizations:alarm_data_retention")}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                  trigger(["alarmMediaRetention", "alarmDataRetention"]);
                }}
                items={alarmRetentionData}
                validationError={errors?.alarmDataRetention?.message}
              />
            );
          }}
        />
        <Controller
          name="alarmMediaRetention"
          control={control}
          rules={{
            validate: {
              organisationMedia: (value) =>
                retentionValidate(
                  value,
                  watch("alarmDataRetention"),
                  `${t("Organizations:should_be_less_or_equal")} ${t(
                    "Organizations:alarm_data_retention"
                  )}`,
                  "alarmMediaRetention"
                ),
            },
          }}
          render={({ field }) => {
            const alarmMediaRetention = optionsToSelect(field.value);
            return (
              <Dropdown
                className="dropdownItem"
                title={t("Organizations:media_retention")}
                selectedItem={alarmMediaRetention.find((i) => i.isSelected)}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                  trigger(["alarmMediaRetention", "alarmDataRetention"]);
                }}
                items={alarmMediaRetention}
                validationError={errors?.alarmMediaRetention?.message}
              />
            );
          }}
        />
      </TwoColGrid>

      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />

      <h2>{t("Organizations:object_retention")}</h2>
      <p>{t("Organizations:months_after_subscription_cancelled")}</p>
      <Spacer size={16} />
      <TwoColGrid style={{ rowGap: "16px" }}>
        <Controller
          name="alarmAfterObjectDeleteRetention"
          control={control}
          render={({ field }) => {
            const alarmAfterObjectDeleteRetention = optionsToSelect(
              field.value
            );
            return (
              <Dropdown
                className="dropdownItem"
                title={t("Organizations:static_data")}
                selectedItem={alarmAfterObjectDeleteRetention.find(
                  (i) => i.isSelected
                )}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                }}
                items={alarmAfterObjectDeleteRetention}
              />
            );
          }}
        />
        <Controller
          name="objectDataRetention"
          control={control}
          rules={{
            validate: {
              objectData: (value) =>
                retentionValidate(
                  value,
                  watch("objectMediaRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:media_data_associated_with_object"
                  )}`,
                  "objectDataRetention"
                ),
            },
          }}
          render={({ field }) => {
            const objectDataRetention = optionsToSelect(field.value);
            return (
              <Dropdown
                className="dropdownItem"
                title={t("Organizations:alarm_data_associated_with_object")}
                selectedItem={objectDataRetention.find((i) => i.isSelected)}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                  trigger(["objectDataRetention", "objectMediaRetention"]);
                }}
                items={objectDataRetention}
                validationError={errors?.objectDataRetention?.message}
              />
            );
          }}
        />
        <Controller
          name="objectMediaRetention"
          control={control}
          rules={{
            validate: {
              objectMedia: (value) =>
                retentionValidate(
                  value,
                  watch("objectDataRetention"),
                  `${t("Organizations:should_be_more_or_equal")} ${t(
                    "Organizations:alarm_data_associated_with_object"
                  )}`,
                  "objectMediaRetention"
                ),
            },
          }}
          render={({ field }) => {
            const objectMediaRetention = optionsToSelect(field.value);
            return (
              <Dropdown
                className="dropdownItem"
                title={t("Organizations:media_data_associated_with_object")}
                selectedItem={objectMediaRetention.find((i) => i.isSelected)}
                onSelectItem={(item) => {
                  setValue(field.name, +item.id);
                  trigger(["objectDataRetention", "objectMediaRetention"]);
                }}
                items={objectMediaRetention}
                validationError={errors?.objectMediaRetention?.message}
              />
            );
          }}
        />
      </TwoColGrid>
    </form>
  );
};
