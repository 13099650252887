import { AxiosInstance } from "axios";
import { IAutocompleteFieldsResponse } from "./types";

const getAtocompleteData = (authenticatedRequest: AxiosInstance, id: string) =>
  authenticatedRequest.get<IAutocompleteFieldsResponse>(
    `/api/v2/autocomplete/user/${id}`
  );

const getTransmittersAutocomplete = (
  authenticatedRequest: AxiosInstance,
  transmitterId: string
) =>
  authenticatedRequest.get<IAutocompleteFieldsResponse>(
    `/api/v2/autocomplete/transmitter/${transmitterId}`
  );

export { getAtocompleteData, getTransmittersAutocomplete };
