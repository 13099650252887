import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY,
  ESCAPE_KEY,
  TAB_KEY,
} from "../../../../core/constants/keyboard_keys";
import {
  IDropdownWithCheckboxes,
  useDropdownWithCheckboxHooks,
} from "../../../utils/dropdown-hook";
import DropdownBody from "../DropdownBody";
import Checkbox, { ICheckbox } from "../../Checkbox/Checkbox";
import {
  getDropdownBodyHeight,
  getRootPopup,
} from "../../../../core/helpers/dropdownHelper";
import Chips, { IChips } from "../../Chips/Chips";
import Icon from "../../Icon";
import useTranslations from "../../../../core/i18n/useTranslations";

import baseStyles from "../Dropdown.module.css";
import styles from "./style.module.css";

export default function DropdownWIthCheckboxes(
  props: Readonly<IDropdownWithCheckboxes>
) {
  const t = useTranslations();
  const {
    items,
    id,
    width,
    containerWidth,
    getSelectedItems,
    title,
    isOpen,
    setIsOpen,
    btnRef,
    setFocusedIndex,
    focusedIndex,
    container,
    withFilter,
    maxVisible,
  } = useDropdownWithCheckboxHooks(props, true);
  const [checkboxItems, setCheckboxItems] = useState<ICheckbox[]>(items ?? []);
  const [filtredItems, setFiltredItems] = useState<ICheckbox[]>(items ?? []);
  const [selectedItems, setSelectedItems] = useState<ICheckbox[]>([]);

  useEffect(() => {
    const selected = items.filter((sO) => sO.checked);
    setSelectedItems(selected);
  }, []);

  const onChange = (item: ICheckbox) => {
    const options = [...checkboxItems];
    options.forEach((i) => {
      if (i.id === item.id) i.checked = !i.checked;
    });
    setCheckboxItems(options);

    const selectedOptions = options.filter((sO) => sO.checked);

    setSelectedItems(selectedOptions); // set items for chips
    getSelectedItems(selectedOptions); // return selected items to parent fro api calls for example
  };

  const unCheckItem = (item: IChips, e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onChange({ ...item, checked: true } as ICheckbox);
  };

  const getChipsList = (): ReactNode | null => {
    return selectedItems.map((selectedItem, index) => {
      if (index > 2) return null;
      return (
        <Chips
          item={{
            id: selectedItem.id,
            label: selectedItem.label,
          }}
          key={selectedItem.id}
          onRemove={unCheckItem}
        />
      );
    });
  };

  const handleInputKeyDown = useCallback(
    (e: any) => {
      const { keyCode } = e;
      switch (keyCode) {
        case ARROW_UP_KEY:
          e.preventDefault();
          setFocusedIndex((prev) => {
            if (prev === 0) return checkboxItems.length - 1;
            return prev - 1;
          });
          break;
        case ARROW_DOWN_KEY:
        case TAB_KEY:
          e.preventDefault();
          setFocusedIndex((p) => (p + 1) % checkboxItems.length);
          break;
        case ESCAPE_KEY:
          setIsOpen(false);
          break;
        case ENTER_KEY:
          if (isOpen) onChange(checkboxItems[focusedIndex]);
          else setIsOpen(true);
          break;
        default:
          break;
      }
    },
    [isOpen, checkboxItems, focusedIndex]
  );

  const onFilter = (filter: string) => {
    const filtered = items.filter(
      (item) => item.label?.toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
    setFiltredItems(filtered);
  };

  const renderItems = () =>
    filtredItems.map((item: ICheckbox, index: number) => {
      const focused = focusedIndex === index;
      return (
        <Checkbox
          key={item.id}
          label={item.label}
          checked={item.checked}
          focused={focused}
          onChange={() => onChange(item)}
          fullWidth
          isInsideDropdown
        />
      );
    });

  return (
    <div
      id={id}
      className={styles.dropdownContainer}
      style={{ width: containerWidth }}
    >
      {title && <div className={styles.title}>{title}</div>}
      <button
        ref={btnRef}
        className={baseStyles.dropdownBtn}
        style={{ width }}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        onKeyDown={handleInputKeyDown}
      >
        <div className={styles.dropdownBtnItems}>
          {selectedItems.length === 0 ? (
            <div className={baseStyles.selectOption}>
              {props.placeholder
                ? props.placeholder
                : t("Common:select_option")}
            </div>
          ) : (
            <div className={styles.chipsList}>{getChipsList()}</div>
          )}
          {selectedItems.length > 3 && (
            <div className={styles.moreItems}>+{selectedItems.length - 3}</div>
          )}
        </div>

        {isOpen ? (
          <Icon name="chevron-up" size={14} />
        ) : (
          <Icon name="chevron-down" size={14} />
        )}
      </button>
      {isOpen &&
        ReactDOM.createPortal(
          <DropdownBody
            withFilter={withFilter}
            onFilter={onFilter}
            elem={btnRef.current}
            innerRef={container}
            onClose={() => setIsOpen(false)}
            dropDownHeight={getDropdownBodyHeight(
              items.length,
              window.innerHeight <= 600 ? 6 : maxVisible,
              withFilter
            )}
          >
            {renderItems()}
          </DropdownBody>,
          getRootPopup()
        )}
    </div>
  );
}
