import { getAllEnumEntries } from "enum-for";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { saveTransmitter } from "../../core/api/transmitters/transmitters";
import {
  EditTransmitterData,
  TransmitterLog,
  TransmitterModel,
} from "../../core/api/transmitters/types";
import { TransmitterLogType } from "../../core/enumerations/enumerations";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import { Column, Row, Spacer } from "../Layout/Layout";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const CreateTransmitterEventModal = ({
  onSubmit,
  onClose,
  transmitter,
  triggerText,
}: {
  onSubmit?: () => void;
  onClose?: () => void;
  transmitter: TransmitterModel;
  triggerText?: string;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, data: userData } = useUser();
  const {
    control,
    formState: { isSubmitting, errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = useForm<TransmitterLog>({
    defaultValues: {
      logType: 0,
    },
  });
  const [isCreateEventOpen, setIsCreateEventOpen] = useState(false);
  const active = useRef(false);
  const [selectedLogType, setSelectedLogType] = useState<string | undefined>();
  const [logText, setLogText] = useState<string | undefined>();
  const [transmitterLogTypes, setTransmitterLogTypes] = useState<
    IDropdownItem[]
  >([]);

  useEffect(() => {
    const newTransmitterLogTypes: IDropdownItem[] = [];
    getAllEnumEntries(TransmitterLogType).forEach((val) => {
      newTransmitterLogTypes.push({
        id: val[1].toString(),
        name: t(`Transmitters:TransmitterLogType_${val[1]}`),
        isSelected: false,
      });
    });
    // prefill the log type
    setTransmitterLogTypes(newTransmitterLogTypes);
    setSelectedLogType(undefined);
    trigger();
  }, []);

  const getPreviewText = (): string => {
    let text = "";
    if (selectedLogType) {
      text =
        selectedLogType === "0"
          ? logText ?? ""
          : t(`Transmitters:TransmitterLogType_${selectedLogType}`);
    }
    return `${userData?.username} ${text}`;
  };
  const closeModal = () => {
    setIsCreateEventOpen(false);
    reset();
    onClose?.();
    setLogText("");
    setSelectedLogType(undefined);
    transmitterLogTypes.forEach((type) => {
      type.isSelected = false;
    });
  };
  const onInternalSubmit = async (formData: TransmitterLog) => {
    const warrenty = transmitter.warrenty
      ? moment(transmitter.warrenty).toDate()
      : undefined;
    const leasingExpires = transmitter.leasingExpires
      ? moment(transmitter.leasingExpires).toDate()
      : undefined;
    const transmitterEdit: EditTransmitterData = {
      ...transmitter,
      leasingExpires,
      missing: !!(
        transmitter.missing && transmitter.missing.toLowerCase() === "true"
      ),
      warrenty,
      logType: formData.logType,
      logText: formData.logType === 0 ? formData.logText : "",
    };
    try {
      await saveTransmitter(transmitterEdit, authenticatedRequest);
      onSubmit?.();
      closeModal();
      notify({
        message: t("Transmitters:labels_new_event"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };
  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  return (
    <>
      <Button
        variant="secondary"
        image="plus"
        text={triggerText ?? t("Transmitters:eventlog_table_add_new")}
        onClick={() => {
          setIsCreateEventOpen(true);
        }}
      />

      <PrimeModal
        isOpen={isCreateEventOpen}
        onClose={closeModal}
        withHeader
        header={t("Transmitters:create_event_modal_title")}
        withFooter
        submitBtn={{
          text: t("Common:label_create"),
          variant: "primary",
          onClick: async () => {
            await handleSubmit(onInternalSubmit)();
          },
          disabled: !isDirty || isSubmitting,
          loading: isSubmitting,
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: closeModal,
        }}
        loading={isSubmitting}
        fixedWidth="960px"
      >
        <form>
          <Spacer size={16} />
          <Column type="top" align="start">
            <p>{t("Transmitters:create_event_modal_description")}</p>
            <Spacer size={16} />
            <Row
              type="fill"
              align="fill"
              style={{ marginTop: "16px", alignSelf: "stretch" }}
            >
              <Controller
                name="logType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputContainer
                    required
                    label={
                      <label htmlFor="addevent_logtype">
                        {t("Transmitters:create_event_modal_logtype")}
                      </label>
                    }
                    input={
                      <Dropdown
                        id="addevent_logtype"
                        items={transmitterLogTypes}
                        placeholder={t("Locations:select_beacontype")}
                        selectedItem={transmitterLogTypes.find(
                          (r) => r.isSelected
                        )}
                        onSelectItem={(item) => {
                          setValue(field.name, +item.id, {
                            shouldDirty: true,
                          });
                          const newTransmitterLogTypes = [
                            ...transmitterLogTypes,
                          ];
                          newTransmitterLogTypes.forEach((r) => {
                            r.isSelected = r.id === item.id;
                          });
                          setTransmitterLogTypes(newTransmitterLogTypes);
                          setSelectedLogType(item.id as string);
                          trigger("logType");
                        }}
                        invalid={errors.logType !== undefined}
                        validationError={
                          errors.logType && t("Transmitters:validation_logtype")
                        }
                        maxVisible={6}
                      />
                    }
                  />
                )}
              />
            </Row>
            {selectedLogType === "0" && (
              <>
                <Spacer size={16} />
                <Row type="space" align="fill" style={{ alignSelf: "stretch" }}>
                  <Controller
                    name="logText"
                    control={control}
                    rules={{
                      required: true,
                      minLength: 1,
                    }}
                    render={({ field }) => (
                      <TextInput
                        required
                        inputRef={field.ref}
                        css={{ flex: 1 }}
                        placeholder={t("Transmitters:placeholder_logtext")}
                        label={t(
                          "Transmitters:create_event_modal_label_description"
                        )}
                        value={field.value}
                        onChange={(el) => {
                          setValue(field.name, el.target.value, {
                            shouldDirty: true,
                          });
                          setLogText(el.target.value);
                          trigger("logText");
                        }}
                        validationError={
                          errors.logText && t("Transmitters:validation_logtext")
                        }
                        onKeyDown={async (event) => {
                          if (event.key === "Enter") {
                            await handleSubmit(onInternalSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </Row>
              </>
            )}
            <Spacer size={16} />
            <Column type="top" align="fill">
              <label htmlFor="preview">
                {t("Transmitters:create_log_preview")}
              </label>
              <p id="preview">{getPreviewText()}</p>
            </Column>
            <Spacer size={16} />
          </Column>
        </form>
      </PrimeModal>
    </>
  );
};
