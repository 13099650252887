import moment from "moment";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
  getObjectStatus,
  getTransmitterSettings,
  saveTransmitterSetting,
} from "../../core/api/transmitters/transmitters";
import {
  ObjectStatus,
  TransmitterSetting,
} from "../../core/api/transmitters/types";
import { notifyApiErrors } from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import { Column, Row, Spacer } from "../Layout/Layout";
import {
  ConfigurationFilesActions,
  ConfigurationFilesModal,
} from "./ConfigurationFilesModal";
import Setting from "./Setting";
import styles from "./Transmitters.module.css";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { ResponsiveWrapper } from "../ResponsiveWrapper/ResponsiveWrapper";

export declare type ConfigureTransmitterActions = {
  open: (transmitterId: number, objectId: string) => void;
};
export interface ConfigureTransmitterProps {
  onSubmit?: () => void;
  onClose?: () => void;
}
export const ConfigureTransmitterModal = forwardRef<
  ConfigureTransmitterActions,
  ConfigureTransmitterProps
>((props: ConfigureTransmitterProps, ref) => {
  const { onSubmit, onClose } = props;
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isConfigureTransmitterOpen, setIsConfigureTransmitterOpen] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settings, setSettings] = useState<TransmitterSetting[] | undefined>();
  const updatedSettings = useRef<TransmitterSetting[]>([]);
  const transmitterId = useRef<number | null>(null);
  const objectStatus = useRef<ObjectStatus | null>(null);
  const objectId = useRef<string | null>(null);
  const configurationFilesRef = useRef<ConfigurationFilesActions>(null);
  const closeModal = () => {
    setIsConfigureTransmitterOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  const onInternalSubmit = async () => {
    let success = true;
    setIsSubmitting(true);
    for (const setting of updatedSettings.current) {
      try {
        await saveTransmitterSetting(
          transmitterId.current as number,
          {
            identifier: setting.identifier,
            value: setting.level === "U" ? setting.defaultValue : undefined,
          },
          authenticatedRequest
        );
      } catch (error: any) {
        success = false;
        notifyApiErrors(error.response?.data?.errors, error.response?.status);
      }
    }
    setIsSubmitting(false);
    if (success) {
      notify({
        message: t("Transmitters:device_configure_success"),
      });
      onSubmit?.();
      closeModal();
    }
  };
  const loadData = async () => {
    try {
      const status = await getObjectStatus(
        objectId.current as string,
        authenticatedRequest
      );
      objectStatus.current = status;
      const result = await getTransmitterSettings(
        transmitterId.current as number,
        authenticatedRequest
      );
      result.data.forEach((d) => {
        d.originalValue = d.defaultValue;
      });
      setSettings([...result.data]);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors, error.response?.status);
    }
  };
  useImperativeHandle<ConfigureTransmitterActions, ConfigureTransmitterActions>(
    ref,
    () => {
      const actions: ConfigureTransmitterActions = {
        open: (tId: number, objId: string) => {
          transmitterId.current = tId;
          objectId.current = objId;
          updatedSettings.current = [];
          loadData();
          setIsConfigureTransmitterOpen(true);
        },
      };
      return actions;
    }
  );
  const getCustomFooter = () => (
    <Row type="space">
      <Row type="left">
        <Button
          type="button"
          variant="primary"
          text={t("Common:save")}
          loading={isSubmitting}
          disabled={updatedSettings.current?.length === 0 || isSubmitting}
          onClick={onInternalSubmit}
          className={styles.actionButton}
        />
        <Button
          type="button"
          variant="secondary"
          text={t("Common:cancel")}
          onClick={closeModal}
          className={styles.actionButton}
        />
      </Row>

      <Button
        type="button"
        variant="border-link"
        text={t("Transmitters:show_available_files")}
        onClick={() => {
          configurationFilesRef.current?.open(objectId.current as string);
        }}
      />
      <ConfigurationFilesModal ref={configurationFilesRef} />
    </Row>
  );
  return (
    <PrimeModal
      isOpen={isConfigureTransmitterOpen}
      onClose={closeModal}
      withHeader
      header={t("Transmitters:configure_modal_title")}
      withFooter
      customFooter={getCustomFooter()}
      loading={isSubmitting}
      fixedWidth="848px"
    >
      <p>
        {t("Transmitters:last_time_configured")}:
        {objectStatus.current?.lastConfigured
          ? moment(objectStatus.current?.lastConfigured).format(
              "YYYY-MM-DD HH:mm"
            )
          : "---"}
      </p>
      <Spacer size={16} />
      <form>
        <Column type="top">
          {!settings ? (
            <LoadingSpinner theme="primary" />
          ) : (
            <>
              <ResponsiveWrapper
                rowType="space"
                rowAlign="start"
                style={{ alignSelf: "stretch" }}
              >
                <TextInput
                  label={t("Transmitters:configure_modal_producttype")}
                  disabled
                  value="test"
                />
                <Spacer size={16} />
                <TextInput
                  label={t("Common:labels_phone_number")}
                  disabled
                  value={objectStatus.current?.phone}
                />
              </ResponsiveWrapper>
              <Spacer size={16} />
              <div className={styles.deviceSettingsWrapper}>
                {settings
                  ? settings.map((setting, index) => (
                      <Setting
                        key={setting.identifier}
                        idPrefix={`setting_${index}_`}
                        setting={setting}
                        onChangeValue={(val) => {
                          if (
                            updatedSettings.current?.find(
                              (s) => s.identifier === val.identifier
                            ) !== undefined
                          ) {
                            updatedSettings.current =
                              updatedSettings.current?.map((s) =>
                                s.identifier === setting.identifier
                                  ? { ...val }
                                  : { ...s }
                              );
                          } else {
                            updatedSettings.current = [
                              ...updatedSettings.current,
                              val,
                            ];
                          }
                          setSettings(
                            settings.map((s) =>
                              s.identifier === setting.identifier
                                ? { ...val }
                                : { ...s }
                            )
                          );
                        }}
                      />
                    ))
                  : null}
              </div>
            </>
          )}
        </Column>
        <Spacer size={16} />
      </form>
    </PrimeModal>
  );
});
