import { Column, Spacer } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import { IPWhiteList } from "./IPWhiteList/IpWhiteList";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import { MenuAlternativesController } from "../../../components/MenuAlternatives/MenuAlternativesController";

export const SettingsContainer = ({ id }: { id: string }) => {
  const t = useTranslations();

  return (
    <Column type="top" align="start" className="w-100">
      <h1>{t("Menu:settings")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Organizations:settings_description")}
      </p>

      <RoleFeature requires={[Role.ApplicationMenuAlternatives]}>
        <Spacer size={32} />

        <MenuAlternativesController
          ownerId={+id}
          alternativesType="organizationId"
          accordionTitle={t("Organizations:alternative_title")}
          accordionDescription={t(
            "Organizations:settings_menu_alternatives_description"
          )}
          withOrganizationsSelection
          renderInAccordion
        />
      </RoleFeature>

      <RoleFeature requires={[Role.ApplicationMenuAlternatives]}>
        <Spacer size={32} />

        <MenuAlternativesController
          ownerId={+id}
          alternativesType="organizationId"
          accordionTitle={t("Organizations:app_alternative_title")}
          accordionDescription={t(
            "Organizations:settings_app_menu_alternatives_description"
          )}
          withOrganizationsSelection
          renderInAccordion
          isMobile
        />
      </RoleFeature>

      <RoleFeature requires={[Role.EditIPWhiteList]}>
        <>
          <Spacer size={32} />

          <IPWhiteList orgId={id} />
        </>
      </RoleFeature>
    </Column>
  );
};
