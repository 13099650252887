import {
  createContext,
  useEffect,
  useRef,
  useState,
  Dispatch,
  useMemo,
} from "react";
import useUser from "../../../core/user/useUser";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  editContact,
  getContactDetails,
} from "../../../core/api/contacts/contacts";
import { ContactDetails } from "../../../core/api/contacts/types";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { LoginModal } from "../../Objects/ObjectResponse/Contacts/LoginModal";
import { notifyApiErrors } from "../../../core/helpers/helpers";

export const SharedContactContext = createContext<{
  data?: ContactDetails;
  isLoading: boolean;
  isError: boolean;
  newData?: ContactDetails;
  errorMessage: string;
  showChangeContactModal: boolean;
  update: (data: ContactDetails) => Promise<void>;
  reload: () => Promise<void>;
  setNewData: Dispatch<ContactDetails>;
  setShowChangeContactModal: Dispatch<boolean>;
  updateContact: (values: ContactDetails) => void;
}>({
  data: undefined,
  isLoading: false,
  isError: false,
  errorMessage: "",
  newData: undefined,
  showChangeContactModal: false,
  update: async () => {},
  reload: async () => {},
  setNewData: () => {},
  setShowChangeContactModal: () => {},
  updateContact: () => {},
});

export const SharedContactProvider = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const isAlive = useRef(false);
  const { authenticatedRequest } = useUser();
  const t = useTranslations();
  const [data, setData] = useState<ContactDetails>();
  const [newData, setNewData] = useState<ContactDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showCredentialModal, setShowCredentialModal] = useState(false);
  const [showChangeContactModal, setShowChangeContactModal] = useState(false);

  const fetchCommonContact = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await getContactDetails(id, authenticatedRequest);

      if (isAlive.current) {
        setData(result.data);
      }
    } catch (error: any) {
      if (isAlive.current) {
        if (error.message.includes("403")) {
          setErrorMessage(
            `${t("Errors:no_object_rights")}
            ${t("Errors:contact_admin")}`
          );
        } else {
          setErrorMessage(t("Errors:open_problem"));
        }
        setIsError(true);
      }
    }
    if (isAlive.current) {
      setIsLoading(false);
    }
  };

  const updateContact = async (updateData: ContactDetails) => {
    setIsLoading(true);
    try {
      const { data: response } = await editContact(
        {
          contactId: +id,
          contactDetails: updateData,
        },
        authenticatedRequest
      );
      if (isAlive.current) {
        if (response) {
          if (
            data?.callMethod !== 1 &&
            updateData.callMethod === 1 &&
            !updateData.adminId
          ) {
            setShowCredentialModal(true);
          }
          setData({
            ...data,
            ...updateData,
          });
          notify({
            message: t("Contacts:edit_success"),
          });
        } else {
          notify({
            message: t(
              "Errors:response_object_reference_not_set_to_an_instance_of_an_object"
            ),
            variant: "error",
          });
        }
      }
    } catch (error: any) {
      if (isAlive.current) {
        notifyApiErrors(error.response?.data?.errors);
      }
    } finally {
      setIsLoading(false);
      setShowChangeContactModal(false);
    }
  };

  const update = async (updateData: ContactDetails) => {
    if (
      (data?.callMethod === 1 &&
        updateData.callMethod !== 1 &&
        data.isResource &&
        data?.adminId) ||
      (data?.isResource &&
        !updateData.isResource &&
        data.callMethod === 1 &&
        data?.adminId)
    ) {
      setShowChangeContactModal(true);
    } else {
      await updateContact(updateData);
    }
  };

  useEffect(() => {
    fetchCommonContact();
  }, [id]);

  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, []);

  const contextData = useMemo(
    () => ({
      data,
      isLoading,
      isError,
      errorMessage,
      newData,
      showChangeContactModal,
      update,
      reload: fetchCommonContact,
      setNewData,
      setShowChangeContactModal,
      updateContact,
    }),
    [isLoading, showChangeContactModal]
  );

  return (
    <SharedContactContext.Provider value={contextData}>
      {data && (
        <LoginModal
          contactId={data.id}
          showModal={showCredentialModal}
          setShowModal={setShowCredentialModal}
          onClose={() => {
            fetchCommonContact();
            setShowCredentialModal(false);
          }}
        />
      )}
      {children}
    </SharedContactContext.Provider>
  );
};
