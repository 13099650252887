import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import DeleteTransmitterModal from "../../components/Transmitters/DeleteTransmitterModal";
import {
  DialogActions,
  EditTransmitter,
} from "../../components/Transmitters/EditTransmitter";
import MoveTransmitterModal from "../../components/Transmitters/MoveTransmitterModal";
import { Transmitter } from "../../core/api/transmitters/types";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import StyledModal from "../../ui-lib/components/Modal/Modal";
import { api as reportApi } from "../../core/api/reports/reports";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { RowAction } from "../../ui-lib/components/Tables/Table";
import TransmittersTable from "./TransmittersTable";
import { getTransmittersInStock } from "../../core/api/transmitters/transmitters";
import {
  storeTableState,
  useStoredTableState,
} from "../../core/hooks/filters/useStoredTableState";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import {
  notifyApiErrors,
  sortParamsToString,
} from "../../core/helpers/helpers";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import Tree from "../../ui-lib/components/Hierarchy/Tree";
import { useOrganisationFilter } from "../../core/hooks/filters/useOrganisationFilter";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { useCustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import { useInStockFilter } from "../../core/hooks/filters/useInStockFilter";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";

import styles from "../PagesLayout.module.css";

export const PAGE_ID = "adminportal/transmitters";

const Transmitters = () => {
  storeTableState(PAGE_ID);
  const pageState = usePageState();
  const user = useUser();
  const t = useTranslations();
  const history = useHistory();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);

  const clearSelectedFunction = useRef<Function>();
  const editTransmitterRef = useRef<DialogActions>(null);
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<Record<string, any>>>(null);

  const [selectedItemRows, setSelectedItemRows] = useState<Transmitter[]>([]);
  const [selectedRowAction, setSelectedRowAction] = useState<number>(0);
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [withArgs, setWithArgs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transmitters, setTransmitters] = useState<{
    data: Transmitter[];
    amount: number;
  }>();
  const [searchParam, setSearchParam] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const { sortParams, setSortParams } = useSortParams(searchState);
  const { data: treeData, isLoading } = useOrganizationsTree();

  const { customersFilter, setCustomersFilter, resetCustomersFilter } =
    useCustomersFilter(searchState);
  const organisationFilter = useOrganisationFilter(customersFilter, treeData);
  const { inStockFilter, setInStockFilter, resetInStockFilter } =
    useInStockFilter(searchState);
  const [stockOptions, setStockOptions] = useState([
    {
      id: 0,
      name: t("Transmitters:in_stock_option"),
      isSelected: inStockFilter.inStock === "1",
    },
    {
      id: 1,
      name: t("Transmitters:connected_option"),
      isSelected: inStockFilter.inStock === "2",
    },
    { id: 2, name: t("Common:all"), isSelected: inStockFilter.inStock === "3" },
  ]);

  const fetchTransmitters = async () => {
    setLoading(true);
    setTransmitters({ data: [], amount: 0 });
    try {
      const { result, total } = await getTransmittersInStock(
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          query: searchParam.q,
          order: sortParamsToString(sortParams),
          organizations: organisationFilter?.value,
          inStock: stockOptions.find((opt) => opt.isSelected)?.id,
        },
        user.authenticatedRequest
      );
      if (result) {
        setTransmitters({ data: result, amount: total });
      }
    } catch (error: any) {
      notifyApiErrors(error.response.data.errors);
    } finally {
      setLoading(false);
    }
  };

  const onDone = () => {
    setIsExportLoading(false);
    setIsExportModalOpen(false);
    clearSelectedFunction.current?.();
    tableRowSelectionPopupRef.current?.close(true);
  };

  const onError = (error: any) => {
    setIsExportLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { downloadReport } = reportApi(
    user.authenticatedRequest,
    onDone,
    onError
  );

  const rowSelectionActionItems: IDropdownItem[] = [
    {
      id: 0,
      name: t("Transmitters:dropdown_move_selected"),
      isSelected: selectedRowAction === 0,
    },
    {
      id: 2,
      name: t("Common:export"),
      isSelected: selectedRowAction === 2,
    },
  ];

  const tryDownloadReport = async () => {
    setIsExportLoading(true);
    await downloadReport({
      reportId: 11,
      reportArguments: {
        runMode: "BySearchCriteria",
        entityIds: withArgs ? selectedItemRows.map((item) => item.id) : [],
        organizationIds:
          customersFilter.customers?.split(",").map((i) => +i) ?? [],
        inStock: +inStockFilter.inStock! - 1,
        searchText: searchParam.q,
      },
    });
  };

  const rowActions = [
    {
      icon: "pencil-alt",
      text: t("Common:label_edit"),
      onClick: ({ id }: { id: any }) =>
        history.push(`/adminportal/transmitters/${id}`),
    } as RowAction<Object>,
  ];

  if (user.config?.show.includes("DeleteTransmitters")) {
    rowSelectionActionItems.push({
      id: 1,
      name: t("Transmitters:dropdown_delete_selected"),
      isSelected: selectedRowAction === 1,
    } as IDropdownItem);

    rowActions.push({
      icon: "trash",
      iconVariant: "secondary",
      text: t("Common:delete"),
      onClick: async (rowData: any) => {
        setSelectedItemRows([rowData as Transmitter]);
        setDeletionModalOpen(true);
      },
    });
  }

  useEffect(() => {
    fetchTransmitters();
  }, [pageFilter, searchParam, sortParams, organisationFilter, stockOptions]);

  useEffect(() => {
    clearSelectedFunction.current?.();
    tableRowSelectionPopupRef.current?.close(true);
  }, [transmitters]);

  const isFilterApplied =
    !!searchParam.q ||
    !!customersFilter.customers ||
    inStockFilter.inStock !== "1";

  const getDeleteModalName = useCallback(() => {
    if (selectedItemRows?.length > 1) {
      return selectedItemRows?.length.toString();
    }
    if (selectedItemRows?.length === 1) {
      return selectedItemRows[0].identifier;
    }
    return "";
  }, [selectedItemRows]);

  return (
    <>
      <PageHeader title={t("Menu:transmitter")} icon={pageState.pageIcon}>
        {user.config?.show.some((key) =>
          [
            "ConfigureCareAlarms",
            "ConfigureOrdinaryHousingAlarms",
            "ConfigurePropertyAlarms",
            "ConfigureResponseAlarms",
            "ConfigureShelteredHousingAlarms",
            "ConfigurePeripheralAlarms",
          ].includes(key)
        ) && (
          <Button
            dataCy="add_transmitter"
            variant="primary"
            text={t("Transmitters:add_new_transmitter")}
            onClick={() => editTransmitterRef.current?.open()}
          />
        )}
        {user.config?.show?.includes("Reports") && (
          <Button
            className="ml-8"
            dataCy="export_transmitters"
            type="button"
            variant="secondary"
            text={t("Common:export")}
            onClick={() => {
              setWithArgs(false);
              setIsExportModalOpen(true);
            }}
            loading={isExportLoading}
            disabled={isExportLoading}
          />
        )}
      </PageHeader>
      <Column type="top" className={styles.content}>
        <SearchInput
          limit={1}
          placeholder={t("Transmitters:transmitter_search_placeholder")}
          value={searchParam.q}
          onChange={(value: string) => {
            if (searchParam.q !== value) {
              setTransmitters({ data: [], amount: 0 });
              resetPageFilter();
            }
            setSearchParam({ q: value });
          }}
        />
        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />
        <Row type="left" align="start" className="w-100 flex0">
          <Tree
            withCheckboxTootip
            label={t("Common:select_organisation")}
            placeholder={`--${t("Common:organisation")}--`}
            hideLabel
            className={styles.treeFilter}
            disabled={isLoading}
            items={treeData}
            selectedTreeItem={customersFilter.customers}
            onSelectItem={(ids: string) => {
              if (!ids) {
                resetCustomersFilter();
              } else {
                setCustomersFilter({ customers: ids });
              }
              setPageFilter({ page: 1, pageSize: pageFilter.pageSize });
            }}
            selectionMode="multiple"
            showClearOption={false}
          />
          <Spacer size={8} />
          <Dropdown
            width={200}
            items={stockOptions}
            onSelectItem={(item) => {
              const newStockOptions = stockOptions.map((opt) => ({
                ...opt,
                isSelected: opt.id === item.id,
              }));
              setStockOptions(newStockOptions);
              setInStockFilter({ inStock: `${+item.id + 1}` });
              setPageFilter({ page: 1, pageSize: pageFilter.pageSize });
            }}
            selectedItem={stockOptions.find((opt) => opt.isSelected)}
          />
          {isFilterApplied && (
            <>
              <Spacer size={8} />
              <ClearFilter
                text={t("Common:labels_clear_all_filters")}
                onClearClick={() => {
                  setSearchParam({ q: "" });
                  resetInStockFilter();
                  setStockOptions(
                    stockOptions.map((opt) => ({
                      ...opt,
                      isSelected: opt.id === 0,
                    }))
                  );
                  resetCustomersFilter();
                }}
                filtersToWatch={[customersFilter, inStockFilter]}
              />
            </>
          )}
        </Row>
        <Spacer size={16} />
        <Divider />
        <Spacer size={16} />
        <TransmittersTable
          loading={loading}
          transmitters={transmitters?.data || []}
          rowActions={rowActions}
          pageSettings={pageFilter}
          totalAmount={transmitters?.amount ?? 0}
          tableRowSelectionPopupRef={tableRowSelectionPopupRef}
          withFilters={isFilterApplied}
          sortParams={sortParams}
          onSortChange={setSortParams}
          onPageSettingsChange={setPageFilter}
          setClearItemsFunction={(func) => {
            clearSelectedFunction.current = func;
          }}
        />
      </Column>

      {/* Modals */}

      <StyledModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        modalTitle={t("Common:export")}
        customFooter={() => (
          <Row type="left">
            <Button
              loading={isExportLoading}
              disabled={isExportLoading}
              text={
                withArgs
                  ? `${t("Common:exportselection")} (${
                      selectedItemRows.length
                    })`
                  : t("Common:export")
              }
              onClick={tryDownloadReport}
            />
            <Spacer size={8} />
            <Button
              variant="secondary"
              text={t("Common:cancel")}
              onClick={() => setIsExportModalOpen(false)}
            />
          </Row>
        )}
      >
        {withArgs ? (
          <p>
            {t("Common:export")}{" "}
            <strong>{`${selectedItemRows.length} ${t(
              "Common:transmitters"
            ).toLowerCase()}`}</strong>{" "}
            {t("Common:inlist")} {t("Common:toreport")}
            {"?"}
          </p>
        ) : (
          <p>{t("Transmitters:export_modal_description")}</p>
        )}
      </StyledModal>
      <EditTransmitter
        ref={editTransmitterRef}
        modalTitle={t("Transmitters:create_transmitter_title")}
        saveButtonTitle={t("Transmitters:create_transmitter")}
        onSubmit={(transmitterId?: string | number) => {
          if (transmitterId) {
            history.push(`/adminportal/transmitters/${transmitterId}`);
          } else {
            fetchTransmitters();
          }
        }}
      />
      <DeleteTransmitterModal
        isOpen={deletionModalOpen}
        onClose={() => setDeletionModalOpen(false)}
        text={
          selectedItemRows?.length > 1
            ? t("Transmitters:delete_multiple_transmitter_confirm")
            : t("Transmitters:delete_transmitter_confirm")
        }
        modalTitle={
          selectedItemRows?.length > 1
            ? t("Transmitters:delete_transmitters")
            : t("Transmitters:delete_transmitter")
        }
        name={getDeleteModalName()}
        onDelete={() => {
          setSelectedItemRows([]);
          setDeletionModalOpen(false);
          clearSelectedFunction.current?.();
          tableRowSelectionPopupRef.current?.close(true);
          setTimeout(() => {
            fetchTransmitters();
          }, 500);
        }}
        transmitterIds={selectedItemRows?.map((s) => s.id.toString()) || []}
      />
      <MoveTransmitterModal
        isOpen={moveModalOpen}
        closeModal={setMoveModalOpen}
        onClose={() => {
          clearSelectedFunction.current?.();
          tableRowSelectionPopupRef.current?.close(true);
          setTimeout(() => {
            fetchTransmitters();
          }, 500);
        }}
        text={
          selectedItemRows?.length > 1
            ? t("Transmitters:move_multiple_transmitter_confirm")
            : t("Transmitters:move_transmitter_confirm")
        }
        modalTitle={t("Transmitters:move_transmitters")}
        name={selectedItemRows?.length.toString()}
        transmitters={selectedItemRows}
      />
      <TableRowSelectionPopup
        ref={tableRowSelectionPopupRef}
        renderBody={(selectedItems) => (
          <Row type="right" align="center">
            <Dropdown
              items={rowSelectionActionItems}
              placeholder={t("Transmitters:select_action")}
              selectedItem={rowSelectionActionItems.find((r) => r.isSelected)}
              onSelectItem={(item) => {
                setSelectedRowAction(+item.id);
              }}
              width="230px"
            />
            <Spacer size={16} />
            {selectedRowAction === 0 && (
              <Button
                variant="primary"
                text={`${t("Transmitters:move_selection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  setSelectedItemRows(selectedItems as Transmitter[]);
                  setMoveModalOpen(true);
                }}
              />
            )}
            {selectedRowAction === 1 && (
              <Button
                variant="destructive"
                text={`${t("Transmitters:delete_selection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  setSelectedItemRows(selectedItems as Transmitter[]);
                  setDeletionModalOpen(true);
                }}
              />
            )}
            {selectedRowAction === 2 && (
              <Button
                variant="primary"
                text={`${t("Common:exportselection")} (${
                  selectedItems.length
                })`}
                onClick={() => {
                  setSelectedItemRows(selectedItems as Transmitter[]);
                  setWithArgs(true);
                  setIsExportModalOpen(true);
                }}
              />
            )}
          </Row>
        )}
      />
    </>
  );
};

export default Transmitters;
