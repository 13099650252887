import { useState } from "react";
import { AxiosInstance } from "axios";
import { deleteIntegration } from "../../../core/api/organizations/organizations";
import { IOrgIntegration } from "../../../core/api/organizations/types";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

import styles from "./Integrations.module.css";

// Backend issue with deleting instance

export const DeleteIntegrationModal = ({
  open,
  integration,
  close,
  fetchIntegrations,
}: {
  open: boolean;
  integration?: IOrgIntegration;
  close: () => void;
  fetchIntegrations?: (
    orgId: string,
    authenticatedRequest: AxiosInstance
  ) => Promise<void>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [loading, setLoading] = useState(false);

  if (!integration) {
    return;
  }

  const handleDeleteIntegration = async () => {
    setLoading(true);
    try {
      await deleteIntegration(
        integration.organizationId.toString(),
        integration.integrationId,
        authenticatedRequest
      );
      notify({ message: t("Messages:instance_deleted") });
      fetchIntegrations?.(integration.integrationId, authenticatedRequest);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setLoading(false);
      close();
    }
  };

  const getConfimText = () => {
    const splittedText = t("Messages:delete_arc_subscription_confirm").split(
      "{replaceKey}"
    );
    return (
      <p className={styles.deleteIntegrationModalDescription}>
        {splittedText[0]}
        <strong>{integration.name}</strong>
        {splittedText[1]}
      </p>
    );
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      fixedWidth="576px"
      className={styles.deleteIntegrationModal}
      header={t("Organizations:delete_integration")}
      isOpen={open}
      onClose={close}
      loading={loading}
      submitBtn={{
        text: t("Organizations:delete_integration"),
        variant: "destructive",
        onClick: handleDeleteIntegration,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: close }}
    >
      {getConfimText()}
    </PrimeModal>
  );
};
